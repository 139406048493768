import { Box, CardActions, CardHeader, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import * as React from 'react';
import {Card as MuiCard, CardContent} from '@mui/material';
import Icon from 'components/icons/Icon';
import SubHeading from 'components/labels/SubHeading';
import Button from 'components/buttons/Button';
import Container from 'components/layout/Container';
import Dialog from './Dialog';
import { ColorsUtil, ColourType, MyTheme } from 'MyTheme';


const ErrorDialog = (props: any) => {
    const {title, onClose, children, ...rest} = props;
    const theme = MyTheme();
    return(
        <Dialog 
            onClose={() => onClose && onClose()} 
            {...rest}
        >
            <Box flexDirection="column" alignItems="center" justifyContent="center" display="flex">
                <Icon color={theme.palette.error.main}>error</Icon>
            </Box>
            {children}
        </Dialog>
    ) 
}

export default ErrorDialog;