import { AppContext, DialogSettings } from 'AppContext';
import { Bootstrapper } from 'Bootstrapper';


export class DialogUtil {
    private context: AppContext;
    constructor(){
        this.context = Bootstrapper.getInstance<AppContext>(AppContext);
    }

    public confirmDialog(msg: string, onResult: (result: boolean) => any, title: string = null){
        var settings = new DialogSettings(msg, onResult, title);
        this.context.setDialogVisibility(settings);
    }
}