import CircularProgress from 'components/progress/CircularProgress';
import {Button as MuiButton}  from '@mui/material';
import * as React from 'react';


const Button = (props: any) => {
    const {loading, loadingColor, ...rest} = props;
    const Loadicon= loading ? <CircularProgress style={{color: 'white'}} size={16} /> : null;

    return <MuiButton type="submit" endIcon={Loadicon} variant="contained" {...rest} />
}

export default Button;