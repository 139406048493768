import Button from 'components/buttons/Button';
import FieldCollection from 'components/fields/fieldCollection/FieldCollection';
import Form from 'components/forms/Form';
import ErrorDialog from 'components/modals/ErrorDialog';
import { AppError, BadRequestError, ForbiddenError, MissingEmailConfirmationError, NotFoundError, UnauthorizedError } from 'errors/ApplicationErrors';
import React, { Component } from 'react'
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import Text from 'components/labels/Text';
import Icon from 'components/icons/Icon';
import { ColorsUtil, ColourType, MyTheme } from 'MyTheme';
import Box from 'components/layout/box/Box';
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';


export enum ErrorType {
  Unknown,
  Forbidden,
  BadRequest
}

class RedirectButton {
  constructor(label: string, to: string){
    this.label = label;
    this.to = to;
  }
  label: string;
  to: string;
}

class ErrorInfo {
  constructor(hasError: boolean, error: any = null, redirect: RedirectButton = new RedirectButton("Home", "/home")) {
    this.hasError = hasError;
    this.error = error;
    this.redirect = redirect;

  }
  hasError: boolean;
  error: any;
  redirect: RedirectButton;

  public getTypedError() {


  }
}

class ErrorBoundary extends React.Component<any, ErrorInfo> {
  // class ErrorBoundary extends React.Component {
  private beforeHandleError: () => {};
  private navigate: NavigateFunction;
  private theme = MyTheme();
  private ignorableErrorMsgs: string[] = [
    "The play() request was interrupted by a call to pause().",
    "ResizeObserver loop completed with undelivered notifications.",
    "ResizeObserver loop limit exceeded", // triggered by lastpass ext
  ]
   
  constructor(props: any) {
    super(props);
    this.state = new ErrorInfo(false);
    this.navigate = props.navigate;
    this.beforeHandleError = props.beforeHandleError;
  }

  // static getDerivedStateFromError(error: any) {
  //   // Update state so the next render will show the fallback UI.
  //   return new ErrorInfo(true, error);
  // }

  private onPromiseRejectionHandler(event: any) {
    // console.log("promise error", event.reason);
    this.handleError(event.reason);
  };

  private onErrorHandler(event: any) {
    var t: ErrorEvent = event;
    var err = t.error ?? new Error(t.message);
    //console.log("evanTest normal errror", t)
    this.handleError(err);
  };

  componentDidMount() {
    window.addEventListener("error", this.onErrorHandler.bind(this));
    window.addEventListener("unhandledrejection", this.onPromiseRejectionHandler.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("error", this.onErrorHandler.bind(this));
    window.removeEventListener("unhandledrejection", this.onPromiseRejectionHandler.bind(this));

  }

  // componentDidCatch(error: any, errorInfo: any) {
  //   console.log("normal error caught", error);
  //   //this.handleError(error);
  //   //this.setState(new ErrorInfo(true, error));
  //   // You can also log the error to an error reporting service
  //   //logErrorToMyService(error, errorInfo);
  // }

  private onDialogClose(){
    const errorInfo = this.state;
    if(errorInfo.redirect)
      this.navigate(errorInfo.redirect.to);
    this.setState(new ErrorInfo(false));
  }

  render() {
    const errorInfo = this.state;
    const errMSg = errorInfo?.error?.message || "";
    return(
      <>
      <ErrorDialog maxWidth="xs" open={errorInfo.hasError} onClose={() => this.onDialogClose()}>
        <Form
          flat
          action={<Button fullWidth onClick={() => this.onDialogClose()}>Ok</Button>}
        >
            <Text textAlign="center">{errMSg}</Text>
        </Form>
      </ErrorDialog>
      {this.props.children}
      </>
    )    
  }




  private handleError(error: any) {
    var ignore = this.ignorableErrorMsgs.some(msg => msg == error.message);
    if(ignore)
      return;

    this.beforeHandleError && this.beforeHandleError();

    var isAppError = error instanceof AppError;


    if (error instanceof UnauthorizedError) {
      if(error.displayMessage)
        this.setState(new ErrorInfo(true, error, new RedirectButton('Login', '/login')))
      else{
        this.setState(new ErrorInfo(false))
        this.navigate("/login");
      }
      return;
    }

    if (error instanceof MissingEmailConfirmationError) {
      this.setState(new ErrorInfo(true, error, new RedirectButton("Confirm", "/email-confirmation")))
      return;
    }

    if (error instanceof NotFoundError) {
      this.setState(new ErrorInfo(true, error));
      return;
    }

    if (error instanceof BadRequestError) {
      this.setState(new ErrorInfo(true, error, null))
      return;
    }

    var e = isAppError ? error : new Error("an unknown error occured");
    this.setState(new ErrorInfo(true, e));
  }

}
export default ErrorBoundary;