
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"
import { Tenant } from "models/Tenant"
import { BadRequestError } from "errors/ApplicationErrors"
import { Bootstrapper } from "Bootstrapper"
import LoadingButton from "components/buttons/LoadingButton"
import { DialogUtil } from "utils/DialogUtil"
import Article, { Exhibit, Icon2, Section2, Stack2, Subtitle2, Text2 } from "../../Article"
import SimpleGrid, { SimpleContent } from "views/articles/shared/simpleGrid/SimpleGrid"
import InfoLink, { InfoType } from "views/articles/shared/InfoLink"

const GettingStartedArticle = (props: any) => {
    const navigate = useNavigate();
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);
    const media = prodInfoService.getMedia();
    const userService = new UserService();
    const tenantService = new TenantService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dialogUtils = new DialogUtil();



    useEffect(() => {
    }, []);




    return(
        <Article 
            title="Create your first digital binder"
            subtitle="Just 3 steps">
                <Section2>
                    <Stack2>
                        <Text2>Zibbit can help you save time when it comes to bundling materials. There are many use cases, including affidavits and motion records. In this article we are going to publish a digital binder in 3 steps.</Text2>
                    </Stack2>
                </Section2>
                <Section2>
                    <Stack2>
                        <Box>
                        <SimpleGrid reverse>
                            <SimpleContent>
                                <Box>
                                    <Stack2>
                                        <Subtitle2>Step 1: Import Exhibits</Subtitle2>
                                        <Text2>Create a new <InfoLink type={InfoType.ProjectPane}/> by clicking the <Icon2>add</Icon2> from the <InfoLink type={InfoType.ZibbitPane}/>. Then specify a folder on your local device that contains your exhibits.</Text2>
                                    </Stack2>

                                </Box>
                            </SimpleContent>
                            <SimpleContent>
                                <Stack2>
                                    <Paper>
                                        <img src={media.get('get-started').url} />
                                    </Paper>
                                </Stack2>
                            </SimpleContent>
                        </SimpleGrid>
                        </Box>
                    </Stack2>

                </Section2>
                <Section2>
                    <SimpleGrid reverse>
                        <SimpleContent>
                            <Box>
                                <Stack2>
                                    <Subtitle2>Step 2: Reference Exhibits</Subtitle2>
                                    <Text2>From the <InfoLink type={InfoType.ExhibitsPane}/> you can insert references to your exhibits throughout your document.</Text2>
                                    <Text2>To insert a reference, click the <Icon2>more_vert</Icon2> icon beside a given exhibit and select "Insert" from the menu.</Text2>
                                    <Text2>References appear where your cursor is positioned within the document. By default the label will appear as "<Exhibit num={1}>Exhibit</Exhibit>", but it's fully customizable.</Text2>
                                </Stack2>

                            </Box>
                        </SimpleContent>
                        <SimpleContent>
                            <Stack2>
                                <Paper>
                                    <img src={media.get('insert-ref').url} />
                                </Paper>
                            </Stack2>
                        </SimpleContent>
                    </SimpleGrid>
                </Section2>
                <Section2>
                    <SimpleGrid reverse>
                        <SimpleContent>
                            <Box>
                                <Stack2>
                                    <Subtitle2 bold>Step 3: Publish PDF</Subtitle2>
                                    <Text2>Open the <InfoLink type={InfoType.PublishPane}/> to create a digital binder.</Text2>
                                    <Text2>You can customize your digital binder with bookmarks, tabs, pagination and more. When you're ready, click "Publish".</Text2>
                                </Stack2>

                            </Box>
                        </SimpleContent>
                        <SimpleContent>
                            <Stack2>
                                <Paper>
                                    <img src={media.get('publish').url} />
                                </Paper>
                            </Stack2>
                        </SimpleContent>
                    </SimpleGrid>
                </Section2>
                <Section2>
                    <SimpleGrid reverse>
                        <SimpleContent>
                            <Box>
                                <Stack2>
                                    <Subtitle2 bold>Success!</Subtitle2>
                                    <Text2>You're all done. Click "Open File" to inspect your new digital binder.</Text2>
                                </Stack2>

                            </Box>
                        </SimpleContent>
                        <SimpleContent>
                            <Stack2>
                                <Paper>
                                    <img src={media.get('publish-success').url} />
                                </Paper>
                            </Stack2>
                        </SimpleContent>
                    </SimpleGrid>
                </Section2>


        </Article>


    )


}


export default GettingStartedArticle