import * as React from 'react';
import {FormControl, FormHelperText, FormLabel, InputAdornment, InputLabel, TextField as MuiTextField} from '@mui/material';
import Select from 'components/inputs/select/Select';

const SelectField = (props: any) => {
  const { isReadOnly, helperText, fullWidth, ...rest } = props;
  const fullWidthVal = fullWidth ?? true
  const label = isReadOnly ? <InputLabel shrink>{props.label}</InputLabel> : <FormLabel>{props.label}</FormLabel>;
  const disabled = props.disabled || isReadOnly;
  const variant = isReadOnly ? "standard" : "outlined";
  
  return (
    <FormControl hiddenLabel={!isReadOnly} variant={variant} fullWidth={fullWidthVal}>
      {label}
      <Select disabled={disabled} fullWidth={fullWidthVal} isReadOnly={isReadOnly} {...rest}/>
      <FormHelperText>{helperText}</FormHelperText>    
    </FormControl>
  );
}
export default SelectField;