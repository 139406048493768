import { Icon, IconButton, ListItemButton, ListItemIcon } from '@mui/material';
import Button from 'components/buttons/Button';
import Menu from 'components/menu/Menu';
import MenuItem from 'components/menu/MenuItem';
import React, {Component, useState} from 'react'

const LoginMenu = (props: any) => {
  const {handleClick, ...rest} = props; 
  const [anchorEl, setAnchorEl] = useState<any>(null);


  const handleClose = (e: any) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const menuClicked = (event: any) => {
    const ele = document.getElementById(rest.eleId);
    setAnchorEl(ele);

    event.stopPropagation()
  };

  const menuItemClicked = async(e: any, route: string) => {
    handleClose(e);
    handleClick(route);
  }


  const el = anchorEl;
  const open = Boolean(el);

  return (
    <>
      {/* <Button
        color="inherit"
              aria-label="More"
              aria-owns={open ? 'long-menu' : undefined}
              aria-haspopup="true"
              onClickCapture={(e: any) => menuClicked(e)}
      
        endIcon={<Icon sx={{color: 'white'}}
        >expand_more</Icon>} >Login</Button> */}

        <Icon  
                aria-label="More"
                aria-owns={open ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClickCapture={(e: any) => menuClicked(e)}
                color='inherit'
                sx={{color: 'whitesmoke', marginTop: 0}}

        >
            expand_more
        </Icon>
      <Menu
        id="long-menu"
        anchorEl={el}
        open={open}
        onClose={(e: any) => handleClose(e)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        >
          <MenuItem onClick={(e: any) => menuItemClicked(e, 'login')}>
              Login
          </MenuItem>
          <MenuItem onClick={(e: any) => menuItemClicked(e, 'register')}>
            {/* <ListItemIcon>
              <Icon type={IconTypes.Folder}/>
            </ListItemIcon>
            Open in folder */}
              Register

          </MenuItem>

        </Menu>
    </>
  )


}


export default LoginMenu