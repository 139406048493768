
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepContent, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import LoadingButton from "components/buttons/LoadingButton"
import { FreeTrialStatus } from "models/FreeTrialStatus"
import ConfirmEmailForm from "./ConfirmEmailForm"
import DownloadsButton from "views/downloads/DownloadsButton"
import RequestDemoButton from "components/buttons/RequestDemoButton"
import Link from "components/buttons/Link"


const ConfirmSteps = (props: any) => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    
    const onDownloadCompletedAsync = (success: boolean) => {
        if(!success)
            return;

        setCurrentStep(3);
    }
    return(
        <Animation duration="fast" type='slide' direction='up' transitionDelay={0}>
        <div>
            <Form
                title={currentStep > 2 ? null : "Almost done!"}
            >

        <Stepper orientation="vertical">
        <Step active={currentStep == 1} completed={currentStep > 1}>
            <StepLabel>Confirm Email</StepLabel>
            <StepContent>
                <ConfirmEmailForm hideTitle onConfirmed={() => setCurrentStep(2)} inline />
            </StepContent>
        </Step>
        <Step active={currentStep == 2} completed={currentStep > 2}>
            <StepLabel>Download</StepLabel>
            <StepContent>
                <Form flat
                    action={<Box>
                        <DownloadsButton onCompleted={(success: boolean) => onDownloadCompletedAsync(success)} fullWidth />
                    </Box>}
                >
                    <Text>Click the button below to get the plugin.</Text>
                </Form>
            </StepContent>
        </Step>
    </Stepper>
        
        {currentStep > 2 && <Box mt={3} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Icon bgCircle>celebration</Icon>
                <SubHeading m={1}>You're all set!</SubHeading>
                <Text>Book a demo <Link component={NavLink} to={'/demo'}>here</Link></Text>
                {/* <RequestDemoButton mt={1} /> */}
            </Box>}

    </Form>

        </div>
    </Animation>


    )
}


export default ConfirmSteps