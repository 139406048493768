import React, { useState, useEffect, useRef } from 'react';
import useInterval from './UseInterval';

export default function usePoler(
  timeoutFn: () => void,
  intervalFn: () => void,
  intervalDelay: number, 
  timeoutDuration: number = 1000 * 5) {
  
  const [delay, setDelay] = useState<number>(intervalDelay);
  const [started, setStarted] = useState<boolean>(false);

  const countRef = useRef(delay);
  countRef.current = delay;

  const startPoling = () => {
    if(started)
      return;

    setTimeout(() => {
      if(countRef.current == null)
        return;
      setDelay(null);
      timeoutFn();
    }, timeoutDuration);
    setStarted(true);
  }

  const stopPoling = () => {
    setDelay(null);
  }
  
  useInterval(async() => {
    if(!started)
      return;
      
    intervalFn();
  }, delay);

  return [startPoling, stopPoling]

}