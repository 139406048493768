import * as React from 'react';
import {AppBar as MuiAppBar, Avatar, Collapse, Color, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Stack, ThemeProvider, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { MyTheme } from 'MyTheme';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Bootstrapper } from 'Bootstrapper';
import Button from 'components/buttons/Button';
import ExpansionPanel from 'components/transitions/expansionPanel/ExpansionPanel';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


const ExpandableListItem = (props: any) => {
  const {children, fullWidth, title, ...rest} = props;
    // const classes = useStyles();
    // const [open, setOpen] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const el = anchorEl;
    const open = Boolean(el);

  
    const handleClick = (e: any) => {
        // setOpen(!open);
        const val = open ? null : e.currentTarget
        setAnchorEl(val);
    };

    const handleClose = (e: any) => {
      setAnchorEl(null);
    };

  
    return (
      <>
        <Button fullWidth={fullWidth} onClick={(e: any) => handleClick(e)} endIcon={open ? <ExpandMore /> : <ExpandMore />} {...rest}>
          {title}
        
                  {/* <Collapse in={open}>
                    {children}
      
                  </Collapse> */}


<Menu
        PaperProps={{style:{
        }
      }}
        id="long-menu"
        anchorEl={el}
        open={open}
        onClose={(e: any) => handleClose(e)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        >
          {children}
        </Menu>
        </Button>

        </>


    );
    
}

export default ExpandableListItem;