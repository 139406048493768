
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepContent, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import Checkbox from "components/toggle/checkbox/Checkbox"
import Input from "components/inputs/input/Input"
import {UserService} from 'services/UserService';
import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import Link from "components/buttons/Link"
import LoadingButton from "components/buttons/LoadingButton"
import ConfirmEmailForm from "./ConfirmEmailForm"
import { CommonUtil } from "utils/CommonUtil"
import DownloadsButton from "views/downloads/DownloadsButton"




const Register = (props: any) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const userService = new UserService();
    const [hasAccessKeyParam, setHasAccessKeyParam] = useState<boolean>();


    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    useEffect(() => {
        const keyParam = Boolean(searchParams.get("key"));
        setHasAccessKeyParam(Boolean(keyParam))
    }, []);



    const RegForm = (props: any) => {
        const {hideTitle, hasKey, ...rest} = props;
        const [accessKey, setAccessKey] = useState<string>("");
        const [email, setEmail] = useState<string>("");
        const [password, setPassword] = useState<string>("");
        const [validationMsg, setValidationMsg] = useState<string>("");
        const [canSubmit, setCanSubmit] = useState<boolean>(true);
        const [hasAccessKey, setHasAccessKey] = useState<boolean>(hasKey);

        const onSubmitAsync = async () => {
            setValidationMsg("");
            if(hasAccessKey && (accessKey == null || accessKey == "")){
                setValidationMsg("You have not provided an access key");
                return;
            }
            
            setCanSubmit(false);
            var regKey = hasAccessKey ? accessKey : "";
            var result = await registerAsync(false, email, password, regKey).catch((err: Error) => {
                if(err == null || err.message == "")
                    throw err;
    
                setValidationMsg(err.message);
            });
            if(result){
                navigate({
                    pathname: '/email-confirmation',
                    search: '?steps=true',
                  });
            }


            
            setCanSubmit(true);
        }
    
        const registerAsync = async (isAdmin: boolean, username: string, password: string, regKey: string): Promise<boolean> => {
            if(isAdmin)
                return await userService.registerAdminAsync(username, password, regKey);
            else
                return await userService.registerAsync(username, password, regKey);
        }

        return(
            <Form
            validationMsg={validationMsg}
            action={<LoadingButton loading={!canSubmit} disabled={!canSubmit} onClick={() => onSubmitAsync()} fullWidth>{hasAccessKey ? "Submit" : "Start Free Trial"}</LoadingButton>}
            title={hideTitle ? "" : "Register"}
        >
                <FieldCollection>
                    <TextField name="username" id="username" value={email} onChange={(ele: any) => setEmail(ele.target.value)} label="Email" {...rest} />
                    <TextField name="password" id="password" type="password" value={password} onChange={(ele: any) => setPassword(ele.target.value)} label="Password" {...rest} />
                    <Checkbox
                        onChange={() => setHasAccessKey(!hasAccessKey)} 
                        checked={hasAccessKey} 
                        label="Have an access key?"/>
                    {hasAccessKey && <TextField value={accessKey} onChange={(ele: any) => setAccessKey(ele.target.value)} placeholder="00000000-0000-0000-0000-000000000000" {...rest} />}
                </FieldCollection>

            </Form>

        )
    }


    return(

        <Section sx={{flexGrow: 1}} id="section-plans" bgColor={bg2}>
            <Container maxWidth="xs">
                        
                <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                        <Text mb={1} variant="caption">Already have an account? <Link component={NavLink} variant="text" to={'/login'}>Sign in</Link></Text>
                        <RegForm hasKey={hasAccessKeyParam} {...props} />
                    </div>
                </Animation>

            </Container>
        </Section>
    )
}


export default Register