
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Backdrop, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, ThemeProvider, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import Navbar from "./Navbar"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import { PermissionTicket } from "models/PermissionTicket"
import { PermissionRequest } from "models/PermissionRequest"
import { ResourceType } from "models/ResourceType"
import { AppScopes, ScopeUtils } from "models/PermissionScopes"
import { Bootstrapper } from "Bootstrapper"
import CircularProgress from "components/progress/CircularProgress"
import Link from "components/buttons/Link"
import Alert from "components/alerts/Alert"

const Dashboard = (props: any) => {
    const {children, titleBar, navItems, navHtml, alerts, maxWidth, isBusy, bgColor, ...rest} = props;
    const hasNavItems = navHtml || (navItems && navItems.length > 0);
    const hasAlerts = alerts;
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const bg = bgColor ?? bg1;
    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    return(
        <Page>
            <Section disablePadding bgColor={bg}>
                <Box flexDirection={isMobile ? 'column' : 'row'} display="flex" minHeight="85vh">
                    {hasNavItems && <Box>
                        {titleBar && <Box minWidth='180px' maxWidth={isMobile ? '100%' : '180px'} p={1} pl={3} pr={3} color={theme.palette.common.white} backgroundColor={theme.palette.common.black}>
                        <Title className='truncate' margin={0} small>{titleBar}</Title>
                    </Box>}
                        {<Navbar navItems={navItems} />}
                    </Box>}


                    <Box flexDirection='column' display="flex" flexGrow={1} >
                        {hasAlerts && alerts}
                        {isBusy && <CircularProgress />}
                        {children && <Box flexGrow={1} sx={{display: isBusy ? 'none' : 'block'}} pt={3} pb={3}>
                            <Container sx={{margin: 0}} maxWidth={maxWidth || "md"}>
                                {children}
                            </Container>

                        </Box>}
                    </Box>
                </Box>
      
            </Section>
        </Page>
    )

}


export default Dashboard