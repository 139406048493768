
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"
import { Tenant } from "models/Tenant"
import { BadRequestError } from "errors/ApplicationErrors"
import { Bootstrapper } from "Bootstrapper"
import LoadingButton from "components/buttons/LoadingButton"
import { DialogUtil } from "utils/DialogUtil"
import Article, { Icon2, Section2, Stack2, Subtitle2, Text2 } from "../../Article"
import InfoLink, { InfoType } from "views/articles/shared/InfoLink"
import SimpleGrid, { SimpleContent } from "views/articles/shared/simpleGrid/SimpleGrid"
// import toolbarImg from "../media/exhibit-pane-toolbar.PNG";
const toolbarImg = require('../../media/exhibit-pane-toolbar.PNG').default;
const ExhibitManagementArticle = (props: any) => {
    const navigate = useNavigate();
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);

    const userService = new UserService();
    const tenantService = new TenantService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dialogUtils = new DialogUtil();

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const media = prodInfoService.getMedia();

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    useEffect(() => {
    }, []);




    return(
        <Article 
            title="How to manage your exhibits"
            subtitle="The basics: Add, Remove, and Replace">
                <Section2>
                    <Text2>When you're preparing materials there may be times where you need to make adjustments to your exhibits. Zibbit provides many ways to handle this situation. In this article we are going to discuss the basics: <strong>Adding</strong>, <strong>Removing</strong>, and <strong>Replacing</strong> exhibits.</Text2>
                </Section2>
                <Section2>
                    <Stack2>
                    {/* <Paper elevation={0}>
                        <img src={media.get('ribbon').url} />
                    </Paper> */}
                    <Box>
                        <SimpleGrid>
                            <SimpleContent>
                            <Stack2>
                                        <Text2>Options for managing exhibits are available on the toolbar of the <InfoLink type={InfoType.ExhibitsPane}/></Text2>

                                        <Stack2>
                                            <Subtitle2>Add Exhibits</Subtitle2>
                                            <Text2>Click the <Icon2>add</Icon2> icon to open a file selection dialog. Then select the files you want to add to your collection of exhibits.</Text2>
                                            <Text2>When you're done, a copy of each selected file will be added to your exhibit folder.</Text2>
                                        </Stack2>
                                        <Stack2>
                                            <Subtitle2>Remove Exhibits</Subtitle2>
                                            <Text2>Click the <Icon2>delete_sweep</Icon2> icon and select the exhibits to remove.</Text2>
                                        </Stack2>
                                        <Stack2>
                                            <Subtitle2>Replace Exhibits</Subtitle2>
                                            <Text2>Replacing exhibits can be useful when you want to update a file, especially if you have already inserted exhibit references throughout your document.</Text2>
                                            <Text2>Click the <Icon2>more_vert</Icon2> icon beside the exhibit you want to update. Then click "Replace" from the menu and select the new file.</Text2>
                                            <Text2>When you're done, a copy of the selected file will be added to your exhibit folder.</Text2>
                                        </Stack2>
                                    </Stack2>

                            </SimpleContent>
                            <SimpleContent>
                            <Stack2>
                                        <img src={toolbarImg} />
                                    </Stack2>

                            </SimpleContent>
                        </SimpleGrid>
                    </Box>
                    </Stack2>
                </Section2>

        </Article>
    )


}


export default ExhibitManagementArticle