
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"

import { User } from "models/User"
import {UseOrganizationStore, Subscription as OrganizationSubscription, Update as UpdateOrganization} from 'stores/OrganizationStore';
import { Bootstrapper } from "Bootstrapper"
import {Context} from '../Context'
import { DialogUtil } from "utils/DialogUtil"
import LoadingButton from "components/buttons/LoadingButton"
import { FreeTrialStatus } from "models/FreeTrialStatus"


const UserMenu = (props: any) => {
    const {user, onRemoveUser, ...rest} = props;
    const onRemoveUsr: (users: User[]) => void = onRemoveUser;
    const userService = new UserService();
    const dialogUtils = new DialogUtil();
    const context = Bootstrapper.getInstance<Context>(Context);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showExtendModal, setShowExtendModal] = React.useState<boolean>();
    const [showResetEmailModal, setShowResetEmailModal] = React.useState<boolean>();
    const [canSubmit, setCanSubmit] = React.useState<boolean>(true);
    const [daysToExtend, setDaysToExtend] = React.useState<number>(null);
    const [newEmail, setNewEmail] = React.useState<string>(null);
    const open = Boolean(anchorEl);
    
    
    
    const menuClicked = (event: any) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation()
    };

    const handleClose = (e: any) => {
        setAnchorEl(null);
        e.stopPropagation();
    };

    const onResetEmailAsync = async(user: User) => {
        setCanSubmit(false);
        var result = await userService.forceResetEmailLinkAsync(user.id, newEmail || "").finally(() => {
            onCloseResetEmailModal();
            setCanSubmit(true);    
        })

        if(result === true){
            const users = await userService.getUsersAsync();
            onRemoveUsr(users);
        }

    }

    const onCloseResetEmailModal = () => {
        setShowResetEmailModal(false);
        setCanSubmit(true);
        setNewEmail(null);
    }


    const onExtendTrialAsync = async(user: User) => {
        setCanSubmit(false);
        await userService.extendFreeTrialAsync(user.id, daysToExtend);
        onCloseExtendTrialModal();
        setCanSubmit(true);
    }

    const onCloseExtendTrialModal = () => {
        setShowExtendModal(false);
        setCanSubmit(true);
        setDaysToExtend(null);
    }

    const onDeleteUserAsync = async(user: User) => {
        dialogUtils.confirmDialog(
            `Are you sure you want to delete this account: ${user.userName}?`,
            async(result) => {
                if(result){
                    context.setBusyIndicator(true);
                    await userService.deleteAccountAsync(user.id);
                    const users = await userService.getUsersAsync();
                    onRemoveUsr(users)
                    context.setBusyIndicator(false);
                }
            }
        )
    }
    return(
        <>
        <IconButton onClick={(e: any) => menuClicked(e)}>
            <Icon normal>more_vert</Icon>
        </IconButton>
        
            <Menu
            onClick={(e: any) => handleClose(e)}
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            >
                <MenuItem onClick={() => setShowExtendModal(true)}>
                    Extend Trial
                </MenuItem>
                <MenuItem onClick={() => setShowResetEmailModal(true)}>
                    Change Email
                </MenuItem>
                <MenuItem onClick={() => onDeleteUserAsync(user)}>
                    Remove
                </MenuItem>
            </Menu>

            {showExtendModal && <Dialog 
                action={<LoadingButton loading={!canSubmit} disabled={!canSubmit} onClick={() => onExtendTrialAsync(user)}>Submit</LoadingButton>}
                title="Extend Free Trial" 
                maxWidth="xs" 
                open={showExtendModal} 
                onClose={() => onCloseExtendTrialModal()}>
                        <Form 
                            flat 
                            >
                            <FieldCollection>
                                <TextField type="number" value={daysToExtend ?? ""} placeholder="Default" onChange={(ele: any) => setDaysToExtend(ele.target.value)} label="Days to extend" {...rest} />
                            </FieldCollection>
                        </Form>                    
                        </Dialog>}


                {showResetEmailModal && <Dialog 
                action={<LoadingButton loading={!canSubmit} disabled={!canSubmit} onClick={() => onResetEmailAsync(user)}>Submit</LoadingButton>}
                title="Reset Email" 
                maxWidth="xs" 
                open={showResetEmailModal} 
                onClose={() => onCloseResetEmailModal()}>
                        <Form 
                            flat 
                            >
                            <FieldCollection>
                                <TextField value={newEmail || ""} onChange={(ele: any) => setNewEmail(ele.target.value)} label="New Email" {...rest} />
                            </FieldCollection>
                        </Form>                    
                        </Dialog>}

        </>
    )

}


export default UserMenu