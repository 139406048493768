
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import Link from "components/buttons/Link"
import LoadingButton from "components/buttons/LoadingButton"
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';
import RequestDemoButton from "components/buttons/RequestDemoButton"
import { InlineWidget } from "react-calendly"




const Demo = (props: any) => {
    const {success, ...rest} = props;
    const navigate = useNavigate();
    const userService = new UserService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);
    const images = prodInfoService.getMedia();


    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [videoLoaded, setVideoLoaded] = useState<boolean>();


    useEffect(() => {
        //BusyUpdate(true);
        //setPlansAsync();
    }, []);


    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    const handleVideoLoaded = () => {
        BusyUpdate(false);
    }

    

    return(
        <Section sx={{flexGrow: 1}} bgColor={bg2}>
            <Container maxWidth="xs">


                <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div> 
                        <TitleBlock sx={{justifyContent: 'center'}}>
                            <Title textAlign="center">Request Demo</Title>
                            <SubHeading textAlign='center'>Book a meeting below</SubHeading>
                            {/* <Box mt={2} textAlign='center'><RequestDemoButton /></Box> */}
                        </TitleBlock>

                        <InlineWidget pageSettings={{}} url="https://calendly.com/zibbit" />


                    {/* <Box className="video-container">

                    <iframe
                    className="video" 
                    onLoad={() => handleVideoLoaded()} 
                    src="https://www.youtube.com/embed/XbKevHE1vRw" 
                    frameBorder="0" 
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
                    </Box> */}
                    </div>
                </Animation>
            </Container>
        </Section>
    )

}


export default Demo