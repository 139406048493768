
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { Tenant } from "models/Tenant"
import {UseOrganizationStore, Subscription as OrganizationSubscription, Update as UpdateOrganization} from 'stores/OrganizationStore';
import { TenantService } from "services/TenantService"
import Tooltip from "components/tooltip/Tooltip"
import CopyToClipboardButton from "components/buttons/CopyToClipboardButton"
import { Bootstrapper } from "Bootstrapper"
import { OrganizationContext } from "./OrganizationContext"
import { CommonUtil } from "utils/CommonUtil"
import { Toast } from "stores/models/Toast"
import { UseToastStore, Update as ToastUpdate } from 'stores/ToastStore';




const OrganizationInfoForm = (props: any) => {
    const {org, label, title, onSubmit, ...rest} = props;
    const organization: Tenant = org;
    const commonUtil = new CommonUtil();

    const tenantService = new TenantService();
    const userService = new UserService();

    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmitProfile, setCanSubmitProfile] = useState<boolean>(true);
    const [orgName, setOrgName] = useState<string>(organization?.name ?? null);

    const onSubmitProfileAsync = async() => {
        setCanSubmitProfile(false);
        if(orgName == null)
            setValidationMsg("The organization name must be provided");
        else{
            var copyOrg: Tenant = JSON.parse(JSON.stringify(organization));
            copyOrg.name = orgName;
            await tenantService.updateOrganizationDetailsAsyn(copyOrg);
            UpdateOrganization(copyOrg);
            onSubmit && onSubmit();
        }
        setCanSubmitProfile(true);
    }

    return(
        <Form 
        flat={title == null}
        title={title}
        validationMsg={validationMsg}
        >
            <FieldCollection>
                <TextField placeholder='Enter firm name' value={orgName || ""} onChange={(ele: any) => setOrgName(ele.target.value)} label={label} />
                <Button disabled={!canSubmitProfile} onClick={() => onSubmitProfileAsync()}>Submit</Button>
            </FieldCollection>
        </Form>

    )
}


export default OrganizationInfoForm