
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Computer, { IComputerProps } from "../../components/objects/computer/Computer"
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import './downloads.scss';
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import Link from "components/buttons/Link"
import { Bootstrapper } from "Bootstrapper"
import LoadingButton from "components/buttons/LoadingButton"
import { DialogUtil } from "utils/DialogUtil"
import DownloadsButton from "./DownloadsButton"
import { useSearchParams } from "react-router-dom"



const Downloads = (props: any) => {
    const [searchParams] = useSearchParams();
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);
    const images = prodInfoService.getMedia();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [hasRequirementsKeyParam, setHasRequirementsKeyParam] = useState<boolean>();
    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const isBeta = props.beta === true;
    useEffect(() => {
        const keyParam = Boolean(searchParams.get("requirements"));
        setHasRequirementsKeyParam(keyParam)
    }, []);

    const getBackgroundUrl = (src: string, isDim: boolean = false): string => {
        var dim = isDim ? `linear-gradient(to left, rgba(245, 246, 252, 0.1), rgba(245, 246, 252, 0.7)),` : '';
        var img = `${dim}url("${src}")`;

        return img;
    }

    const getCompStyle = () => {
        var img = "";
        var url = getBackgroundUrl(prodInfoService.GetProductImage(), true);

        let style: CSSProperties = {
            backgroundImage: url
        };
        return style;
    }

    
    const compProps: IComputerProps = {
        imgStyle: getCompStyle()
    };



    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    return(
        <Section bgColor={bg1} id='section-downloads' disablePadding>
                <Box className="content-wrapper">
                    <Grid container alignItems="stretch" spacing={0}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Item sx={{display:'flex', backgroundColor:'white', height: '100%'}}>
                                <Box display="flex" flexDirection="column" alignItems='stretch'>
                                    <Box padding={3} className="summary-wrapper">
                                        <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} duration='medium' type='slide' direction='up'>
                                            <div className="summary">
                                                <Stack pb={2} direction="row" alignItems="center">
                                                    <Title gutterBottom={false}>Zibbit</Title>
                                                </Stack>
                                                <div>
                                                    <Subtitle mb={3}>Microsoft Word Add-in</Subtitle>
                                                    <DownloadsButton beta={isBeta} hideDetailsLink />
                                                </div>
                                            </div>
                                        </Animation>
                                    </Box>
                                    <Box className="info">
                                        <ExpansionPanel key={hasRequirementsKeyParam} expanded={hasRequirementsKeyParam} title="System Requirements">
                                            <Box padding={0}>
                                                <List dense disablePadding>
                                                    <ListItem alignItems='flex-start'>
                                                        <Icon verySmall>circle</Icon>
                                                        <ListItemText>Windows Operating System</ListItemText>
                                                    </ListItem>
                                                    <ListItem alignItems='flex-start'>
                                                        <Icon verySmall>circle</Icon>
                                                        <ListItemText
                                                            primary="Microsoft Word"
                                                            secondary="2010 or later"
                                                        />
                                                    </ListItem>
                                                    <ListItem alignItems='flex-start'>
                                                        <Icon verySmall>circle</Icon>
                                                        <ListItemText
                                                            primary="Microsoft .NET Framework"
                                                            secondary="4.7.2 or later"
                                                        />
                                                    </ListItem>
                                                    <ListItem alignItems='flex-start'>
                                                        <Icon verySmall>circle</Icon>
                                                        <ListItemText
                                                            primary="Microsoft Visual Studio 2010 Tools for Office Runtime"
                                                            secondary="10.0.30319 or later"
                                                        />
                                                    </ListItem>
                                                    <ListItem alignItems='flex-start'>
                                                        <Icon verySmall>circle</Icon>
                                                        <ListItemText
                                                            primary="Microsoft Edge WebView2 Runtime"
                                                            secondary={<span><Link target="_blank" href="https://developer.microsoft.com/en-us/microsoft-edge/webview2/">Learn more</Link></span>}
                                                            >
                                                        </ListItemText>
                                                    </ListItem>
                                                </List>
                                            </Box>
                                        </ExpansionPanel>
                                        <ExpansionPanel title="Download Contents">
                                            <Box>
                                                <Text>1 file: the Microsoft Installer (.msi)</Text>
                                            </Box>
                                        </ExpansionPanel>
                                        <ExpansionPanel title="Instructions">
                                            <Box>
                                                <List dense disablePadding>
                                                    <ListItem alignItems='flex-start'>
                                                        <Icon verySmall>circle</Icon>
                                                        <ListItemText>After downloading the installer, double click it to run the short installation wizard.</ListItemText>
                                                    </ListItem>
                                                    <ListItem alignItems='flex-start'>
                                                        <Icon verySmall>circle</Icon>
                                                        <ListItemText>After completing the installation process, a new tab labeled "Zibbit" will appear in the ribbon of Microsoft Word.</ListItemText>
                                                    </ListItem>
                                                </List>

                                            </Box>
                                        </ExpansionPanel>
                                    </Box>
                                </Box>
                            </Item>
                        </Grid>
                        {!isMobile && <Grid item xs={12} sm={6} md={8}>
                            <Item sx={{height: '100%'}}>
                                <Box className="hero-wrapper">
                                    <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} duration='medium' transitionDelay={0} type='slide' direction='left'>
                                        <div className="hero">
                                            <Computer {...compProps} />
                                        </div>
                                    </Animation>
                                </Box>
                            </Item>
                        </Grid>}
                    </Grid>
                    </Box>
        </Section>
    )
}


export default Downloads