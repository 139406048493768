export class Card {
    constructor(title: string, description: string | React.ReactElement, icon: string = '', route: string = ''){
        this.title = title;
        this.description = description;
        this.icon = icon;
        this.route = route;
    }
    route: string;
    icon: string;
    title: string;
    description: string | React.ReactElement;
}