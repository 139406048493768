import isDev from 'MySettings';
import { UserService } from 'services/UserService';
import { ForbiddenError, UnauthorizedError, BadRequestError, NotFoundError, MissingEmailConfirmationError, AppError } from 'errors/ApplicationErrors';
import { TokenUtil } from 'utils/TokenUtil';
import axios from "axios";
import { IdentityClient } from 'api-clients/IdentityClient';
import {UseActiveUserIdStore, Subscription as ActiveUserIdSubscription, Update as UpdateActiveUserId} from 'stores/ActiveUserIdStore';
import {Subscription as OrganizationIdSubscription, Update as UpdateOrganizationId} from 'stores/ActiveUserOrganizationsStore';
import { TenantService } from 'services/TenantService';
import {container, InjectionToken} from "tsyringe";
import { Tenant } from 'models/Tenant';
import { CommonUtil } from 'utils/CommonUtil';

export class Bootstrapper {

    public static async initializeAsync(){
        if(!isDev()){
            console.log = () => {};
            console.warn = () => {};
        }
            
        Bootstrapper.setAuthInterceptor();
        await Bootstrapper.setUserInfoAsync();

    }

    public static async setUserInfoAsync(){
        const userService = Bootstrapper.getInstance<UserService>(UserService);
        const tenantService = Bootstrapper.getInstance<TenantService>(TenantService);
        
        if(userService.isAuthenticated()){
            var userId = userService.getActiveUserId();
            UpdateActiveUserId(userId);
            var orgsPermissionMap = await tenantService.getOrganizationPermissionMap(userId, userService);
            var orgs: Tenant[] = [];
            orgsPermissionMap.forEach((v,k) => {
                if(v) orgs.push(k);
            })
            UpdateOrganizationId(orgs);
        }
        else{
            UpdateOrganizationId([]);
        }
    }

    private static setAuthInterceptor(){
        const HEADER_NAME = 'Authorization';
        var userService = new UserService(); //injector.get(UserService);
        var identityClient = new IdentityClient(); //injector.get(IdentityClient); 
        var tokenUtil = new TokenUtil();
        
        // Add a request interceptor
        axios.interceptors.request.use(function (config) {
            console.log("auth request interceptor called", config.url)//, config.url, config);
            config.headers["audience-type"] = "Website";
            if(tokenUtil.isAutheticated){
                config.headers[HEADER_NAME] = tokenUtil.getAuthHeader();
            }
            
            return config;
        });

        // Add a response interceptor 
        axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, async function (error) {
            if(!error.response)
                throw new Error("Network Error");

            var status = error.response.status;
            console.warn(status);

            const errData = error.response.data;
            const errMsg = CommonUtil.isString(errData) ? errData : errData?.message ?? "Unknown error message"; //?.message;
            if(status === 500){
                throw new Error(errMsg ?? "An unknown error occured");
            }

            if(status === 404){
                throw new NotFoundError(errMsg);
            }
            if(status === 400 || status === 422){ // 422 - invalid token
                throw new BadRequestError(errMsg);

            }
            if(status === 412 && tokenUtil.isAutheticated)
                throw new MissingEmailConfirmationError(errMsg);
            
            if(status === 403) {
                throw new ForbiddenError(errMsg);
            } 
            if(status === 401) {
                console.warn("unauth response interceptor called")
                var isRefreshAttempt = error.response.config.url == identityClient.refreshUrl;
                if(isRefreshAttempt){
                    console.warn("caught refresh attempt");
                    window.location.href = "/login";
                    return;
                }
    
                var redirectUrl = ""; //error.response.config.url;
                if(!tokenUtil.isAutheticated)
                    throw new UnauthorizedError(redirectUrl);

                console.warn("attempting to refresh");
                var success = await userService.authRefreshAsync();

                if(!success) {
                    console.warn("refresh not successful");
                    throw new UnauthorizedError(redirectUrl);

                }

                console.warn("attempting to retry orig");//, error.config);
                return axios.request(error.config);        
                
                    
                //tokenUtil.setAuthInfo(null);
                
                // window.location.href ='/login';
                // return Promise.resolve(emptyResponse)
            }
            
            return Promise.reject(error.response);
        });
    }


    public static getInstance<T>(token: InjectionToken<T>) : T {
        //arguments
        //console.log("resolve " + typeName);
        //var container = Bootstrapper.container;
        var service = container.resolve(token);
        //var service = container.resolve(typeName);
        return service as T;
    }}