import * as React from 'react';
import {Box, Card as MuiCard, CardContent} from '@mui/material';
import { Card as ModelCard } from './models/Card';
import SubHeading from 'components/labels/SubHeading';
import Text from 'components/labels/Text';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Icon from 'components/icons/Icon';
import { ColorsUtil, MyTheme } from "MyTheme"
import Link from 'components/buttons/Link';

const CardFeature = (props: any) => {
    const {card, ...rest} = props;
    const c: ModelCard = card;
    const theme = MyTheme();

    const style = {
        //border: `3px solid ${theme.palette.primary.main}`,
        //background: 'transparent'
    }

    

    const icon = false; //c.icon;
    const route = c.route;
    const headerStyle = rest.link ? {
        //color: theme.palette.primary.main,
        borderBottom: `3px solid ${theme.palette.primary.main}`

    } : {};

    return (
        <MuiCard style={style} {...rest}>
            <CardContent>
                {icon && <Icon>{icon}</Icon>}
                <Box>
                    <SubHeading sx={headerStyle} variant="h5" component="h4" mb={1} mt={icon ? 1 : 0}>{c.title}</SubHeading>
                    <Text mt={1}>{c.description}</Text>
                </Box>
                {/* <Box textAlign='right' mt={1}>
                    {route && route}
                </Box> */}
            </CardContent>
        </MuiCard>
    ) 
}

export default CardFeature;