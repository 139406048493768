import { IdentityClient } from 'api-clients/IdentityClient';
import { PlansClient } from 'api-clients//PlansClient';

import {Card} from '../models/Card';
import {Installer} from '../models/Installer';
import { promises } from 'fs';
import { Benefit } from '../models/Benefit';
import { TutorialStep } from '../models/TutorialStep';
import { Plan } from 'models/Plan';
import { Price } from 'models/Price';
import { Media } from '../models/Media';
import { AppConfig } from 'AppConfig';
import { singleton } from 'tsyringe';
import { DialogUtil } from 'utils/DialogUtil';


export enum LogoType{
  Normal,
  Light,
  Dark,
  Transparent,
  WithText,
  WithTextTransparent,

}

@singleton()
export class ProductInfoService {
    private config: AppConfig = new AppConfig();
    private plansClient: PlansClient;
    private identityClient: IdentityClient;
    private productName: string;
    private logoUrl: string;
    private email: string;
    private videoLink: string;
    private imageUrls: string[];
    private cards: Card[];
    private installers: Installer[];
    private plans: Plan[];
    private dialogUtils = new DialogUtil();
  
    constructor(plansClient: PlansClient, identityClient: IdentityClient) {
      this.plansClient =  plansClient;
      this.identityClient = identityClient;
      this.productName = "Zibbit";
      this.email = "info@zibbit.ca";
      this.videoLink = "https://www.youtube.com/embed/vgILI-UchzI?autoplay=1"; //"https://www.youtube.com/embed/kbpVxiZWLMU?autoplay=1";
    }

    public get demoLink(): string{
      return this.videoLink;
    }

    public getSessions(priceId: string): Promise<string>{
      return this.plansClient.createCheckoutSession(priceId);
    }

    public getBenefits(): Benefit[]
    {
      var benefits = [];

      // var exhibitBen = new Benefit("Manage Exhibits");
      // exhibitBen.items.push("Add, remove, and re-order exhibits");
      // exhibitBen.items.push("Insert references to exhibits");
      // exhibitBen.items.push("Style references");

      var exhibitBen = new Benefit("Exhibits");
      exhibitBen.icon = "far fa-folder";
      exhibitBen.items.push("Add, remove, and re-order");
      exhibitBen.items.push("Supports pdf and docx files");
      exhibitBen.items.push("Document preview");

      var refBen = new Benefit("References");
      refBen.icon = "fas fa-link";
      refBen.items.push("Customize labels");
      refBen.items.push("Auto update");

      var publishBen = new Benefit("PDF");
      publishBen.icon = "far fa-file-pdf";
      publishBen.items.push("Appendix of exhibits");
      publishBen.items.push("Hyperlinked exhibit references");
      publishBen.items.push("Bookmarks");

      var templateBen = new Benefit("Templates");
      templateBen.icon = "fas fa-toolbox";
      templateBen.items.push("Affidavit");
      templateBen.items.push("Custom templates");
      templateBen.items.push("Dynamic properties");

      
      benefits.push(exhibitBen, refBen, publishBen, templateBen);
      return benefits;
    }

    public getInstallers(): Installer[]{
      return this.installers;
    }

    public getEmail(){
      return this.email;
    }

    public getLogoUrl(logoType: LogoType = LogoType.Normal): string{
      const base = this.config.getUploadsPath() + '/main/';
      switch(logoType){
        case LogoType.Dark:
          return base + 'Zibbit_Logo_Dark.svg';
        case LogoType.Transparent:
          return base + 'Zibbit_Logo_Trans.svg';
        case LogoType.WithText:
          return base + 'Zibbit_Logo_Stacked_Horizontal.png';
        case LogoType.WithTextTransparent:
          return base + 'Zibbit_Logo_Stacked_Horizontal_trans.svg';
  
        default:
          return base + 'Zibbit_Logo.svg';
  
      }
    }

    public getSpinnerUrl(): string{
      return this.config.getUploadsPath() + '/main/spinner-circle.gif';
    }

    private getInstaller(title: string, version: string): Installer{
      var downloadsPath = this.config.getUploadsPath() + '/installers/';
      var url = downloadsPath + `${title}_v${version}.msi`;
      var installer1 = new Installer(title + "_v" + version, version, url);
      return installer1;
    }




    public getOS = () => {
        let userAgent = window.navigator.userAgent.toLowerCase(),
          macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
          windowsPlatforms = /(win32|win64|windows|wince)/i,
          iosPlatforms = /(iphone|ipad|ipod)/i,
          os = null;
      
        if (macosPlatforms.test(userAgent)) {
          os = "macos";
        } else if (iosPlatforms.test(userAgent)) {
          os = "ios";
        } else if (windowsPlatforms.test(userAgent)) {
          os = "windows";
        } else if (/android/.test(userAgent)) {
          os = "android";
        } else if (!os && /linux/.test(userAgent)) {
          os = "linux";
        }
      
        return os;
    } 


    public async downLoadInstallerAsync(beta: boolean): Promise<boolean>{
      const downloadAsync = beta ? this.identityClient.getBetaInstallerAsync : this.identityClient.getLatestInstallerAsync;
      return await downloadAsync.call(this.identityClient)
      .then(data => {
        var arrayBuff = this.base64ToArrayBuffer(data.attachment);
        this.saveByteArray(data.filename, arrayBuff);
        return true;
      });
    }

    private base64ToArrayBuffer(base64: any) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
         var ascii = binaryString.charCodeAt(i);
         bytes[i] = ascii;
      }
      return bytes;
   }

   private saveByteArray(filename: string, byte: Uint8Array) {
    var blob = new Blob([byte], {type: "application/octet-stream"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    link.remove();
}

    public GetProductImage(): string {
      var imgs = this.imageUrlDict;

      return imgs.get('exhibits-window')?.url ?? "";
    }

    public getImageUrls(): string[]{
      return this.imageUrls;
    }

    public getCards(){
      return this.cards;
    }

    public getImage(imgPath: string, parentDir: string = "product-images")
    {
      return this.config.getUploadsPath() + `/${parentDir}/` + imgPath;
    }

    public getVideo(vidPath: string){
      return this.config.getUploadsPath() + '/videos/' + vidPath;
    }


    // get tutorial(): Map<string, TutorialStep[]>{
    //   var imgs = this.imageUrlDict;
    //   var tutorialMap = new Map<string, TutorialStep[]>();

    //   var step_1a = new TutorialStep("Exhibit Folder", imgs.get('get-started').url, imgs.get("icon-folder").url, 1);
    //   step_1a.items.push("Select a folder to manage your collection of PDF exhibits.");
    //   step_1a.items.push("These exhibits will be used to generate an appendix.");


    //   var step_1b = new TutorialStep("Manage Exhibits", imgs.get('manage-exhibits').url, imgs.get("icon-folder").url, 1);
    //   step_1b.items.push("Exhibits can be added, removed, and re-ordered at any time.");
    //   step_1b.items.push("After an exhibit is added, it can be referenced throughout your document.");


    //   var step_2a = new TutorialStep("Insert References", imgs.get("insert-ref").url, imgs.get("icon-folder").url, 1);
    //   step_2a.items.push("Insert references to your exhibits throughout your document.");
    //   step_2a.items.push("References can hyperlink to exhibits in your appendix.");
    //   step_2a.items.push("Exhibits can be sorted based on the order of appearance of references in the document.");


    //   var step_2b = new TutorialStep("Style References", imgs.get("style-ref").url, imgs.get("icon-style").url, 3);
    //   step_2b.items.push("Choose the label text, style, and sequence type (alphabetical / numeric)");

    //   var step_3a = new TutorialStep("Publish PDF", imgs.get("publish").url, imgs.get("icon-publish").url, 3);
    //   step_3a.items.push("Generate a PDF document with an appendix of exhibits.");
    //   step_3a.items.push("Decide which exhibits to include in your appendix.");
    //   step_3a.items.push("Create PDF bookmarks (optional).");



    //   tutorialMap.set("Exhibits", [step_1a ]); //, step_1b]);
    //   tutorialMap.set("References", [step_2a]);//, step_2b]);
    //   tutorialMap.set("Publish", [step_3a]);//, step_2b]);
    //   return tutorialMap;
    // }

    public getRibbonIcons(): Media[]{
      return [
       
        this.imageUrlDict.get('icon-zibbit-ribbon'),
        this.imageUrlDict.get('icon-folder'),
        this.imageUrlDict.get('icon-insert'),
        this.imageUrlDict.get('icon-table'),
        this.imageUrlDict.get('icon-sync'),
        this.imageUrlDict.get('icon-publish'),
        this.imageUrlDict.get('icon-templates'),
      ]
    }

    public getMedia(): Map<string, Media> {
        return this.imageUrlDict;
    }
    public get imageUrlDict(): Map<string, Media>{
      var urlMap = new Map<string, Media>();

      urlMap.set("feature-img", new Media(this.getImage('feature-img.PNG', '..'), "Feature image"));
      urlMap.set("edit", new Media(this.getImage('edit.svg', 'other'), "Edit document"));

      urlMap.set("vid-demo1", new Media(this.getVideo("Zibbit Short Demo1.mp4"), "Zibbit walk through"));
      urlMap.set('vid-marketing', new Media(this.getVideo("Zibbit Marketing-short.mp4"), "Zibbit Addin Introduction"));
      urlMap.set("vid-import", new Media(this.getVideo("Zibbit Marketing-meet deadlines_v4.mp4"), "Zibbit's three step process"));

      urlMap.set("animation-import", new Media(this.getImage('animations/import-exhibits.gif'), "Step 1. import exhibits into document"));
      urlMap.set("animation-reference", new Media(this.getImage('animations/reference-exhibits.gif'), "Step 2. reference exhibits throughout document"));
      urlMap.set("animation-publish", new Media(this.getImage('animations/publish.gif'), "Step 3. publish PDF for filing"));


      urlMap.set("ribbon", new Media(this.getImage('ribbon.PNG'), "Zibbit ribbon buttons"));
      urlMap.set("get-started", new Media(this.getImage('get-started.PNG'), "Import exhibits into document"));
      urlMap.set("manage-exhibits", new Media(this.getImage('exhibits.PNG'), "Exhibits imported into working document"));
      urlMap.set("insert-ref", new Media(this.getImage('insert-ref.PNG'), "Insert reference to exhibit"));
      urlMap.set("style-ref", new Media(this.getImage('refLabel.PNG'), "Style reference labels"));
      urlMap.set("publish", new Media(this.getImage('publishPdf.PNG'), "Publish document to PDF"));
      urlMap.set("publish-success", new Media(this.getImage('publish-success.PNG'), "Successfully publish document to PDF"));
      urlMap.set("project", new Media(this.getImage('project.PNG'), "Create a new Zibbit project"));

      urlMap.set("get-started-window", new Media(this.getImage('full-window/getStarted.PNG'), "Import exhibits into document"));
      urlMap.set("exhibits-window", new Media(this.getImage('full-window/exhibits-blancdoc.PNG'), "Exhibits imported into new document"));
      urlMap.set("manage-exhibits-window", new Media(this.getImage('full-window/exhibits.PNG'), "Exhibits imported into working document"));
      urlMap.set("insert-ref-window", new Media(this.getImage('insert-ref-window.PNG'), "Insert reference to exhibit into document"));
      urlMap.set("style-ref-window", new Media(this.getImage('style-ref-window.PNG'), "Style reference labels"));
      urlMap.set("publish-window", new Media(this.getImage('full-window/publish-normal.PNG'), "Publish document to PDF"));

      urlMap.set("icon-zibbit", new Media(this.getImage('/Zibbit_Logo.png', 'main'),"Zibbit Logo"));
      urlMap.set("icon-zibbit-ribbon", new Media(this.getImage('icons/zibbit-dark.png'),"Zibbit Logo Dark"));
      urlMap.set("icon-folder", new Media(this.getImage('icons/exhibits.png'), "Exhibit Folder Icon"));
      urlMap.set("icon-insert", new Media(this.getImage('icons/references.png'), "References Icon"));
      urlMap.set("icon-publish", new Media(this.getImage('icons/publish.png'), "Publish PDF Icon"));
      urlMap.set("icon-word", new Media(this.getImage('icons/word.png'), "Microsoft Word Icon"));
      urlMap.set("icon-binder", new Media(this.getImage('icons/binder.png'), "Digital Binder Icon"));
      
      urlMap.set("icon-table", new Media(this.getImage('icons/table.png'), "Properties Icon"));
      urlMap.set("icon-sync", new Media(this.getImage('icons/sync.png'), "Sync Icon"));
      urlMap.set("icon-templates", new Media(this.getImage('icons/templates.png'), "Templates Icon"));

      urlMap.set("smartScreen", new Media(this.config.getUploadsPath() + '/other/SmartScreen.PNG', "Installation Warning"));
      urlMap.set("smartScreen-runanyway", new Media(this.config.getUploadsPath() + '/other/SmartScreen Run anyway.PNG', "Proceed with installation anyway"));

      urlMap.set("partner-liz", new Media(this.getImage('LIZ-logo.png', 'partners'), "Legal Innovation Zone"));

      urlMap.set("headshot", new Media(this.getImage('headshot.jpg', 'other'), "Evan Silverstein image"));

      return urlMap;
    }


}