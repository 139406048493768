import * as React from 'react';
import {Button as MuiButton, Card, CardContent, Stack}  from '@mui/material';
import Box from 'components/layout/box/Box';
import Heading from 'components/labels/Heading';
import Text from 'components/labels/Text';
import FieldCollection from 'components/fields/fieldCollection/FieldCollection';
import SubHeading from 'components/labels/SubHeading';

const Form = (props: any) => {
    const {flat, title, children, validationMsg, action, ...rest} = props;
    const elevation = flat ? 0 : 2;
    const formPadding = flat ? 0 : 2;
    return(
        <Card elevation={elevation} {...rest}>
            <Stack direction="column" spacing={1} p={formPadding}>
                {validationMsg && <Box><Text textAlign="center" color="error">{validationMsg}</Text></Box>}
                {title && <Box textAlign="center"><SubHeading component="span">{title}</SubHeading></Box>}

                <CardContent sx={{padding: '0px !important'}}>
                    {children}
                </CardContent>
                {action && <Box>{action}</Box>}

            </Stack>

    </Card>


    )
}

export default Form;