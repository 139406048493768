
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"
import { Tenant } from "models/Tenant"
import { BadRequestError } from "errors/ApplicationErrors"
import { Invitation } from "models/Invitation"
import {UseOrganizationStore, Subscription as OrganizationSubscription, Update as UpdateOrganization} from 'stores/OrganizationStore';
import { Bootstrapper } from "Bootstrapper"
import { OrganizationContext } from "./OrganizationContext"
import { DialogUtil } from "utils/DialogUtil"
import LoadingButton from "components/buttons/LoadingButton"


const Invitations = (props: any) => {
    const navigate = useNavigate();
    const context = Bootstrapper.getInstance<OrganizationContext>(OrganizationContext);

    const userService = new UserService();
    const tenantService = new TenantService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dialogUtils = new DialogUtil();

    const userId = userService.getActiveUserId();
    const organization = UseOrganizationStore();

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [invites, setInvites] = useState<Invitation[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [inviteeEmail, setInviteeEmail] = useState<string>("");
    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    useEffect(() => {
        loadDataAsync();
    }, []);

    const loadDataAsync= async() => {
        context.setBusyIndicator(true);
        const invites = await tenantService.getInvitationsAsync(organization?.id);
        setInvites(invites);
        context.setBusyIndicator(false);
    }


    const InviteMenu = (props: any) => {
        const {invite, ...rest} = props;
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        
        
        const menuClicked = (event: any) => {
            setAnchorEl(event.currentTarget);
            event.stopPropagation()
        };
    
        const cancelMenuItemClicked = async(invite: Invitation) => {
            dialogUtils.confirmDialog(
                `Are you sure you want to cancel the invitation to ${invite.inviteeEmail}?`,
                async(confirm) => {
                    if(!confirm)
                        return;
                    
                    await onCancelInviteAsync(invite);
                }
            )

        }

        const extendMenuItemClicked = async(invite: Invitation) => {
            dialogUtils.confirmDialog(
                `Are you sure you want to extend the invitation to ${invite.inviteeEmail}?`,
                async(confirm) => {
                    if(!confirm)
                        return;

                    await onExtendInviteAsync(invite);
                }
            )

        }
        
        const handleClose = (e: any) => {
            setAnchorEl(null);
            e.stopPropagation();
        };

        const onCancelInviteAsync = async(invite: Invitation) => {
            context.setBusyIndicator(true);
            await tenantService.cancelInvitationsAsync(invite.id);
            const invites = await tenantService.getInvitationsAsync(organization.id);
            setInvites(invites);
            context.setBusyIndicator(false);
        }
    
        const onExtendInviteAsync = async(invite: Invitation) => {
            context.setBusyIndicator(true);
            await tenantService.extendInvitationsAsync(invite.id, 7, true);
            const invites = await tenantService.getInvitationsAsync(organization.id);
            setInvites(invites);
            context.setBusyIndicator(false);
        }   

        return(
            <>
            <IconButton onClick={(e: any) => menuClicked(e)}>
                <Icon normal>more_vert</Icon>
            </IconButton>
            
                <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClick={(e: any) => handleClose(e)}
                >
    
                    <MenuItem onClick={(e: any) => extendMenuItemClicked(invite)}>
                        Extend
                    </MenuItem>
                    <MenuItem onClick={(e: any) => cancelMenuItemClicked(invite)}>
                        Cancel
                    </MenuItem>
                </Menu>
            </>
        )
    
    }



    const getInvitesList = (invites: Invitation[]) => {
        return(
            <>
                <Table className={isMobile ? "responsive-table" : ""} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell align="center">Sent</TableCell>
                            <TableCell align="center">Expired</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invites.map(invite => {
                            return (
                                <TableRow
                                key={invite.id}
                            >
                                <TableCell data-label="Email" component="th" scope="row">{invite.inviteeEmail}</TableCell>
                                <TableCell data-label="Sent" align="center" scope="row">{new Date(invite.created).toLocaleDateString()}</TableCell>
                                <TableCell data-label="Expired" align="center" scope="row">{isExpired(invite).toString()}</TableCell>
                                <TableCell data-label="Action" align={isMobile ? "left" : "right"}>
                                    <InviteMenu invite={invite} />
                                </TableCell>
                            </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </>
        )
    }


    const isExpired = (invite: Invitation): boolean => {
        var current = new Date();
        var expires = new Date(invite.expires);
        var isExpired = current > expires;
        return isExpired;
    }

    return(
        <Section disablePadding id="section-plans" bgColor={bg1}>
                <Animation duration="medium" overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                        <Stack spacing={1}>

                                <Paper>
                                    {getInvitesList(invites)}
                                </Paper>
                        </Stack>
                    </div>
                </Animation>


        </Section>

    )


}


export default Invitations