export class BusyIndicator{
    constructor(isBusy: boolean, label: string = null, value: number = null){
      this.isBusy = isBusy;
      this.label = label;
      this.value = value;
    }
    public isBusy: boolean;
    public label: string;
    public value: number;
  
  }