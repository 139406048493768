export class Toast{
    constructor(open: boolean, message: string = null, autoHide: boolean = true){
      this.open = open;
      this.message = message;
      this.autoHide = autoHide;
    }

    public open: boolean;
    public autoHide: boolean;
    public message: string;  
  }