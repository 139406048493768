
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography, useMediaQuery, Zoom } from "@mui/material"
import React, { Children, useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"
import { Tenant } from "models/Tenant"
import { AppError, BadRequestError } from "errors/ApplicationErrors"
import { Bootstrapper } from "Bootstrapper"
import LoadingButton from "components/buttons/LoadingButton"
import { DialogUtil } from "utils/DialogUtil"
import { Icon2, Section2, Stack2, Subtitle2, Text2 } from "views/articles/Article"



export const SimpleContent = (props: any) => {
    return <>{props.children}</>
}

const SimpleGrid = (props: any) => {
    const {reverse, spacing, orderOverride, children, ...rest} = props;
    const arrayChildren = Children.toArray(children);
    
    if(arrayChildren.length != 2)
        throw new Error('SimpleGrid only accepts 2 children for left and right content');
    // if(arrayChildren.some(c => !(c instanceof SimpleContent)))
    //     throw new Error('SimpleGrid only accepts SimpleContent components');
    
    const leftComponent = arrayChildren[0];
    const rightComponent = arrayChildren[1];
    const space = spacing ?? 3;
    const order = orderOverride ?? reverse ? {xs: 0, md: 1} : {xs: 0}
    return(

            <Grid container alignItems="start" spacing={space}>
                <Grid item xs={12} sm={7} order={order}>
                    <Item>
                        {leftComponent}
                    </Item>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Item>
                        {rightComponent}
                    </Item>
                </Grid>
            </Grid>
    )
}

// SimpleGrid.left = (props: any) => <>{props.children}</>


export default SimpleGrid