import { Box, CardActions, CardHeader, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import * as React from 'react';
import {Card as MuiCard, CardContent} from '@mui/material';
import Icon from 'components/icons/Icon';
import SubHeading from 'components/labels/SubHeading';
import Button from 'components/buttons/Button';
import Container from 'components/layout/Container';
import { ColorsUtil, ColourType, MyTheme } from 'MyTheme';
import LoadingButton from 'components/buttons/LoadingButton';
import { useState } from 'react';
import Dialog from 'components/modals/Dialog';
import RibbonPane from './panes/RibbonPane';
import { Bootstrapper } from 'Bootstrapper';
import { ProductInfoService } from 'services/ProductInfoService';
import ProjectPane from './panes/ProjectPane';

export enum InfoType{
    ZibbitPane,
    ExhibitsPane,
    PublishPane,
    ProjectPane,
}

interface InfoProps{
    type: InfoType;
}


const InfoLinkButton = (props: any) => {
    const {btnLabel, dlgTitle, children, ...rest} = props;
    const [open, setOpen] = React.useState<boolean>(false);
    const theme = MyTheme();

    return(
        <>
            <Button sx={{p: 0}} onClick={() => setOpen(true)} variant='text'>{btnLabel}</Button>
            <Dialog
                maxWidth="sm" 
                open={open}
                onClose={() => setOpen(false)}
                title={dlgTitle} 
                children={children}
                // action={<Button variant="contained" onClick={() => cb(true)}>Ok</Button>}
                {...rest}
            >
            </Dialog>
        </>
    ) 
}

const InfoLink = (props: InfoProps) => {
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);
    const media = prodInfoService.getMedia();
    const ribbonMedia = media.get('ribbon');

    const getView = (type: InfoType) => {
        const dlgTitlePrefix = "How to open the "
        switch(type){
            case InfoType.ZibbitPane:{
                const paneName = "Zibbit Pane";
                const dlgTitle = dlgTitlePrefix + paneName;
                const icon = <img style={{maxWidth: "30px"}} src={media.get('icon-zibbit-ribbon').url}/>
                const paneMedia = media.get('get-started');
                return <InfoLinkButton dlgTitle={dlgTitle} btnLabel={paneName}>
                    <RibbonPane name={paneName} icon={icon} paneMedia={paneMedia} ribbonMedia={ribbonMedia}   />
                    </InfoLinkButton>

            }
            case InfoType.ExhibitsPane:{
                const paneName = "Exhibits Pane";
                const dlgTitle = dlgTitlePrefix + paneName;
                const icon = 'folder';
                const paneMedia = media.get('manage-exhibits');
                return <InfoLinkButton dlgTitle={dlgTitle} btnLabel={paneName}>
                        <RibbonPane name={paneName} icon={icon} paneMedia={paneMedia} ribbonMedia={ribbonMedia}   />
                    </InfoLinkButton>
            }
            case InfoType.PublishPane:{
                const paneName = "Publish Pane";
                const dlgTitle = dlgTitlePrefix + paneName;
                const icon = 'download';
                const paneMedia = media.get('publish');
                return <InfoLinkButton dlgTitle={dlgTitle} btnLabel={paneName}>
                        <RibbonPane name={paneName} icon={icon} paneMedia={paneMedia} ribbonMedia={ribbonMedia}   />
                    </InfoLinkButton>
            }
            case InfoType.ProjectPane:{
                const paneName = "Project";
                const dlgTitle = 'About: ' + paneName;
                return <InfoLinkButton dlgTitle={dlgTitle} btnLabel={paneName}>
                        <ProjectPane />
                    </InfoLinkButton>
            }
        }
    }
    return getView(props.type)

    
}

export default InfoLink;