import { Box, CardActions, CardHeader, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import * as React from 'react';
import {Card as MuiCard, CardContent} from '@mui/material';
import Icon from 'components/icons/Icon';
import SubHeading from 'components/labels/SubHeading';
import Button from 'components/buttons/Button';
import Container from 'components/layout/Container';


const Dialog = (props: any) => {
    const {onClose, action, children, title, open, ...rest} = props;
    const titleLabel = title ?? null;
    return(
        <MuiDialog open={open} fullWidth onClose={onClose} {...rest}>
            {titleLabel && <DialogTitle>
                <Box alignItems="center" justifyContent='space-between' display='flex'>
                    <SubHeading component="span">{titleLabel}</SubHeading>
                    <IconButton edge="end" onClick={onClose}>
                        <Icon normal>close</Icon>
                    </IconButton>
                </Box>
            </DialogTitle>}

                    {/* <IconButton sx={{position: 'absolute', top: 0, right: -20}} onClick={onClose}>
                    <Icon>close</Icon> 
                </IconButton>*/}
            <DialogContent>
                    {children}
            </DialogContent>
            {action && <DialogActions>
                {action}
            </DialogActions>}
        </MuiDialog>
    ) 
}
export default Dialog;