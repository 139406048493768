import { Tenant } from './../models/Tenant';
import { CustomerStatus } from './../models/CustomerStatus';
import { TokenUtil } from 'utils/TokenUtil';
import { IdentityClient } from 'api-clients/IdentityClient';
import { PlansClient } from 'api-clients/PlansClient';

import {Card} from '../models/Card';
import {Installer} from '../models/Installer';
import { promises } from 'fs';
import { Benefit } from '../models/Benefit';
import { TutorialStep } from '../models/TutorialStep';
import { Plan } from 'models/Plan';
import { Price } from 'models/Price';
import { Invitation } from 'models/Invitation';
import { singleton } from 'tsyringe';
import { CommonUtil } from 'utils/CommonUtil';
import { UserService } from './UserService';
import { PermissionRequest } from "models/PermissionRequest"
import { ResourceType } from "models/ResourceType"
import { AppScopes, ScopeUtils } from "models/PermissionScopes"
import { MembershipInfo } from 'models/MembershipInfo';

@singleton()
export class TenantService {
    private authClient: IdentityClient;
    private plansClient: PlansClient;


  
    constructor(authClient: IdentityClient = null, plansClient: PlansClient = null) {
      this.authClient = authClient ?? new IdentityClient();
      this.plansClient = plansClient ?? new PlansClient();
    }

    public async getInvitationsAsync(orgId: string): Promise<Invitation[]>{
      return await this.authClient.getInvitesByOrgAsync(orgId);
    }

    public async cancelInvitationsAsync(inviteId: string): Promise<boolean>{
      return await this.authClient.cancelInviteAsync(inviteId);
    }

    public async extendInvitationsAsync(inviteId: string, daysExtended: number, sendEmail: boolean): Promise<boolean>{
      return await this.authClient.extendInviteAsync(inviteId, daysExtended, sendEmail);
    }

    public async getOrganizationOwnerIdAsyn(tenantId: string): Promise<string>{
      return await this.authClient.getOrganizationOwnerIdAsync(tenantId);
    }

    public async isSubscriptionValidAsyn(tenantId: string): Promise<boolean>{
      return await this.authClient.isSubsciptionValidAsync(tenantId);
    }

    public async getOrganizationPlanAsyn(tenantId: string): Promise<Plan>{
      return await this.plansClient.getPlanAsync(tenantId);
    }

    public async updateOrganizationDetailsAsyn(org: Tenant): Promise<string>{
      return await this.authClient.updateOrganizationDetailsAsync(org);
    }

    public async getNewOrganizationRegKey(orgId: string): Promise<string>{
      return await this.authClient.newOrganizationRegKeyAsync(orgId);
    }

    public async getOrganizationRegKey(orgId: string): Promise<string>{
      return this.authClient.getOrganizationRegKeyAsync(orgId);
    }

    public async deleteOrganizationAsync(orgId: string): Promise<boolean>{
      var result = await this.authClient.deleteOrganizationAsync(orgId);
      return result;
    }

    public async getOwnerOrganization(): Promise<Tenant>{
      return this.authClient.getOwnerOrgAsync();
    }

    public getOwnerOrganizationId(orgs: Tenant[], userId: string): string {
      var o = orgs.find(o => o.ownerId === userId);
      return o != null ? o.id : null;
    }

    public async getOrganizationById(orgId: string): Promise<Tenant>{
      return this.authClient.getOrganizationAsync(orgId);
    }

    public async getOrganizationsAsync(): Promise<Tenant[]>{
      return this.authClient.getOrganizationsAsync();
    }

    public async getOrganizationMembershipInfoAsync(orgId: string): Promise<MembershipInfo[]>{
      return this.authClient.getOrganizationMembershipInfoAsync(orgId);
    }

    public async getOrganizationsByUserAsync(userId: string): Promise<Tenant[]>{
      // const util = new CommonUtil();
      // await util.timeoutAsync(6000);
      var result = await this.authClient.getOrganizationsByUserAsync(userId);
      return result;
    }

    public async requestAccessToOrganizationAsync(regKey: string): Promise<boolean>{
      var result = await this.authClient.requestAccessToOrganizationAsync(regKey);
      return result;
    }

    public async cancelTenantMembership(tenantId: string): Promise<boolean>{
      return await this.authClient.cancelTenantMembershipAsync(tenantId);
    }

    public async revokeAccessToOrganizationAsync(orgId: string, userId: string): Promise<boolean>{
      var result = await this.authClient.revokeAccessToOrganizationAsync(orgId, userId);
      return result;
    }

    public async updateTenantMembersDeactivationStatusAsync(orgId: string, activatedUserIds: string[]): Promise<boolean>{
      var result = await this.authClient.updateOrganizationMembersDeactivatedStatusAsync(orgId, activatedUserIds);
      return result;
    }

    public async reactivateTenantMemberAsync(orgId: string, userId: string): Promise<boolean>{
      var result = await this.authClient.reactivateOrganizationMemberAsync(orgId, userId);
      return result;
    }

    

    public async registerOrganizationAsync(orgName: string): Promise<boolean>{
      var result = await this.authClient.registerOrganizationAsync(orgName);
      return result;
    }

    public async replaceOrganizationOwnerAsync(orgId: string, userId: string): Promise<boolean>{
      var result = await this.authClient.replaceOrganizationOwnerAsync(orgId, userId);
      return result;
    }

    public async startPilotProgramAsync(orgId: string): Promise<boolean>{
      var result = await this.authClient.startOrganizationPilotAsync(orgId);
      return result;
    }

    public async cancelPilotProgramAsync(orgId: string): Promise<boolean>{
      var result = await this.authClient.cancelOrganizationPilotAsync(orgId);
      return result;
    }


    public async getOrganizationPermissionMap(userId: string, userService: UserService): Promise<Map<Tenant, boolean>>{
      var orgs = await this.getOrganizationsByUserAsync(userId);
      var orgMap = new Map<string, Tenant>();
      orgs.forEach(o => {
        orgMap.set(o.id, o);
      })

      var pPromises = orgs.map(o => {
          var request = new PermissionRequest();
          request.RequestingUserId = userId;
          request.resourceOwnerId = o.id;
          request.resourceType = ResourceType.TenantAccount;
          return userService.getPermissionTicketAsync(request);
      });


      var tickets = await Promise.all(pPromises);
      var orgPermissions = new Map<Tenant, boolean>();
      tickets.forEach(t => {
          var canWriteTenant =  ScopeUtils.containsScope(t, AppScopes.orgAccount.Write);
          var org = orgMap.get(t.request.resourceOwnerId);
          orgPermissions.set(org, canWriteTenant);
      })
      return orgPermissions;
  }
}