
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';
import LoadingButton from "components/buttons/LoadingButton"





const PasswordResetForm = (props: any) => {
    const {onCompleted, inline, hideTitle, ...rest} = props;
    const navigate = useNavigate();
    const userService = new UserService();


    const isAuthenticated = userService.isAuthenticated();
    const [searchParams] = useSearchParams();
    const [isNewUser, setIsNewUser] = useState<boolean>();
    const [token, setToken] = useState<string>();
    const [userId, setUserId] = useState<string>();
    const [email, setEmail] = useState<string>("");


    const [password, setPassword] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [validationMsg, setValidationMsg] = useState<string>("");


    useEffect(() => {
        loadDataAsync();
    }, []);


    const loadDataAsync = async() => {
        BusyUpdate(true);
        const activeUser = isAuthenticated ? await userService.getUserAsync(userService.getActiveUserId()) : null;

        const t = searchParams.get("token");
        const uId = searchParams.get("userId") || activeUser?.id;
        const e = searchParams.get("email") || activeUser?.userName

        setIsNewUser(Boolean(searchParams.get("newAccount")))
        setToken(t);
        setUserId(uId);
        setEmail(e);
            
        BusyUpdate(false);        

    }


    const onSubmitAsync = async() => {
        if(!canSubmit)
            return;

        setValidationMsg("");
        if(password == ""){
            setValidationMsg("Password is required");
            return;
        }
        
        setCanSubmit(false);
        var result = await userService.updatePasswordAsync(userId, password, token, email).catch((err: Error) => {
            if(err.message == null)
                throw err;
            setValidationMsg(err.message);
            
        });
        if(result){
            onCompleted && onCompleted(result);

        }
        
        setCanSubmit(true);
    }
    return(
        <Form 
        flat={inline}
        validationMsg={validationMsg}
        action={<LoadingButton loading={!canSubmit} disabled={!canSubmit} onClick={() => onSubmitAsync()} fullWidth>Submit</LoadingButton>}
        title={hideTitle ? null : (isNewUser ? "Complete Registration" : "Reset Password")}>
            <FieldCollection>
                {email && <TextField id="username" name="username" isReadOnly value={email} label="Email" {...rest} />}
                <TextField id="password" name="password" type="password" value={password} onChange={(ele: any) => setPassword(ele.target.value)} label="Password" {...rest} />
            </FieldCollection>
        </Form>
    )

}


export default PasswordResetForm