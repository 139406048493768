
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Chip, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"
import { Tenant } from "models/Tenant"
import { BadRequestError } from "errors/ApplicationErrors"
import { Invitation } from "models/Invitation"
import {UseOrganizationStore, Subscription as OrganizationSubscription, Update as UpdateOrganization} from 'stores/OrganizationStore';
import { Bootstrapper } from "Bootstrapper"
import { OrganizationContext } from "./OrganizationContext"
import { DialogUtil } from "utils/DialogUtil"
import LoadingButton from "components/buttons/LoadingButton"
import SelectField from "components/fields/selectField/SelectField"


const InviteUsersButton = (props: any) => {
    const {maxInvites, onSubmit, ...rest} = props;
    const max: number = maxInvites;
    const userService = new UserService();
    const tenantService = new TenantService();
    const theme = MyTheme();
    const dialogUtils = new DialogUtil();

    const organization = UseOrganizationStore();

    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [inviteeEmail, setInviteeEmail] = useState<string>("");
    const [emails, setEmails] = useState<string[]>([]);
    const [role, setRole] = useState<string>("User");

    const isAdmin = userService.isAdmin();

    const onCloseModal = () => {
        setIsOpen(false);
        setCanSubmit(true);
        setEmails([]);
        setInviteeEmail(null);
        setValidationMsg(null);
    }

    const handleNewInviteAsync = async() => {
        if(!canSubmit)
            return;
        setCanSubmit(false);
        setValidationMsg("");

        if(emails.length > 0){
            var result = await userService.InviteUsersToOrganizationAsync(emails, organization.id, role)
            if(result.length == 0){
                onCloseModal();
                setCanSubmit(true);
                onSubmit && onSubmit();
                return;
            }
            var msgSuffix = result.join('\n');
            dialogUtils.confirmDialog("Unable to send invitatins to the following emails:" + '\n' + msgSuffix, (r) => {
                onCloseModal();
                setCanSubmit(true);
                onSubmit && onSubmit();
            })
        }

    }

    const validateEmail = (email: string) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };

    const handleAddEmail = (email: string) => {
        if(!validateEmail(email)){
            setValidationMsg("This email address is not valid")
            return;
        }
        if(emails.findIndex(e => e == email) > -1){
            setValidationMsg("This email address was already added")
            return;

        }

        setValidationMsg("");
        setInviteeEmail("");
        emails.push(email)
        setEmails([...emails]);
    }

    const handleRemoveEmail = (i: number) => {
        emails.splice(i, 1);
        setEmails([...emails]);
    }

    const canInivte = emails.length < max;
    const icon = <IconButton edge="end" onClick={() => handleAddEmail(inviteeEmail || "")}><Icon small>add</Icon></IconButton>
    return(
        <>
                <Button onClick={() => setIsOpen(true)} {...rest}>Invite Users</Button>
                <Dialog
                    title="Invite Users"
                    action={<LoadingButton loading={!canSubmit} disabled={emails.length == 0 || !canSubmit} onClick={() => handleNewInviteAsync()}>Submit</LoadingButton>}
                    maxWidth="xs" 
                    open={isOpen} 
                    onClose={() => onCloseModal()}>
                            <Form flat validationMsg={validationMsg || ""}>
                                <FieldCollection>
                                    <TextField disabled={!canInivte} endAdornment={canInivte ? icon : null} label="Email" value={inviteeEmail || ""} onChange={(ele: any) => setInviteeEmail(ele.target.value)} />
                                    {isAdmin && <SelectField label="Role" value={role} onChange={(e: any)=>setRole(e.target.value)}>
                                        <MenuItem value="User">Member</MenuItem>
                                        <MenuItem value="TenantManager">Manager</MenuItem>
                                    </SelectField>}
                                </FieldCollection>
                            </Form>
                            <List disablePadding dense>
                                {emails.map((email, i) => {
                                    return (
                                        <ListItem key={i} disablePadding>
                                            <ListItemText className="truncate">{email}</ListItemText>
                                            <IconButton onClick={() => handleRemoveEmail(i)}><Icon small>delete</Icon></IconButton>
                                        </ListItem>
                                    )
                                })} 

                            </List>
                            {/* <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                                {emails.map((email, i) => {
                                    return (
                                        <Chip
                                            key={i}
                                            label={email}
                                            onDelete={() => handleRemoveEmail(i)}
                                            />
                                    )
                                })} 
                            </Stack> */}
                 
                </Dialog>
        </>
    )


}


export default InviteUsersButton