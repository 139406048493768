import * as React from 'react';
import {Button as MuiButton, Fade, Slide, Zoom, ZoomProps}  from '@mui/material';
import useVisibility from "hooks/UseVisibility"
import { jsx } from '@emotion/react';
import { TransitionProps } from '@mui/material/transitions';
import { useState } from 'react';

export type AnimationType = 'zoom' | 'slide' | 'fade'; 
export type AnimationDuration = 'fast' | 'medium' | 'slow';

const getDuration = (type: AnimationDuration) => {
    switch(type){
        case 'fast':
            return 500;
        case 'medium':
            return 1000;
    }
    return 1500;
}

const Animation = React.forwardRef((props: any, ref) => {
    const {overrideAnimation, type, duration, transitionDelay, ...rest} = props;
    const transDuration: AnimationDuration = duration ?? 'slow';
    const transDelay = (transitionDelay ?? 0) * 500;
    const anType: AnimationType = type ?? 'zoom';
    const [isCompEleVisible, setRef] = useVisibility<HTMLElement | null>();

    
    // const transitionStyles = {
    //     entering: { opacity: 1 },
    //     entered:  { opacity: 1 },
    //     exiting:  { opacity: 0 },
    //     exited:  { opacity: 0 },
    // };
    
    var transProps = {
        //...transitionStyles,
        style: {transitionDelay: transDelay},
        ref: (r: any) => setRef(r),
        mountOnEnter: false,
        timeout: getDuration(transDuration),
        in: overrideAnimation ?? isCompEleVisible,
        ...rest,

    }



    const getTransition = (type: AnimationType, rest: any): JSX.Element => {
        switch(type){
            case 'zoom':
                return <Zoom {...rest} />            
            case 'slide':
                return <Slide direction="up" {...rest} />
        }
        return <Fade {...rest} />            

    }
    
    return(
        getTransition(anType, transProps)
    )
})

export default Animation;