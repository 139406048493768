import {IconButton, List, ListItem, ListItemButton, ListItemText, Snackbar } from '@mui/material'
import Icon from 'components/icons/Icon'
import { UseToastStore, Update as ToastUpdate } from 'stores/ToastStore';
import { Toast } from 'stores/models/Toast';
import Dialog from 'components/modals/Dialog';
import Form from 'components/forms/Form';
import FieldCollection from 'components/fields/fieldCollection/FieldCollection';
import TextField from 'components/fields/textField/TextField';
import { Bootstrapper } from 'Bootstrapper';
import { UserService } from 'services/UserService';
import { InlineWidget, PopupButton } from 'react-calendly';
import { Link, useLocation, useNavigate  } from 'react-router-dom';
import Button from 'components/buttons/Button';
import { Tenant } from 'models/Tenant';
import { UseActiveUserOrganizationIdStore } from 'stores/ActiveUserOrganizationsStore';
import Text from 'components/labels/Text';
import Box from 'components/layout/box/Box';
import { useState } from 'react';
import React from 'react';


const OrganizationsButton = (props: any) => {
  const {onNav, component, componentProps, ...rest} = props;
  const c = component ?? 'div';
  const cProps = componentProps ?? {};
  const navigate = useNavigate();
  const handleNav = (ownerOrgId: string) => {
    const to = `/organization/${ownerOrgId}`;
    navigate(to);
    onNav && onNav();
  }
  const organizations = UseActiveUserOrganizationIdStore();
  const orgs: Tenant[] = organizations ?? []; 
  const view = orgs.length == 1 ? <SingleOrgView component={c} componentProps={cProps} handleNav={handleNav} organization={orgs[0]} {...rest}/> : <MultipleOrgsView component={c} componentProps={cProps} handleNav={handleNav} organizations={orgs} {...rest}/>; 
  return(
     view
  ) 
}



const SingleOrgView = (props: any) => {
  const {handleNav, organization, component, componentProps, ...rest} = props;
  const ownerOrgId = organization.id;
  componentProps.onClick = () => handleNav(ownerOrgId);
  // component.onClick=() => handleNav(ownerOrgId);
  // component.children = "Organization";

  return(
    <>
      {React.createElement(component, componentProps, `Organization`)}
    </>
  )

}

const MultipleOrgsView = (props: any) => {
    const {organizations, component, componentProps, handleNav, ...rest} = props;
    const orgs: Tenant[] = organizations; 
    const userService = Bootstrapper.getInstance<UserService>(UserService);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [validationMsg, setValidationMsg] = useState<string>();


    const onDialogClose = () => {
      setValidationMsg('');
      setIsOpen(false);
    }

    const handleClick = () => {
      setIsOpen(true);
    }

    const handleOrgClick = (orgId: string) => {
      handleNav(orgId);
      onDialogClose();
    }
    componentProps.onClick=handleClick
    return (
        <>
        {React.createElement(component, componentProps, `Organizations (${orgs.length})`)}
          {isOpen && <Dialog 
                    title="Organizations" 
                    maxWidth="xs" 
                    open={isOpen} 
                    onClose={() => onDialogClose()}>
                            <List disablePadding dense>
                                {orgs.map((o, i) => {
                                    // const to = `/organization/${o.id}`;

                                    return (
                                        <ListItem key={i} disablePadding>
                                            <ListItemButton onClick={() => handleOrgClick(o.id)} className="truncate">{o.name}</ListItemButton>
                                        </ListItem>
                                    )
                                })} 

                            </List>

                            </Dialog>}
        
        </>
    )
}

export default OrganizationsButton;