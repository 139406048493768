import { Toast } from './models/Toast';
import { StoreBase, UseStore } from './StoreBase';

const store = new StoreBase<Toast>(new Toast(false, ));

export const GetState = () => store.state;
export const Update = (toast: Toast) => {    
    store.update(toast);
}
export const Subscription = (fn: (state: Toast) => any) => {
    store.subscription(fn)
}
export function UseToastStore(){
    let result = UseStore(store);
    return result;
}
