import { Subject } from "observable-fns";
import { singleton } from "tsyringe";
import { ContextBase } from "views/ContextBase";

export class DialogSettings{
    constructor(msg: string, onResult: (result: boolean) => any, title: string = null){
        this.msg = msg;
        this.onResult = onResult;
        this.title = title;
    }
    title: string;
    msg: string;
    onResult: (result: boolean) => any        
}

@singleton()
export class AppContext extends ContextBase {
    protected displayDialog = new Subject<DialogSettings>();

    public displayDialogEmitter = this.displayDialog;

    protected displayDialogEmitChange(settings: DialogSettings) {
        this.displayDialog.next(settings);
    }

    public setDialogVisibility(settings: DialogSettings): void {
        this.displayDialogEmitChange(settings);
    }


}