import * as React from 'react';
import {Input as MuiInput, OutlinedInput, InputAdornment} from '@mui/material';
import isDev from 'MySettings';

const Input = React.forwardRef((props: any, ref) => {
  const {isReadOnly, startAdornment, endAdornment, type, ...rest} = props;
  const inputType = type == 'password' && isDev() ? 'text' : type; 
  const rows = !rest.multiline ? 1 : (isReadOnly ?  "" : 5);
  var startAdorn = !startAdornment ? null : <InputAdornment position={"start"}>{startAdornment}</InputAdornment>
  var endAdorn = !endAdornment ? null : <InputAdornment position={"end"}>{endAdornment}</InputAdornment>
  const inputProps = Object.assign({ spellCheck: 'false' }, rest.inputProps ?? {});
  var editableInputHtml = <OutlinedInput type={inputType} rows={rows} notched={false} size="small" ref={ref} startAdornment={startAdorn} endAdornment={endAdorn} inputProps={inputProps} {...rest}></OutlinedInput>
  var readOnlyInputHtml = <MuiInput type={inputType} rows={rows} size="small" ref={ref} startAdornment={startAdorn} endAdornment={endAdorn} inputProps={inputProps} {...rest}></MuiInput>
  var inpt = isReadOnly ? readOnlyInputHtml : editableInputHtml;
  return (
    inpt
  );
});

export default Input;