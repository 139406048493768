
export type OrganizationRole = 'TenantOwner' | 'TenantManager' | 'User'
export class MembershipInfo {
    constructor(){
    }
    tenantId: string;
    userId: string;
    role: OrganizationRole;
    deactivatedMembership: boolean;

    public static getRoleLabel = (info: MembershipInfo): string => {
        if(!info)
            return "Unknown";
        switch(info.role){
            case "TenantOwner":
                return "Owner";
            case "TenantManager":
                return "Manager";
            case "User":
                return "Member";
        }
    }

}