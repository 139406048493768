import { Feature } from './Feature';
import { Price } from "./Price";



export enum PlanType{
    Free,
    Pilot,
    Small,
    Medium,
    Large,
    License,
}


export enum SubscriptionStatus{
    None, // not a customer
    Expired, // for pilot
    Canceled,
    Incomplete,
    Unpaid,
    Active
}

export class Plan {

    constructor(id: string, name: string, description: string, features: Feature[] = []){
        this.id = id;
        this.name = name;
        this.description = description;
        this.features = features;
    }
    public id: string;
    public name: string;
    public description: string;
    public features: Feature[]
    public prices: Price[];
    public maxUsers: number;
    public status: SubscriptionStatus;
    public type: PlanType;

    public get isFree(): boolean {
        return this.type === PlanType.Free;
    }

    public static getFullNameLabel = (plan: Plan): string => {
        if(!plan)
            return "Pilot Available";
        const status = Plan.getStatusLabel(plan);
        return `${plan.name} (${status})`;
    }

    public static getStatusLabel = (plan: Plan): string => {
        switch(plan?.status){
            case SubscriptionStatus.Active:
                return "Active";
            case SubscriptionStatus.Canceled:
                return "Canceled";
            case SubscriptionStatus.Incomplete:
                return "Incomplete";
            case SubscriptionStatus.None:
                return "Not Subscribed";
            case SubscriptionStatus.Unpaid:
                return "Unpaid";
            case SubscriptionStatus.Expired:
                return "Expired";
    
            default:
                return "Unknown";
        }
    }

    public static empty = (): Plan => { return new Plan("0", "test1", "empty") }
}