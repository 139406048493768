import { AuthTokenInfo } from "../models/AuthTokenInfo";
const jwt = require('jsonwebtoken');


export class TokenUtil {
    private static accessKey = "accessKey";
    private static refreshKey = "refreshKey";

    
    get isAutheticated(): boolean {
        var token = this.accessToken;
        return (token != null && token != "");
    }

    get accessToken(): string {
        var token = window.localStorage.getItem(TokenUtil.accessKey) ?? "";
        return token;
    }

    get refreshToken(): string {
        var token = window.localStorage.getItem(TokenUtil.refreshKey) ?? "";
        return token;
    }

    public getActiveUserId(): string {
        var token = this.accessToken;
        if(token == null || token == '')
          return null;
  
        var decoded = jwt.decode(token);
        
        var userId = decoded.sub;
  
        return userId;
      }

    getAuthInfo(): AuthTokenInfo{
        var info = new AuthTokenInfo();
        info.accessToken = this.accessToken;
        info.refreshToken = this.refreshToken;
        return info;
    }


    setAuthInfo(info: AuthTokenInfo){
        if(info)
        {
            window.localStorage.setItem(TokenUtil.accessKey, info.accessToken);
            window.localStorage.setItem(TokenUtil.refreshKey, info.refreshToken);
        }
        else
        {
            window.localStorage.removeItem(TokenUtil.accessKey);
            window.localStorage.removeItem(TokenUtil.refreshKey);
        }
    }


    getAuthHeader(): string{
        var token = this.accessToken;
        // console.log("auth header", token);
        var authHeader = token ? `Bearer ${token}` : "";
        return authHeader;
    }
    
}