
export class AppError extends Error{
    constructor(msg: string = "Unknown error"){
        super(msg)
        Object.setPrototypeOf(this, AppError.prototype);
    }
}

export class UnauthorizedError extends AppError {
    constructor(redirectUrl: string = null, msg: string = "User is unauthorized", displayMessage: boolean = false){
        super(msg)
        Object.setPrototypeOf(this, UnauthorizedError.prototype);
        this.redirectUrl = redirectUrl;
        this.displayMessage = displayMessage;
    } 
    public redirectUrl: string;
    public displayMessage: boolean; 
}


export class ForbiddenError extends AppError {
    constructor(msg: string = "User is forbidden"){
        super(msg)
        Object.setPrototypeOf(this, ForbiddenError.prototype);
    }   
}

export class MissingEmailConfirmationError extends AppError {
    constructor(msg: string = "Email confirmation is required"){
        super(msg)
        Object.setPrototypeOf(this, MissingEmailConfirmationError.prototype);
    }   
}

export class BadRequestError extends AppError {
    constructor(msg: string = "Bad request"){
        super(msg)
        Object.setPrototypeOf(this, BadRequestError.prototype);
    }   
}

export class NotFoundError extends AppError {
    constructor(msg: string = "Not found"){
        super(msg)
        Object.setPrototypeOf(this, NotFoundError.prototype);
    }   
}