
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"
import { Tenant } from "models/Tenant"
import { BadRequestError } from "errors/ApplicationErrors"
import { Bootstrapper } from "Bootstrapper"
import LoadingButton from "components/buttons/LoadingButton"
import { DialogUtil } from "utils/DialogUtil"
import { Icon2, Section2, Stack2, Subtitle2, Text2 } from "views/articles/Article"
import SimpleGrid, { SimpleContent } from "../simpleGrid/SimpleGrid"




const ProjectPane = (props: any) => {
    // const {name, icon, paneMedia, ribbonMedia, ...rest} = props;
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);
    const media = prodInfoService.getMedia();
    const name = "Project";
    const paneMedia = media.get('project');
    
    // const i = typeof(icon) === 'string' ? <Icon2 dark>{icon}</Icon2> : icon;
    return(
        <Box>
            <Stack2>
                {/* <Subtitle2>Ribbon</Subtitle2> */}

                {/* <Paper elevation={1}>
                    <img src={ribbonMedia.url} />
                </Paper> */}
                <Text2>Create a {name} to begin using Zibbit for your document. This will enable the Zibbit functionality from the ribbon.</Text2>
                <Box>
                    <SimpleGrid>
                        <SimpleContent>
                            <Box>
                                <Stack2>
                                    <Stack2>
                                        <Subtitle2>Exhibit Folder</Subtitle2>
                                        <Text2>This input is required. You must specify a folder on your local device that contains your exhibits. You can always modify your collection of exhibits at any time.</Text2>
                                    </Stack2>

                                    <Stack2>
                                        <Subtitle2>Template</Subtitle2>
                                        <Text2>This input is optional. Select a template to have your {name} settings pre-configured.</Text2>
                                    </Stack2>

                                    <Stack2>
                                        <Subtitle2>Create Project</Subtitle2>
                                        <Text2>When you're ready, click "Create Project".</Text2>
                                    </Stack2>
                                </Stack2>

                            </Box>
                        </SimpleContent>

                        <SimpleContent>
                            <Paper>
                                <img src={paneMedia.url} />
                            </Paper>

                        </SimpleContent>
                    </SimpleGrid>
                </Box>
            </Stack2>
        </Box>
    )


}


export default ProjectPane