
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"
import { Tenant } from "models/Tenant"
import { BadRequestError } from "errors/ApplicationErrors"
import { Bootstrapper } from "Bootstrapper"
import LoadingButton from "components/buttons/LoadingButton"
import { DialogUtil } from "utils/DialogUtil"

export const Icon2 = (props: any) => {
    const {sx, color, dark, ...rest} = props;
    const c = color ?? dark ? 'black' : 'gray';
    var s = {
        color: c,  
        margin: 0,
        // border: '1px solid',
        // borderRadius: '50%',
        ...sx,
    }
    return <Icon style={s} {...rest} />
}

export const Exhibit = (props: any) => {
    const {num, ...rest} = props;
    const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p'];
    const sequence = alphabet[num - 1].toUpperCase();
    return <Emphasis {...rest}>Exhibit {sequence}</Emphasis>
}

export const Emphasis = (props: any) => {
    return <strong {...props}></strong>
}

export const Text2 = (props: any) => {
    return <Typography mb={0} variant="body1" component="p" {...props} />
}

export const Subtitle2 = (props: any) => {
    return <SubHeading bold {...props} sx={{mb: -1}} />
}

export const Stack2 = (props: any) => {
    return <Stack direction="column" spacing={2} {...props}></Stack>
}

export const Section2 = (props: any) => {
    return <section><Box mb={8}><Stack2 spacing={0} {...props}></Stack2></Box></section>
}

const Article = (props: any) => {
    const {children, title, subtitle, spacing, ...rest} = props;
    const navigate = useNavigate();
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);

    const userService = new UserService();
    const tenantService = new TenantService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dialogUtils = new DialogUtil();

    const [orgPermissionMap, setOrgPermissionMap] = useState<Map<Tenant, boolean>>(new Map<Tenant, boolean>());
    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [accessKey, setAccessKey] = useState<string>("");
    const [organizations, setOrganizations] = useState<Tenant[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const userId = userService.getActiveUserId()
    const location = useLocation();
    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    useEffect(() => {
    }, []);




    return(
        <Box mt={5} maxWidth='700px' flexGrow={1}>
            <TitleBlock fullWidth sx={{mb: 8, justifyContent: 'left'}}>
                <Title textAlign="left">{title}</Title>
                {subtitle && <SubHeading textAlign="left">{subtitle}</SubHeading>}
            </TitleBlock>

            {/* <Box mt={8} mb={8} justifyContent='center' display='flex'>
                <Box width='100%' maxWidth='500px'>
                    <Divider variant='middle'/>

                </Box>

            </Box> */}

            <Stack direction="column" spacing={spacing}>
                {children}

            </Stack>
            {/* <Section disablePadding bgColor={bg1}>
                {children}
            </Section> */}
        </Box>

    )


}


export default Article