import {CircularProgress as MuiCircularProgress} from '@mui/material';
import Text from 'components/labels/Text';
import React, { CSSProperties } from 'react';

const style = {
  display: 'flex', 
  flexGrow: 1, 
  alignItems: 'center', 
  justifyContent: 'center',
  flexDirection:'column',
  // height: '100%',
} as CSSProperties

const CircularProgress = (props: any) => {
  const {label, value, ...rest} = props;
  const labelStyle = {
    display: label == null ? 'none' : 'block',
    margin: '5px'
  }
  const valueStyle = {
    display: value == null ? 'none' : 'block'
  }
  return (
    <div style={style}>
      <Text style={labelStyle}>{label}</Text>
      <MuiCircularProgress size={70} {...rest}><Text style={valueStyle}>{`${Math.round(value)}%`}</Text></MuiCircularProgress>
    </div>
  );
}
export default CircularProgress;