import "reflect-metadata";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Bootstrapper } from 'Bootstrapper';
import { UseAppInitStore, Update as InitUpdate } from 'stores/AppInitStore';
import { BrowserRouter } from "react-router-dom";



init();
async function init(){
  InitUpdate(false);
  render();
  await initalizeAppAsync();
  InitUpdate(true);
}


async function initalizeAppAsync(){
  //await new Promise(f => setTimeout(f, 500000));
  await Bootstrapper.initializeAsync();

}


function render(){
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    // <React.StrictMode> // this causes useEffect to run twice in dev
    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </React.StrictMode>
  );
  
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
