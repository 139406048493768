
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { LogoType, ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Computer, { IComputerProps } from "../../components/objects/computer/Computer"
import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import {Card as ModelCard } from "./models/Card"
import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Dialog from "components/modals/Dialog"
import { Bootstrapper } from "Bootstrapper"
import CircularProgress from "components/progress/CircularProgress"
import { CommonUtil } from "utils/CommonUtil"
import { Link as NavLink } from 'react-router-dom';
import Form from "components/forms/Form"
import CardFeature2 from "./CardFeature2"
import Link from "components/buttons/Link"
import RequestDemoButton from "components/buttons/RequestDemoButton"
import Video from "components/wrappers/Video"




const Home = (props: any) => {
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);

    const images = prodInfoService.getMedia();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];


    const SectionIntro = (props: any) => {
        const videoRef = React.useRef(null);
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
        const isTablet = useMediaQuery(theme.breakpoints.down('md'));
        const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
        const [isVidDialogOpen, setIsVidDialogOpen] = useState<boolean>(false);
        const [vidLoaded, setVidLoaded] = useState<boolean>(true); // youtube loads
        const [hasVidStarted, setHasVidStarted] = useState<boolean>();
        // const [isFullScreen, setIsFullscreen] = useFullscreenStatus(videoRef, (val) => {
        //     if(isAutoFullscreen() && !val)
        //         onDialogClose();
        // });

        // const isAutoFullscreen = () => {
        //     return fullscreenEnabled && isMobile;
        // }

        const getBackgroundUrl = (src: string, isDim: boolean = false): string => {
            var dim = isDim ? `linear-gradient(to left, rgba(245, 246, 252, 0.1), rgba(245, 246, 252, 0.7)),` : '';
            var img = `${dim}url("${src}")`;
    
            return img;
        }

        const getCompStyle = () => {
            var img = "";
            var url = getBackgroundUrl(prodInfoService.GetProductImage(), true);
    
            let style: CSSProperties = {
                backgroundImage: url
            };
            return style;
        }

        const playVideo = () => {
            if(!hasVidStarted)
                setHasVidStarted(true);
            setIsVidDialogOpen(true);
        }
    
        const compProps: IComputerProps = {
            callback: playVideo,
            imgStyle: getCompStyle()
        };

 

        const handleAnimationChain = () => {
            setIsAnimationChaining(true);
        }

        const onVidLoaded = () => {
            setVidLoaded(true)
            
            // if(isAutoFullscreen())
            //     setIsFullscreen(true)
        }

        const onDialogClose = () => {
            setIsVidDialogOpen(false);
            // setVidLoaded(false);
        }

        const getIntroVideo = () => {
            return(
                <Box className="video-container">
                <iframe
                className="video" 
                // onLoad={() => onVidLoaded()} 
                // width="100%" 
                // height="auto" 
                src={prodInfoService.demoLink} 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                 allowFullScreen></iframe>
                </Box>
            //     <video webkit-playsinline playsInline ref={videoRef} onLoadedData={() => onVidLoaded()} style={{display: vidLoaded ? 'block' : 'none', maxWidth: '100%'}} autoPlay className="marketVid" preload="metadata" controls>
            //     <source src={images?.get('vid-marketing')?.url} type="video/mp4" />
            //     <object data={images?.get('vid-marketing')?.url}>
            //     </object>
            // </video>
            )
        }
        const logoUrl = prodInfoService.getLogoUrl(LogoType.WithTextTransparent);
        const homeIcon = <Box mb={2} display='flex' alignItems='center' width={'300px'}><img alt="Zibbit logo" src={logoUrl}/></Box>

        return(
            <>
            <Section bgColor={bg1} id='section-intro'>
                <Box className="content-wrapper">
                    <Container maxWidth='lg'>
                        <Grid container justifyContent='center' alignItems="stretch" spacing={6}>
                            <Grid item xs={12} md={5} order={0}>
                                <Item>
                                    <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} duration='medium' type='slide' direction='up'>
                                        <div>

                                        <TitleBlock sx={{justifyContent: 'left'}} mb={0}>
                                            {homeIcon}
                                            {/* <Title gutterBottom={true}>Zibbit</Title> */}
                                            {/* <Text textAlign='left'>The simple way to assemble court documents for e-filing</Text> */}
                                            <Box mb={2} display='inline-block'>
                                            <Stack spacing={2}>
                                                <Subtitle maxWidth={isMobile ? '300px' : '100%'}>an exhibit manager for Microsoft Word</Subtitle>
                                                <Divider light  orientation='horizontal'/>
                                                
                                            </Stack>

                                            </Box>
                                            <Text fontStyle='italic' mb={2}>The simple way to assemble court documents for e-filing. Save time, eliminate errors, and make revisions effortless.</Text>

                                            <Stack direction={false ? 'column' : 'row'} spacing={1}>
                                                <Button component={NavLink} to="/register">14-day Free Trial</Button>

                                                <RequestDemoButton />
                                            </Stack>

                                        </TitleBlock>

                                        {/* <Animation transitionDelay={1.5} overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} duration='medium' type='fade' direction='up'>
                                        <div>
                                        <Box >
                                            <Box mt={6} mb={6}>

                                            </Box>

                                        <Box maxWidth="150px">
                                            <Paper elevation={0}>
                                                <Box p={2} mt={2} display='flex' flexDirection='column' alignItems='start' justifyContent='center'>
                                                                    <Text gutterBottom={false}>Partner</Text>
                                                                    <Box ml="-22px" maxWidth="140px">
                                                                        <a target="_blank" href="https://www.torontomu.ca/zone-learning/legal-innovation-zone/">
                                                                        <img src={images.get("partner-liz").url} />

                                                                        </a>
                                                                    </Box>
                                                                </Box>

                                            </Paper>
                                        </Box>
                                        </Box>

                                        </div>
                                        </Animation> */}
                                        </div>
                                    </Animation>
                                </Item>
                            </Grid>
                            {!isTablet && <Grid item xs={12} md={7}>
                                <Item>
                                    <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} duration='medium' transitionDelay={0} type='slide' direction='left'>
                                        <div>
                                        <Computer {...compProps} />
                                        </div>
                                    </Animation>
                                </Item>
                            </Grid>}

                        </Grid>

                        {isVidDialogOpen && <Dialog fullWidth={vidLoaded} maxWidth={vidLoaded ? "md" : "xs"} open={isVidDialogOpen} onClose={() => onDialogClose()}>
                                {getIntroVideo()}
                                {!vidLoaded && <CircularProgress />}
                        </Dialog>}

                    </Container>
                </Box>


            
            </Section>

            {isTablet && <Section bgColor={theme.palette.primary.main}>
                    <Container>

                        <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} duration='medium' transitionDelay={0} type='slide' direction='left'>
                            <div>
                                <Computer {...compProps} />
                            </div>
                        </Animation>
                    </Container>


            </Section>}


            </>
        )




    }

    const SectionSummary = (props: any) => {
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
        const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
        const binderItems: ModelCard[] = [
            new ModelCard(
                'Digital Binders', 
                'Instantly bundle your materials as a PDF',
                'check',
                ),
            new ModelCard(
                'Drafting Assistance', 
                'Save time, eliminate errors, and make revisions effortless',
                'check'
                ),
            new ModelCard(
                'Templates', 
                'Create templates to help prepare any court document',
                'check'
                ),
        ]
        

        const handleAnimationChain = () => {
            setIsAnimationChaining(true);
        }
        
        const imgStyle = {
            background: `url(${images.get('publish-window').url})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '100% 100%',
            width: '100%',
            height: '100%',
        }

        return(
            <Section id='section-summary' bgColor={bg2}>




                <Container maxWidth="md">
                    <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                        <TitleBlock fullWidth={false} sx={{justifyContent: 'center'}}>
                            <Heading gutterBottom={true} textAlign={isMobile ? "left" : "center"}>Working with Exhibits?</Heading>

                            {/* <Heading variant='h5' textAlign="center"><strong>Working with Exhibits?</strong></Heading> */}
                            <Box textAlign='center'>
                            <SubHeading textAlign={isMobile ? 'left' : 'center'} gutterBottom={false}>Zibbit is the simple way to assemble court documents for e-filing</SubHeading>
                            
                            </Box>
                        </TitleBlock>
                    </div>
                    </Animation>
                    <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='zoom' direction='left' transitionDelay={0}>
                                 <div>
                                     <Box justifyContent={isMobile ? 'start' : 'center'} display='flex'>
                                        <Box>
                                            <Stack direction='column' spacing={1}>
                                                <MyListItem icon={<Icon>check</Icon>} alignItems='center'><SubHeading fontWeight={500} gutterBottom={false}>Affidavits</SubHeading></MyListItem>
                                                <MyListItem icon={<Icon>check</Icon>} alignItems='center'><SubHeading fontWeight={500} gutterBottom={false}>Motion Records</SubHeading></MyListItem>
                                                <MyListItem icon={<Icon>check</Icon>} alignItems='center'><SubHeading fontWeight={500} gutterBottom={false}>Compendiums</SubHeading></MyListItem>
                                                <MyListItem icon={<Icon>check</Icon>} alignItems='center'><SubHeading fontWeight={500} gutterBottom={false}>Mediation Briefs</SubHeading></MyListItem>
                                            </Stack>                                                
                                        </Box>                                     
                                     </Box>
                                     {/* <Box mt={8}>

                                <Grid container justifyContent='center' alignItems="start" spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <Item>
                                            <div>
                                                <Grid container justifyContent='center' alignItems="stretch" spacing={2}>
                                                    {
                                                        binderItems.map((f, i) => {
                                                            return <Grid key={i} item xs={12} md={4}>
                                                                <Item sx={{height: '100%'}}>
                                                                        <div style={{height: '100%'}}>
                                                                            <CardFeature elevation={1} sx={{bgcolor: ColorsUtil(theme, 'White'), height: "100%"}} card={f}/>
                                                                        </div>                                    
                                                                </Item>
                                                            </Grid>
                                                        })
                                                    }
                                                </Grid>
                                            </div>
                                        </Item>
                                    </Grid>

                                </Grid>
                                </Box> */}





                                    {/* <Grid container justifyContent='center' alignItems="stretch" spacing={2}>
                                    {
                                        binderItems.map((f, i) => {
                                            return <Grid key={i} item xs={12} md={4}>
                                                <Item sx={{height: '100%'}}>
                                                        <div style={{height: '100%'}}>
                                                            <CardFeature elevation={1} sx={{bgcolor: ColorsUtil(theme, 'White'), height: "100%"}} card={f}/>
                                                        </div>                                    
                                                </Item>
                                            </Grid>
                                        })
                                    }
                                    </Grid> */}

                    </div>
                    </Animation>

                </Container>
            </Section>

        )




    }

    const MyListItem = (props: any) => {
        const {children, icon, ...rest} = props;
        const alignItems = rest.alignItems ?? 'start'
        const title = rest.title ? <Text>{rest.title}</Text> : children;
        const iconFinal = icon ? icon : <Icon verySmall>circle</Icon>;
        return(
            <Box display='flex' alignItems={alignItems}>
                <Box mt={alignItems == 'start' ? "2px": 0}>{iconFinal}</Box>
                <Box ml={1}>{title}</Box>
            </Box>
    
        )

    }


    const SectionBinder = (props: any) => {
        const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
        const isTablet = useMediaQuery(theme.breakpoints.down('md'));
        const binderItems: ModelCard[] = [
            new ModelCard(
                'Exhibits', 
                'Convert all exhibits to PDFs and correctly order them',
                ),
            new ModelCard(
                'Hyperlinks', 
                'Include hyperlinks when referring to exhibits',
                ),
            new ModelCard(
                'Bookmarks', 
                'Include bookmarks for each exhibit',
                ),
            new ModelCard(
                'Tabs', 
                'Include stamp / notary pages before each exhibit',
                ),
            new ModelCard(
                'Table of Contents', 
                'Include a TOC with page numbers and hyperlinks to each exhibit',
                ),
            new ModelCard(
                'Volumes', 
                'Split large PDFs into volumes to meet file size restrictions',
                ),
        ]
        

        const handleAnimationChain = () => {
            setIsAnimationChaining(true);
        }
        
        const imgStyle = {
            background: `url(${images.get('icon-binder').url})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '100% 0%',
            width: '100%',
            height: '100%',
        }

        return(
            <Section id='section-binder' bgColor={bg1}>
                <Container maxWidth="md">
                <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='slide' direction='right' transitionDelay={0} duration='medium'>
                    <div>
                        <Stack 
                            // divider={<Divider variant='middle' sx={{borderWidth: '4px'}} orientation='vertical' flexItem />}
                            spacing={8}
                            direction='row'>
                            {!isTablet && <Box maxWidth='200px'>
                                <img src={images.get('icon-binder').url} />
                            </Box>}

                            <TitleBlock mb={0} fullWidth>
                                        <Heading>Digital Binders</Heading>
                                        <SubHeading>Assemble your materials as a PDF binder</SubHeading>
                                        <Box display='flex' mt={3}>

                                        <Stack spacing={1}>
                                            <MyListItem title="Generate a PDF with a single click" />
                                            <MyListItem title="Meet e-filing requirements" />
                                            <MyListItem title="Fully customizable" />
                                        </Stack>


                                        </Box>
                                        
                                        </TitleBlock>


                        </Stack>
                    </div>
                    </Animation>
                    <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='zoom' direction='left' transitionDelay={0}>
                                 <div>
                    <Box mt={8}>

                    <Grid container justifyContent='center' alignItems="start" spacing={2}>
                        {/* <Grid className='binderWrapper' item xs={12} sm={6} md={4}>
                            <Item>
                                <Box>
                                    <img style={{marginLeft: '-30px'}} src={images.get('binder').url} />
                                </Box>                                        
                            </Item>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12}>
                            <Item>
                                    <Grid container alignItems="stretch" spacing={2}>
                                    {
                                        binderItems.map((f, i) => {
                                            return <Grid key={i} item xs={12} sm={6} md={4}>
                                                <Item sx={{height: '100%'}}>
                                                        <div style={{height: '100%'}}>
                                                            <CardFeature sx={{bgcolor: ColorsUtil(theme, 'White'), height: "100%"}} card={f}/>
                                                        </div>                                    
                                                </Item>
                                            </Grid>
                                        })
                                    }
                                    </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                    </Box>

                    </div>
                    </Animation>

                </Container>
            </Section>

        )




    }

    const SectionDrafting = (props: any) => {
        const isTablet = useMediaQuery(theme.breakpoints.down('md'));
        const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);

        const draftingItems: ModelCard[] = [
            new ModelCard(
                'Easy Revisions', 
                "The sequence of exhibits is automatically maintained, making last minute revisions effortless",
                ),
            new ModelCard(
                'Custom Settings', 
                'Customize the appearance of reference labels when referring to exhibits',
                ),
            new ModelCard(
                'Templates', 
                'Create templates to streamline all your court documents',
                ),                    
        ]
        

        const handleAnimationChain = () => {
            setIsAnimationChaining(true);
        }

        return(
            <Section id='section-draft' bgColor={bg2}>
                <Container maxWidth='md'>
                <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='slide' direction='left' transitionDelay={0} duration='medium'>
                    <div>

                    <Stack 
                            // divider={<Divider variant='middle' sx={{borderWidth: '4px'}} orientation='vertical' flexItem />}
                            spacing={8}
                            direction='row'>
                            {!isTablet && <Box maxWidth='200px'>
                                <img src={images.get('icon-word').url} />
                            </Box>}

                                    <TitleBlock mb={0} fullWidth>
                                        <Heading>Drafting Assistance</Heading>
                                        <SubHeading>We take care of the tedious work for you</SubHeading>
                                            <Box display='flex' mt={3}>

                                                <Stack spacing={1}>
                                                    <MyListItem title="Save time while drafting" />
                                                    <MyListItem title="Eliminate errors with automation" />
                                                    <MyListItem title="Make revisions effortlessly" />
                                                </Stack>


                                            </Box>
                                        
                                        </TitleBlock>


                        </Stack>
                    </div>
                </Animation>
                <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='zoom' direction='left' transitionDelay={0}>
                        <div>




                        <Box mt={8} maxWidth='100%'>
                            <Video src={images.get('vid-demo1').url} poster={images.get('feature-img').url} />
                        </Box>                                      


                    </div>
                </Animation>
                </Container>
            </Section>

        )




    }

    const SectionTemplates = (props: any) => {
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
        const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
        const binderItems: ModelCard[] = [
            new ModelCard(
                'Binder Settings', 
                'Customize all aspects of your digital binder',
                ),
            new ModelCard(
                'Drafting Settings', 
                'Optimize drafting based on your preferences',
                ),

        ]
        

        const handleAnimationChain = () => {
            setIsAnimationChaining(true);
        }
        
        const imgStyle = {
            background: `url(${images.get('publish-window').url})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '100% 100%',
            width: '100%',
            height: '100%',
        }

        return(
            <Section id='section-templates' bgColor={bg1}>
                <Container maxWidth="md">

                    <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='slide' direction='right' duration='medium' transitionDelay={0}>
                                 <div>

                    <Grid container justifyContent='center' alignItems="start" spacing={isMobile ? 0 : 2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Item>
                                <Box>
                                    <TitleBlock mb={isMobile ? 8 : 0}>
                                        <Heading>Templates</Heading>
                                        <SubHeading>Streamline all your legal matters</SubHeading>
                                        <Box display='flex' mt={3}>

                                            <Stack  spacing={1}>
                                                <MyListItem title="Use our default templates" />
                                                <MyListItem title="Create custom templates" />
                                            </Stack>

                                        </Box>
                                    </TitleBlock>
                                </Box>                                        
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Item>
                                    <Grid container alignItems="stretch" spacing={2}>
                                    {
                                        binderItems.map((f, i) => {
                                            return <Grid key={i} item xs={12} sm={12}>
                                                <Item sx={{height: '100%'}}>
                                                        <div style={{height: '100%'}}>
                                                            <CardFeature sx={{bgcolor: ColorsUtil(theme, 'White'), height: "100%"}} card={f}/>
                                                        </div>                                    
                                                </Item>
                                            </Grid>
                                        })
                                    }
                                    </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                    </div>
                    </Animation>

                </Container>
            </Section>

        )




    }



    const SectionProcess = (props: any) => {
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

        const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
        

        
        const handleAnimationChain = () => {
            setIsAnimationChaining(true);
        }

        const getStepInfo = (step: number) => {
            switch(step){
                case 1:
                    return getInfoStep1();
                case 2:
                    return getInfoStep2();
                case 3:
                    return getInfoStep3();
            }
        }

        const getInfoStep1 = () => {
            return(
                <Box padding={2}>
                    <span style={{color: ColorsUtil(theme, 'ThemePrimary')}}>Step 1</span>
                    <SubHeading>Import Exhibits</SubHeading>
                    <Text mt={1}>Import a folder of exhibits into your document</Text>
    
                </Box>
    
    
            )
        }

        const getInfoStep2 = () => {
            return(
                <Box padding={2}>
                    <span style={{color: ColorsUtil(theme, 'ThemePrimary')}}>Step 2</span>
                    <SubHeading>Reference Exhibits</SubHeading>
                    <Text mt={1}>Insert references thoughout your document</Text>
                </Box>
    
    
    
            )
        }

        const getInfoStep3 = () => {
            return(
                <Box padding={2}>
                    <span style={{color: ColorsUtil(theme, 'ThemePrimary')}}>Step 3</span>
                    <SubHeading>Publish PDF</SubHeading>
                    <Text mt={1}>Generate a digital binder</Text>
                </Box>    
            )
        }






        const steps = [1,2,3];

        return(
            <Section bgColor={bg1} id="section-process">
                <Container maxWidth="md">
                    <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                        <div>
                            <TitleBlock sx={{justifyContent: 'center'}}>
                                <Heading textAlign={isMobile ? 'left' : 'center'}>The Process</Heading>
                                <SubHeading>It takes 3 steps to prepare your digital binder</SubHeading>
                            </TitleBlock>
                        </div>
                    </Animation>

                                   <div>
                                        <Grid 
                                            container
                                            alignItems="stretch"
                                            justifyContent="center"
                                            spacing={2}>
                                                {steps.map(v => {
                                                    return(

                                                        <Grid key={v} item xs={12} sm={6} md={4}>
                                                        <Item sx={{height: '100%'}}>
                                                        <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} transitionDelay={0.5 * (v - 1)} duration='medium' type='zoom' direction='left'>
                                                            <div style={{height: '100%'}}>

                                                            <Paper sx={{display:'flex', height: '100%', flexDirection: "column"}}>  
                                                                {getStepInfo(v)}
                                                            </Paper>
                                                            </div>
                                                        </Animation>
                                                        </Item>
                                                    </Grid>
                                                    )

                                                })}


                                        </Grid>
                                    </div>

                </Container>
            </Section>    
        )
    
    }

    const SectionPrivacy = (props: any) => {
        const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
        const handleAnimationChain = () => {
            setIsAnimationChaining(true);
        }

        return(
            <Section bgColor={bg2}>
                <Container maxWidth="md">
                    <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                        <div>
                            <TitleBlock sx={{justifyContent: 'center'}}>
                                <Box mb={3} justifyContent='center' alignItems="center" display="flex">
                                    <Icon bgCircle>verified_user</Icon>
                                    <Heading ml={1} gutterBottom={false} textAlign='center'>Privacy</Heading>
                                </Box>
                                <SubHeading>Your data stays on your device</SubHeading>
                            </TitleBlock>
                        </div>
                    </Animation>

                    <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='zoom' direction='left' transitionDelay={0}>
                        <div>

                            <Grid
                                container
                                alignItems="stretch"
                                spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Item sx={{height: '100%'}}>
                                        <Paper sx={{height: '100%'}}>
                                            <Box padding={2}>
                                                <SubHeading>Exhibits</SubHeading>
                                                <Text>Exhibits are never transmitted, processed, or stored on any remote server</Text>
                                            </Box>
                                        </Paper>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Item sx={{height: '100%'}}>
                                    <Paper sx={{height: '100%'}}>
                                        <Box padding={2}>
                                            <SubHeading>Network Communication</SubHeading>
                                            <Text>Network communication is encrypted with TLS/SSL protocols</Text>
                                        </Box>
                                        </Paper>
                                    </Item>
                                </Grid>

                            </Grid>
                        </div>
                    </Animation>
                </Container>
            </Section>
        )

    }

    const SectionDemo = (props: any) => {
        const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
        const handleAnimationChain = () => {
            setIsAnimationChaining(true);
        }

        return(
            <Section bgColor={bg1}>
                <Container maxWidth="xs">
                    <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                        <div>
                            <Form 
                                action={<Button component={NavLink} to='/demo' fullWidth>Video Demo</Button>}
                                title="See it in action!">
                            </Form>
                        </div>
                    </Animation>
                </Container>
            </Section>
        )

    }

    const SectionCTA = (props: any) => {
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
        const isTablet = useMediaQuery(theme.breakpoints.down('md'));
        const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
        const handleAnimationChain = () => {
            setIsAnimationChaining(true);
        }

        return(
            <Section disablePadding sx={{color: 'white'}} bgColor={theme.palette.primary.main}>
                <Box pt="50px" pb="50px">

                <Container maxWidth="sm">
                    {/* <TitleBlock sx={{justifyContent: 'center'}}>
                                <Box mb={0} justifyContent='center' alignItems="center" display="flex">
                                    <Icon bgCircle>verified_user</Icon>
                                    <Heading ml={1} gutterBottom={false} textAlign='center'>Privacy</Heading>
                                </Box>
                            </TitleBlock> */}
                    <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                        <div>

                        <Grid container justifyContent='center' alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Item>
                                <Box>
                                    <Heading textAlign={isMobile ? 'center' : 'left'} gutterBottom={false}>Get Started</Heading>
                                </Box>                                        
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Item>
                                <Box>
                                    <Stack 
                                        divider={!isTablet && <Divider sx={{background: 'white'}} orientation='vertical' flexItem />} 
                                        direction={isTablet ? 'column' : 'row'} spacing={2}>
                                        <Button fullWidth={true} color='inherit' variant="outlined" component={NavLink} to='/register'>Begin free trial</Button>
                                        {/* <Link color="inherit" component={NavLink} to='/demo'>Or checkout the demo</Link>               */}

                                        {/* <Button fullWidth color='inherit' variant="outlined" component={NavLink} to='/demo'>Video Demo</Button> */}
                                    </Stack>

                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                    <Box mt={1}>

                    </Box>


                            {/* <Form 
                                action={<Button component={NavLink} to='/register' fullWidth>Get Started</Button>}
                                title="Get started for free!">
                            </Form> */}
                        </div>
                    </Animation>
                </Container>
                </Box>

            </Section>
        )

    }








    return (
        <Page>
            <SectionIntro {...props} />
            <SectionSummary {...props} />
            <SectionBinder {...props} />
            <SectionDrafting {...props} />
            {/* <SectionTemplates {...props} /> */}
            <SectionCTA {...props} />
            {/* <SectionFeatures {...props} /> */}
            <SectionProcess {...props} />
            <SectionPrivacy {...props} />
            {/* <SectionDemo {...props} /> */}
        </Page>

    )
}


export default Home