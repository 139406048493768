import * as React from 'react';
import {Box as MuiBox} from '@mui/material';
import "./box.scss";

const Box = (props: any) => {
    const {center, className, ...rest} = props;
    var centerClass = center ? "center" : "";
    var classes: string[] = [className, centerClass]; 
    var finalClassName = classes.filter(c => c != null).join(" ");

    return <MuiBox className={finalClassName} {...rest} />
}

export default Box;