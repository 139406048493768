
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Computer, { IComputerProps } from "../../components/objects/computer/Computer"
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import CardPlan from "./CardPlan"
import './planOptions.scss';
import { Price } from "models/Price"
import { Bootstrapper } from "Bootstrapper"
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';
import { UnauthorizedError } from "errors/ApplicationErrors"
import { useNavigate, useSearchParams } from "react-router-dom"
import { DialogUtil } from "utils/DialogUtil"
import { features } from "process"
import { Feature } from "models/Feature"





const PlanOptions = (props: any) => {
    const {products, title, subtitle, ...rest} = props;
    const plans: Plan[] = products;
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);
    const plansClient = new PlansClient();
    const images = prodInfoService.getMedia();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isBusy = UseBusyIndicatorStore();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [searchParams] = useSearchParams();
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    // const [plans, setPlans] = useState<Plan[]>([]);
    const [priceMap, setPriceMap] = useState<Map<string,Price[]>>();
    const [loading, setLoading] = useState<boolean>(true);
    const dialogUtils = new DialogUtil();
    const navigate = useNavigate();

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    useEffect(() => {
        // const keyParam = searchParams.get("type");
        // const isFirmPlan = keyParam == "firm";
        // setShowFirmPlans(isFirmPlan);
        setPlansAsync();
    }, []);

    const setPlansAsync = async() => {
        setLoading(true);
        BusyUpdate(true);

        const promises = plans.map(p => {
            return getPriceDataAsync(p.id);
        });

        const planPriceData = await Promise.all(promises);
        const priceMap = new Map<string, Price[]>();
        planPriceData.forEach(data => {
            priceMap.set(data.planId, data.prices);

        })

        setPriceMap(priceMap);
        BusyUpdate(false);
        setLoading(false);    
    }

    const getPriceDataAsync = async(planId: string) => {
        const prices = await plansClient.getPrices(planId);
        return {planId: planId, prices: prices};
    }

    const goToCheckoutUrlAsync = async(priceId: string) => {
        if(!canSubmit)
            return;
        setCanSubmit(false);
        BusyUpdate(true);
        var url = await prodInfoService.getSessions(priceId).catch((e: Error) => {
            if (e instanceof UnauthorizedError) {
                dialogUtils.confirmDialog("This action requires an account. Register now?", async(confirmed) => {
                    if(confirmed){
                        navigate('/register');
                    }
                });
            }
            else{
                BusyUpdate(false);
                setCanSubmit(true);        
                throw e;

            }

        });
        if(url){
            window.location.href = url;
        }
        BusyUpdate(false);
        setCanSubmit(true);
    }

    return(
            <Section sx={{flexGrow: 1}} id="section-plans" bgColor={bg2}>
                <Container>
                    {!loading && <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                        <div>
                            <TitleBlock sx={{justifyContent: 'center'}}>
                                <Title textAlign="center">{title}</Title>
                                <SubHeading>{subtitle}</SubHeading>
                            </TitleBlock>
                        </div>
                    </Animation>}
                    {!loading && <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='zoom' direction='left' transitionDelay={0}>
                        <div>
                            {<Grid className="info" container justifyContent='center' alignItems="stretch"spacing={2}>
                                {
                                    plans.map((f, i) => {
                                        {f.prices = priceMap.get(f.id) ?? []}
                                        return <Grid key={i} item xs={12} md={4}>
                                            <Item sx={{height: '100%'}}>
                                                <div style={{height: '100%'}}>
                                                    <CardPlan checkoutHandler={(priceId: string)=>goToCheckoutUrlAsync(priceId)} sx={{bgcolor: ColorsUtil(theme, 'White'), height: "100%"}} plan={f}/>
                                                </div>                                    
                                            </Item>
                                        </Grid>
                                    })
                                }
                            </Grid>}
                        </div>

                    </Animation>}
                </Container>
            </Section>
    )
}


export default PlanOptions