
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';
import LoadingButton from "components/buttons/LoadingButton"
import {UseActiveUserIdStore, Subscription as ActiveUserIdSubscription, Update as UpdateActiveUserId} from 'stores/ActiveUserIdStore';
import { CommonUtil } from "utils/CommonUtil"


const NewEmailConfirm = (props: any) => {
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];

    const userService = new UserService();
    const [searchParams] = useSearchParams();
    const [isEmailConfirmed, setIsEmailConfirmed] = useState<boolean>();
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [token, setToken] = useState<string>();
    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }


    useEffect(() => {
        loadDataAsync();
    }, []);


    const loadDataAsync = async() => {
        BusyUpdate(true);
        const t = searchParams.get("token");
        setToken(t);
        BusyUpdate(false);        

    }


    const onConfirmNewEmailAsync = async() => {
        if(!canSubmit)
            return;
        setValidationMsg("");


        if(CommonUtil.isNullOrEmpty(token)){
            setValidationMsg("Token is required");
            return;
        }

        setCanSubmit(false);
        var result = await userService.updateEmailAsync(token).catch((err: Error) => {
            setValidationMsg(err.message);
        });
        
        setIsEmailConfirmed(result === true);
        if(result === true){
            await userService.authRefreshAsync();
            UpdateActiveUserId(null);
            UpdateActiveUserId(userService.getActiveUserId());
        }

        setCanSubmit(true);
    }

    const action = isEmailConfirmed ? null : <LoadingButton loading={!canSubmit} disabled={!canSubmit} onClick={() => onConfirmNewEmailAsync()} fullWidth>Confirm
    </LoadingButton>;
    return(
        <Section sx={{flexGrow: 1}} id="section-plans" bgColor={bg2}>
            <Container maxWidth="xs">
                <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                    <Form 
                    validationMsg={validationMsg}
                    action={
                        <Box>
                            {action}
                        </Box>                        
                    }
                    title={"Email Confirmation"}>
                            {!isEmailConfirmed && <Text>Click the button below to confirm your new email</Text>}
                            {isEmailConfirmed && <Text align='center'>Your new email was successfully confirmed!</Text>}

                    </Form>
                    </div>

                </Animation>
            </Container>
        </Section>
    )

}

export default NewEmailConfirm
