import React from "react";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { CSSProperties } from "@mui/material/styles/createTypography";

const Alert = React.forwardRef(function Alert(props: any, ref: any) {
    const {flat, ...rest} = props;
    const style: CSSProperties = flat ? {borderRadius: 0} : {}; 
    return <MuiAlert style={style} ref={ref} elevation={0} variant="filled" {...rest} />;
  });

export default Alert;