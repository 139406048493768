
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Chip, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { ReactElement, useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"

import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import Link from "components/buttons/Link"
import LoadingButton from "components/buttons/LoadingButton"
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"


class FaqItem{
    constructor(category: string, tags: string[], question: string, answerHtml: ReactElement | string, learnMoreLink: string = null){
        this.category = category;
        this.tags = tags;
        const body = answerHtml instanceof String ? <Text>{answerHtml}</Text> : answerHtml as ReactElement;
        this.question = question;
        this.html = body;
        this.learnMoreLink = learnMoreLink;
        
    }
    public question: string;
    public category: string;
    public tags: string[];
    public html: ReactElement;
    public learnMoreLink: string;
    
}

const ItemCard = (props: any) => {
    const {faq, ...rest} = props;
    const item: FaqItem = faq as FaqItem;
    const title = item.question;
    // const title = <Stack width='100%' direction='row' justifyContent='space-between'><Subtitle>{item.question}</Subtitle><Chip label={item.category} color='primary' /></Stack>

    return(
        <ExpansionPanel title={title}>
            <Box>
                {item.html}
                {item.learnMoreLink && <Box mt={1}><Link component={NavLink} to={item.learnMoreLink} color="inherit">Learn more</Link></Box>}
            </Box>
        </ExpansionPanel>
    )
}

const Faq = (props: any) => {
    const {success, ...rest} = props;
    const navigate = useNavigate();
    const userService = new UserService();
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);

    const images = prodInfoService.getMedia();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [videoLoaded, setVideoLoaded] = useState<boolean>();


    const appItems: FaqItem[] = [
        new FaqItem(
            'Exhibits',
            ['Exhibits', 'Management', 'add', 'remove', 'replace', 'update',],
            'Can my collection of exhibits be updated?',
            'Yes. You can add, remove, or replace exhibits at any time.',
            "/articles/exhibit-management"
            ),
        new FaqItem(
            'References',
            ['References', 'Sequence', 'Edit', 'Exhibit Label', 'update',],
            'Can reference labels appear numerically (Exhibit 1 vs Exhibit A)?',
            'Yes. Reference labels are customizable. You can change the display text, the style, and whether the sequence is alphabetical or numerical.',
            ),
        new FaqItem(
            'Publish',
            ['Publish', 'Insert Position', 'modify', 'update',],
            "Can I decide where my collection of exhibits appears within my digital binder?",
            'Yes. You can adjust the publish settings to control where your exhibits will appear within your digital binder.',
            "/articles/publish-package"
            ),


    ]


    const technicalItems: FaqItem[] = [
        new FaqItem(
            'Privacy',
            ['Exhibits'],
            'Where are the exhibits stored?',
            'The exhibits are stored on your device. Sensitive data is never transmitted, processed, or stored on any remote servers.'
            ),
        new FaqItem(
            'System',
            ['System', 'Requirements', 'Word', 'Version'],
            'Does Zibbit work on Mac computers?',
            'No. Zibbit is only available for Windows computes. It is built for the desktop version of Word.'
        ),
        new FaqItem(
            'System',
            ['System', 'Requirements', 'Word', 'Version'],
            'What are the system requirements?',
            <Text>See list of requirements <Link component={NavLink} to={'/downloads?requirements=true'} color="inherit">here</Link>.</Text>
        ),
        new FaqItem(
            'Team',
            ['System', 'Collaboration', 'Teams'],
            'Is collaboration possible with Zibbit?',
            'Collaboration on Word documents is supported. One caveat is that some Zibbit functionality may be limited if a given user does not have direct access to the exhibit files. However, Zibbit uses environment variables where possible to enable multiple users to have direct access to the exhibits.'
        ),
        new FaqItem(
            'Price',
            ['Cost', 'Price'],
            'How much does Zibbit cost?',
            <Text>Zibbit is a subscription service. See available plans <Link component={NavLink} to={'/plans'} color="inherit">here.</Link></Text>
        )
    ]
    useEffect(() => {
        //BusyUpdate(true);
        //setPlansAsync();
    }, []);



    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    const handleVideoLoaded = () => {
        BusyUpdate(false);
    }

    return(
        <Section sx={{flexGrow: 1}} bgColor={bg2}>
            <Container maxWidth="md">

                <TitleBlock sx={{justifyContent: 'center'}}>
                    <Title textAlign="center">FAQ</Title>
                    <SubHeading textAlign="center">Find answers to common questions</SubHeading>
                </TitleBlock>

                <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                        <Box>
                            <Box mb={2}>
                                <Subtitle bold mb={0}>Application</Subtitle>
                                {/* <Text>Learn about the application</Text> */}
                            </Box>

                            {appItems.map((item,  i)=> {
                                return <ItemCard key={i} faq={item}/>
                            })}

                            
                            <Box mt={8} mb={2}>
                                <Subtitle bold mb={0}>Technical</Subtitle>
                                {/* <Text>Learn about the application</Text> */}
                            </Box>
                            {technicalItems.map((item,  i)=> {
                                return <ItemCard key={i} faq={item} />
                            })}
                        </Box>
                    </div>
                </Animation>
            </Container>
        </Section>
    )

}


export default Faq