import { Button, IconButton, Snackbar } from '@mui/material'
import Icon from 'components/icons/Icon'
import React, { useState } from 'react'
import { CommonUtil } from 'utils/CommonUtil'
import { UseToastStore, Update as ToastUpdate } from 'stores/ToastStore';
import { Toast } from 'stores/models/Toast';


const commonUtil = new CommonUtil();

const CopyToClipboardButton = (props: any) => {
    const {content, ...rest} = props
    const handleClick = () => {
      ToastUpdate(new Toast(true, "Copied to clipboard"));
      commonUtil.CopyToClipboard(content);
    }
    
    return (
        <>
          <IconButton onClick={handleClick}><Icon normal>content_copy</Icon></IconButton>
        </>
    )
}

export default CopyToClipboardButton