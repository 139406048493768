import * as React from 'react';
import Typography  from '@mui/material/Typography';
import { CSSProperties } from 'react';

const Heading = (props: any) => {
    const {textAlign, ...rest} = props;
    const align = textAlign ?? 'left';
    const style: CSSProperties = {
        textAlign: align
    }
    return <Typography gutterBottom sx={style} variant="h3" component="h2" {...rest} />
}

export default Heading;