
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import Link from "components/buttons/Link"
import LoadingButton from "components/buttons/LoadingButton"
import { FreeTrialStatus } from "models/FreeTrialStatus"
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';
import { Update } from "@mui/icons-material"
import { CommonUtil } from "utils/CommonUtil"




const ConfirmEmailForm = (props: any) => {
    const {inline, wasEmailAlreadySent, onConfirmed, hideTitle, flat, ...rest} = props;
    const navigate = useNavigate();
    const userService = new UserService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    

    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);

    const [isEmailConfirmed, setIsEmailConfirmed] = useState<boolean>(false);
    const [isTrialActive, setIsTrialActive] = useState<boolean>(false);
    const [wasEmailSent, setWasEmailSent] = useState<boolean>(wasEmailAlreadySent);

    useEffect(() => {
        loadDataAsync();
    }, []);

    const loadDataAsync = async() => {
        setCanSubmit(false);
        var isEmailConfirmed = false;
        var isTrialActive = false;
        if(userService.isAuthenticated()){
            const user = await userService.getUserAsync(userService.getActiveUserId());
            var trialStatus = await userService.GetFreeTrialStatusAsync();
            isEmailConfirmed = user.emailConfirmed;
            isTrialActive = trialStatus === FreeTrialStatus.Active;

        }
        setIsEmailConfirmed(isEmailConfirmed);
        setIsTrialActive(isTrialActive);
        setCanSubmit(true);
    }

    const onRequestResetLinkAsync = async() => {
        setCanSubmit(false);
        var result = await userService.sendEmailConfirmationLinkAsync();
        setWasEmailSent(true);   
        setCanSubmit(true);
    }  

    const onRequestLinkAsync = async() => {
        BusyUpdate(true);
        // var util = new CommonUtil();
        // await util.timeoutAsync(3000);
        var result = await userService.sendEmailConfirmationLinkAsync();
        BusyUpdate(false);
        
    }

    const onRefresh = async() => {
        setValidationMsg("");
        setCanSubmit(false);
        // var util = new CommonUtil();
        // await util.timeoutAsync(3000);
        
        //await loadDataAsync();
        var isEmailConfirmed = false;
        if(userService.isAuthenticated()){
            const user = await userService.getUserAsync(userService.getActiveUserId());
            isEmailConfirmed = user.emailConfirmed;
        }

        if(isEmailConfirmed)
            onConfirmed();
        else{
            setValidationMsg("Your email has not been confirmed yet")
        }
        setCanSubmit(true);

    }

    return(
        <>
            {!inline && <Form 
            validationMsg={validationMsg}
            action={ isEmailConfirmed || wasEmailSent ? null : <LoadingButton loading={!canSubmit} disabled={!canSubmit} onClick={() => onRequestResetLinkAsync()} fullWidth>New link</LoadingButton>}
            title="Email Confirmation">
                {isEmailConfirmed && <Box>
                    <Text>Your email was successfully confirmed.</Text>
                    {isTrialActive && <Text>Enjoy your <strong>Free Trial!</strong></Text>}
                </Box>}
                {!isEmailConfirmed && <Box>
                    {wasEmailSent && <Text>An email was sent that contains a confirmation link</Text>}
                    {!wasEmailSent && <Text>Click the button below to request a new link to confirm your account</Text>}
                </Box>}
            </Form>}

            {inline && <Form flat
            title={hideTitle ? null : "Email Confirmation"}
            validationMsg={validationMsg}
            action={<Box>
                <LoadingButton 
                    loading={!canSubmit} 
                    disabled={!canSubmit} 
                    onClick={() => onRefresh()} 
                    fullWidth>
                        Continue
                </LoadingButton>
                {!isEmailConfirmed && <Text mt={1} variant="caption">Request a new <Link onClick={() => onRequestLinkAsync()}>link</Link></Text>}
                </Box>}
            >
                {isEmailConfirmed && <Box>
                    <Text>Your email was successfully confirmed.</Text>
                    {isTrialActive && <Text>Enjoy your <strong>Free Trial!</strong></Text>}
                </Box>}
                {!isEmailConfirmed && <Text>A link was sent to confirm your email. Click the button below after confirming your email.</Text>}
            </Form>}

        </>
    )
}


export default ConfirmEmailForm