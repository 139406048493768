export class Card {
    constructor(title: string, description: string | React.ReactElement, icon: string = '', iconBgColor: string = null){
        this.title = title;
        this.description = description;
        this.icon = icon;
        this.iconBgColor = iconBgColor;
    }
    route: string;
    icon: string;
    iconBgColor: string;
    title: string;
    subtitle: string;
    description: string | React.ReactElement;
}