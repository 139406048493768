
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan, PlanType, SubscriptionStatus } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import Link from "components/buttons/Link"
import LoadingButton from "components/buttons/LoadingButton"
import { Tenant } from "models/Tenant"
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';
import { TenantService } from "services/TenantService"
import OrganizationInfoForm from "views/organization/OrganizationInfoForm"
import useInterval from "hooks/UseInterval"
import { BadRequestError, NotFoundError } from "errors/ApplicationErrors"
import { User } from "models/User"
import usePoler from "hooks/UsePoler"
import AccessManagerButton from "views/organization/AccessManagerButton"



const SimplePaymentResult = (props: any) => {
    const {success, ...rest} = props;
    const navigate = useNavigate();
    const userService = new UserService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];

    const [searchParams] = useSearchParams();
    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showForm, setShowForm] = useState<boolean>(false);
    const [org, setOrg] = useState<Tenant>();
    const [plan, setPlan] = useState<Plan>();
    const [orgUsers, setOrgUsers] = useState<User[]>();
    const tenantService = new TenantService();


    const polTimeoutHandler = () => {
        BusyUpdate(false);
        throw new NotFoundError("Your subscription was not found. Try refreshing the browser.");
    }


    const polIntervalHandler = async() => {

        if(plan == null){
            var p = await tenantService.getOrganizationPlanAsyn(org.id);
            const isCustomer = p.type !== PlanType.Pilot && p.type !== PlanType.Free; // wait for plan to update

            if(isCustomer){
                setPlan(p);
                stopPoling();
                BusyUpdate(false);
                await Bootstrapper.setUserInfoAsync();
            }
        }



    }
    
    const [startPoling, stopPoling] = usePoler(polTimeoutHandler, polIntervalHandler, 2000, 60 * 1000);


    useEffect(() => {
        if(!success)
            return;

        setDataAsync();
        startPoling();
    }, []);


    const setDataAsync = async() => {
        BusyUpdate(true);
        var tenant = await tenantService.getOwnerOrganization();
        var users = await userService.getUsersByOrgIdAsync(tenant.id);
        setOrg(tenant);
        setOrgUsers(users);
    }

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }



    const deactivateMembershipsRequired = (orgUsers?.length ?? 0) > (plan?.maxUsers ?? 0);
    return(
        <Section sx={{flexGrow: 1}} bgColor={bg2}>
            <Container maxWidth="xs">
                <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                        {!success && <Form 
                            action={<Button component={NavLink} to={'/home'} fullWidth>Home</Button>}
                            title='Payment Canceled'>
                        </Form>}

                        {success && plan && <Box>
                            {deactivateMembershipsRequired && <Form 
                            action={<AccessManagerButton onSuccess={() => navigate(`/organization/${org?.id}`)} fullWidth orgId={org?.id} orgUsers={orgUsers} orgPlan={plan} />}
                            title='Checkout Successful!'>
                            </Form>}
                            {!deactivateMembershipsRequired && <Form 
                            action={<Button component={NavLink} to={`/organization/${org?.id}`} fullWidth>Organization</Button>}
                            title='Checkout Successful!'>
                        </Form>}
                        </Box>}
                    </div>
                </Animation>
            </Container>
        </Section>
    )

}


export default SimplePaymentResult