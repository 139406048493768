import * as React from 'react';
import {styled, Tooltip as MuiTooltip, tooltipClasses, TooltipProps} from '@mui/material';
import Text from 'components/labels/Text';

// const Tooltip = (props: any) => {
//   const {title, children, ...rest} = props;
//   return (
//       <MuiTooltip title={title} {...rest}><div>{children}</div></MuiTooltip>
//   );
// }


const Tooltip = styled(({title, className, ...props }: TooltipProps) => {
  const titleEle = title ?  <Text>{title}</Text> : "";
  return(<MuiTooltip {...props} title={titleEle} classes={{ popper: className }} />)

}
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
  },
});

export default Tooltip;