
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, ThemeProvider, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import { Tenant } from "models/Tenant"
import { TenantService } from "services/TenantService"
import {UseOrganizationStore, Subscription as OrganizationSubscription, Update as UpdateOrganization} from 'stores/OrganizationStore';
import { Bootstrapper } from "Bootstrapper"
import { OrganizationContext } from "./OrganizationContext"
import CircularProgress from "components/progress/CircularProgress"
import { NavItem } from "components/dashboard/Navbar"
import Dashboard from "components/dashboard/Dashboard"
import Alert from "components/alerts/Alert"
import { BadRequestError } from "errors/ApplicationErrors"


const Organization = (props: any) => {
    const navigate = useNavigate();
    const context = Bootstrapper.getInstance<OrganizationContext>(OrganizationContext);

    const userService = new UserService();
    const tenantService = new TenantService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const organization = UseOrganizationStore();
    const location = useLocation();
    const { id } = useParams(); 


    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }


    useEffect(() => {
        // context.reset();
        context.isBusyEmitter.subscribe(val => {
            setIsBusy(val);
        })
        loadDataAsync();
    }, [id]);

    const loadDataAsync= async() => {
        context.setBusyIndicator(true);
        setHasLoaded(false);
        UpdateOrganization(null);
        var organization = await tenantService.getOrganizationById(id);
        var hasActiveStatus = await tenantService.isSubscriptionValidAsyn(id).catch((err:Error) => {
            if(err instanceof BadRequestError){
                setValidationMsg(err.message);
            }
            else
                throw err;
        });
        UpdateOrganization(organization);
        setHasLoaded(true);
        context.setBusyIndicator(false);
    }



    const getNavItems = () => {
        const items: NavItem[] = [
            new NavItem('Users', `/organization/${organization?.id}`),
            new NavItem('Invitations', `/organization/${organization?.id}/invitations`),
            new NavItem('Settings', `/organization/${organization?.id}/profile`),
        ];
        return items;
        
    }

    return(

        <Dashboard
        titleBar={organization?.name}
        navItems={hasLoaded ? getNavItems() : []}
        alerts={validationMsg && <Alert flat severity="warning">{validationMsg}</Alert>}
        isBusy={isBusy}
    >
        {hasLoaded && <Outlet />}
    </Dashboard>


    )
    }



export default Organization