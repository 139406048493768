import * as React from 'react';
import {Menu as MuiMenu, MenuProps} from '@mui/material';

const Menu = (props: MenuProps) => {
  

  return (
      <MuiMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...props} 
       />
  );
}
export default Menu;