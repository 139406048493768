import * as React from 'react';
import {AppBar as MuiAppBar, Avatar, Collapse, Color, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Stack, ThemeProvider, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { MyTheme } from 'MyTheme';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Bootstrapper } from 'Bootstrapper';
import Button from 'components/buttons/Button';
import ExpansionPanel from 'components/transitions/expansionPanel/ExpansionPanel';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandableListItem from 'components/list/ExpandableListItem';



const getItemView = (item: NavItem, key: number, onClick: (i: NavItem)=>void, nested: boolean = false ) => {
  const handleClick = (itm:NavItem) => {
    // e.stopPropagation();
    onClick && onClick(itm);
  }
  const hasChildren = item.children?.length > 0;
    return(
      
      <ListItem sx={{borderBottom: `${nested ? 0 : 1}px solid`}} disablePadding key={key}>
        {!hasChildren && <>
          {!nested && <ListItemButton onClick={(e: any) => handleClick(item)} sx={{p: 1, pl: 3}}>{item.label}</ListItemButton>}
          {nested && <ListItemButton  onClick={(e: any) => handleClick(item)}>{item.label}</ListItemButton>}
        </>}
        {hasChildren &&  <List sx={{width: '100%'}} dense disablePadding>

          <ExpandableListItem title={item.label} sx={{p: 1, pl: 3}}>
          {item.children.map((child, i) => {
            return(
              <List key={i} sx={{pl: 3,}} disablePadding>

                {getItemView(child, i, () => handleClick(child), true)}
              </List>
            )

            })}

        </ExpandableListItem>
      </List>}
    </ListItem>
    )
  

}
export class NavItem{
  constructor(label: string, to: string, children: NavItem[] = []){
    this.label = label;
    this.to = to;
    this.children = children;
  }
  public label: string;
  public to: string;
  public children: NavItem[];
  public parentLabel: string;

  public fullname(): string{
    const prefix = this.parentLabel ? `${this.parentLabel} / ` : '';
    return prefix + this.label;

  }
}

const MobileMenu = (props: any) => {

  const getAllItems = (items: NavItem[], parentName: string = null): NavItem[] => {
    const result: NavItem[] = [];
    items.map(i => {
      i.parentLabel = parentName;
      result.push(i)
      if(i.children.length > 0)
        result.push(...getAllItems(i.children, i.label));
    })
    return result;
    
  }
  const navigate = useNavigate();

  const items: NavItem[] = props.items;
  const allItems = getAllItems(items);
  const location = useLocation();
  const selectedItem = allItems.find(i => i.to === location.pathname);

  const [open, setOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [btnLabel, setBtnLabel] = React.useState<string>(selectedItem?.fullname());
  // const open = Boolean(anchorEl);
  
  const menuClicked = (event: any) => {
      //setAnchorEl(event.currentTarget);
      //event.stopPropagation()
      setOpen(!open);
  };

  
  const handleClose = (e: any) => {
      setAnchorEl(null);
      e.stopPropagation();
  };

  const onMenuItemClick = (item: NavItem) => {
    setBtnLabel(item.fullname());
    navigate(item.to)
    setOpen(false);
  }
  return(
    <>
    <Button sx={{borderRadius: 0}} fullWidth onClick={(e: any) => menuClicked(e)}>
        {btnLabel}
    </Button>
      <Box sx={{overflow: "unset !important"}} position="relative" id="drawer-container">
      <Drawer
      // style={{position: 'absolute'}}
      PaperProps={{ style: {width:'100%', height: 'unset', position: 'absolute' } }}
      // BackdropProps={{ style: { position: 'absolute' } }}
      ModalProps={{
        container: document.getElementById('drawer-container'),
        style: { position: 'absolute' }
      }}
      variant="temporary"
      // BackdropProps={{ style: { position: 'absolute' } }}
      // hideBackdrop={false}
      // container={() => document.getElementById('dashMenu')}
      anchor="left"
      open={open}
      onClose={() => setOpen(false)}
    >
      <List disablePadding>
      {items.map((item, i) => {
            return(
              getItemView(item, i, (itm) => onMenuItemClick(itm))

            )
          })}
      </List>
    </Drawer>
    </Box>

      </>
  )

}

const Navbar = (props: any) => {
    const navigate = useNavigate();
    const {navItems, ...rest} = props;
    const items: NavItem[] = navItems;
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const btnStyle = {
      display: 'flex', 
      justifyContent: 'flex-start'
    }




    return (
      <>
      {isMobile && <MobileMenu items={items} />}

      {!isMobile && <ThemeProvider theme={MyTheme("light")}>
        <Box color={theme.palette.grey[300]} bgcolor={theme.palette.primary.main} height="100%" display="flex" flexGrow={1}>
              
              <List sx={{width: '100%'}} dense disablePadding>
                {items.map((item, i) => {
                  return(
                    getItemView(item, i, (itm) => navigate(itm.to))
                    // <ListItem sx={{borderBottom: '1px solid'}} disablePadding key={i}>

                    //   {<ListItemButton sx={{p: 1, pl: 3}} component={Link} to={item.to}>{item.label}</ListItemButton>}
                    // </ListItem>

                  )
                })}

              </List>
        </Box>
    </ThemeProvider>}

    </>

  )
}

export default Navbar;