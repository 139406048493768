import {Checkbox as MuiCheckbox, FormControlLabel, FormLabel} from '@mui/material';
import Text from 'components/labels/Text';
import React from 'react';
import ToggleField from '../ToggleField';

const Checkbox = (props: any) => {
  const {label, labelPlacement, ...rest} = props
  return (

    <FormControlLabel sx={{ml: "-11px !important"}}  label={<FormLabel>{label}</FormLabel>} labelPlacement={labelPlacement} control={<MuiCheckbox  {...rest}  />}  />
  );
}
export default Checkbox;