
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"

import { User } from "models/User"
import {UseOrganizationStore, Subscription as OrganizationSubscription, Update as UpdateOrganization} from 'stores/OrganizationStore';
import { Bootstrapper } from "Bootstrapper"
import {Context} from '../Context'
import { DialogUtil } from "utils/DialogUtil"
import LoadingButton from "components/buttons/LoadingButton"
import { FreeTrialStatus } from "models/FreeTrialStatus"
import UserMenu from "./UsersMenu"
import { BadRequestError } from "errors/ApplicationErrors"


const Users = (props: any) => {
    const navigate = useNavigate();
    const context = Bootstrapper.getInstance<Context>(Context);

    const userService = new UserService();
    const tenantService = new TenantService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dialogUtils = new DialogUtil();
    
    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [users, setUsers] = useState<User[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [inviteeEmail, setInviteeEmail] = useState<string>("");
    const [trialDays, setTrialDays] = useState<number>(null);


    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    useEffect(() => {
        loadDataAsync();
    }, []);

    const loadDataAsync= async() => {
        context.setBusyIndicator(true);
        const users = await userService.getUsersAsync();
        setUsers(users);
        context.setBusyIndicator(false);
    }


    const getUsersList = (users: User[]) => {
        return(
            <>
                <Table className={isMobile ? "responsive-table" : ""} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell align="center">Confirmed Email</TableCell>
                            <TableCell align="right">Date Registered</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(user => {
                            return (
                                <TableRow
                                    key={user.id}
                                >
                                <TableCell data-label="Email" component="th" scope="row">{user.userName}</TableCell>
                                <TableCell data-label="Confirmed Email" align="center" scope="row">{user.emailConfirmed.toString()}</TableCell>
                                <TableCell data-label="Date Registered" align={isMobile ? 'left' : "right"} scope="row">{new Date(user.dateRegistered).toLocaleDateString()}</TableCell>
                                <TableCell data-label="Action" align={isMobile ? 'left' : "right"} scope="row">
                                    <UserMenu user={user} onRemoveUser={setUsers} />
                                </TableCell>
                            </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </>
        )
    }

    return(
        <Section disablePadding id="section-plans" bgColor={bg1}>
                <Animation duration="medium" overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>

                        <Stack spacing={1}>
                                <Paper>
                                    {getUsersList(users)}
                                </Paper>
                        </Stack>                  
                    </div>
                </Animation>
        </Section>

    )


}


export default Users