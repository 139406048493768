import {Backdrop as MuiBackdrop} from '@mui/material';

const Backdrop = (props: any) => {
    
    return(
        <MuiBackdrop
        sx={{background: 'rgba(0, 0, 0, 0.2);', color: '#fff', zIndex: (theme) => theme.zIndex.tooltip + 1 }}
         {...props}></MuiBackdrop>
    )
}

export default Backdrop;