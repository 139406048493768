import { Box, CardActions, CardHeader, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import * as React from 'react';
import {Card as MuiCard, CardContent} from '@mui/material';
import Icon from 'components/icons/Icon';
import SubHeading from 'components/labels/SubHeading';
import Button from 'components/buttons/Button';
import Container from 'components/layout/Container';
import Dialog from './Dialog';
import { ColorsUtil, ColourType, MyTheme } from 'MyTheme';
import LoadingButton from 'components/buttons/LoadingButton';
import { useState } from 'react';


const ConfirmDialog = (props: any) => {
    const {title, open, onClose, onResult, children, ...rest} = props;
    const theme = MyTheme();

    const cb = (val: boolean) => {
        onClose();
        onResult(val);
    }
    return(
        <Dialog
            maxWidth="xs" 
            open={true}
            title={title ?? "Confirm"} 
            onClose={() => cb(false)}
            children={children}
            action={<Button variant="contained" onClick={() => cb(true)}>Ok</Button>}
            {...rest}
        >
        </Dialog>
    ) 
}

export default ConfirmDialog;