import axios from 'axios';

import { Plan } from 'models/Plan';
import { Price } from 'models/Price';
import isDev from 'MySettings';
import { singleton } from 'tsyringe';

@singleton()
export class PlansClient {
    private baseUrlDev: string = "https://localhost:5001";
    private baseUrlProd: string = "https://zibbit-identity.azurewebsites.net";

    private baseUrl: string;
  
    constructor() {

        this.baseUrl = (isDev() ? this.baseUrlDev : this.baseUrlProd) + "/api/plans";
    }


    public getPlanAsync(tenantId: string): Promise<Plan> {
        var url = this.baseUrl + "/" + tenantId;
        return axios.get(url)
        .then(function (response) {
            var plan: Plan = response.data;
            return plan;
        })

    }

    public getPlansAsync(isFirmPlans: boolean): Promise<Plan[]> {
        var url = this.baseUrl + `/plans?firmPlans=${isFirmPlans}`;

        return axios.get(url)
        .then(function (response) {
            var connections: Plan[] = response.data;
            return connections;
        })

    }

    public getGrandfatheredPlanAsync(): Promise<Plan> {
        var url = this.baseUrl + `/plans/grandfathered`;

        return axios.get(url)
        .then(function (response) {
            var connections: Plan = response.data;
            return connections;
        })

    }

    public getTestPlanAsync(): Promise<Plan> {
        var url = this.baseUrl + "/test-plan";
        return axios.get(url)
        .then(function (response) {
            var connections: Plan = response.data;
            return connections;
        })

    }

    public getPrices(planId: string): Promise<Price[]> {
        var url = this.baseUrl + "/prices/" + planId;
        return axios.get(url)
        .then(function (response) {
            var connections: Price[] = response.data;
            return connections;
        })
    }

    public getCustomerPortalUrlAsync(): Promise<string> {
        var url = this.baseUrl + "/customer-portal";
        return axios.get(url)
        .then(function (response) {
            return response.data;
        })
    }



    public createCheckoutSession(priceId: string): Promise<string> {
        var bodyFormData = new FormData();
        //bodyFormData.append("userId", userId);
        bodyFormData.append("priceId", priceId)
        var url = this.baseUrl + "/payment";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            var checkoutUrl = response.data;
            return checkoutUrl;
        })
    }
}