import { TokenUtil } from 'utils/TokenUtil';
import { StoreBase, UseStore } from './StoreBase';

const tokenUtil = new TokenUtil();
const store = new StoreBase<string>(tokenUtil.getActiveUserId());

export const GetState = () => store.state;
export const Update = (val: string) => {    
    store.update(val);
}
export const Subscription = (fn: (val: string) => any) => {
    store.subscription(fn)
}
export function UseActiveUserIdStore(){
    let result = UseStore(store);
    return result;
}
