import { useMediaQuery } from '@mui/material';
import useVisibility from 'hooks/UseVisibility';
import { MyTheme } from 'MyTheme';
import * as React from 'react';
import { useState } from 'react';

const Video = (props: any) => {
    const {onAppearance, src, toggleOnAppearance, ...rest} = props;
    const theme = MyTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const handleVisibilityChanged = (isVisible: boolean) => {
        if(isMobile || !toggleOnAppearance)
            return;

        try{ // swallow errors - play was interrupted by pause
            if(isVisible)
                ele?.play()?.catch();
            else
                ele?.pause();
        }
        catch{}
    }
    const [isCompEleVisible, setRef] = useVisibility<HTMLElement | null>(false, onAppearance ?? handleVisibilityChanged, 0.85);
    const [ele, setEle] = useState<HTMLVideoElement>();


    const handleRefSet = (ele: HTMLVideoElement) => {
        setRef(ele);
        setEle(ele);
        // if(ele == null)
        //     return;
        // ele.onplaying = () => {
        //     setIsPlaying(true);
        // };
        // ele.onpause = () => {
        //     setIsPlaying(false);
        // }
    }


    return <video controls muted ref={r => handleRefSet(r)} {...rest} poster={rest.poster}>
                <source src={src} type="video/mp4" />
                <object data={src}>
                </object>
                Your browser does not support the video tag.
            </video>
}


export default Video;