import { CSSProperties } from '@mui/material/styles/createTypography';
import Icon from 'components/icons/Icon';
import * as React from 'react';
import './computer.scss';


export interface IComputerProps{
    imgStyle?: CSSProperties;
    callback?: () => void;
}



const Computer = (props: IComputerProps) => {
    const hasBtn: boolean = props.callback != null;
    const imgStyle = props.imgStyle;

    const handleBtn = () => {
        if(props.callback)
            props.callback();
    }   
    return(
        <div className="compWrapper">
            <div className="comp">
                <div className="monitor">
                    {hasBtn && <div onClick={handleBtn} className="splash">
                        <Icon className="play-icon" sx={{fontSize: '60px'}}>play_circle</Icon>
                    </div>}

                    <div style={imgStyle} className="background">
                        
                    </div>

                </div>
                <div className="stand">
                    <div className="neck"></div>
                    <div className="base"></div>
                </div>
            </div>
        </div>


    )
}

export default Computer;