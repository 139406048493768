import { StoreBase, UseStore } from './StoreBase';

const store = new StoreBase<boolean>(false);

export const GetState = () => store.state;
export const Update = (val: boolean) => {
    store.update(val)
}
export const Subscription = (fn: (state: boolean) => void) => {
    store.subscription(fn)
}
export function UseAppInitStore(){
    let result = UseStore(store);
    return result;
}
