
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"
import { Tenant } from "models/Tenant"
import { BadRequestError } from "errors/ApplicationErrors"
import { Bootstrapper } from "Bootstrapper"
import { Context } from "../Context"
import LoadingButton from "components/buttons/LoadingButton"
import ConfirmDialog from "components/modals/ConfirmDialog"
import { DialogUtil } from "utils/DialogUtil"


const Organizations = (props: any) => {
    const navigate = useNavigate();
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);

    const userService = new UserService();
    const tenantService = new TenantService();
    const context = Bootstrapper.getInstance<Context>(Context);
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dialogUtils = new DialogUtil();

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [accessKey, setAccessKey] = useState<string>("");
    const [organizations, setOrganizations] = useState<Tenant[]>([]);
    const [regOrgName, setRegOrgName] = useState<string>("");
    const [trialPeriodDays, setTrialPeriodDays] = useState<number>(null);
    const [orgPlanMap, setOrgPlanMap] = useState<Map<string, Plan>>();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const userId = userService.getActiveUserId()
    const location = useLocation();
    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    useEffect(() => {
        loadDataAsync();
    }, []);

    const loadDataAsync= async() => {
        context.setBusyIndicator(true);
        const orgs = await tenantService.getOrganizationsAsync();
        const p = orgs.map(o => {
            return getPlanAsync(o.id);
        })
        const orgPlanMap = new Map<string, Plan>();
        var data = await Promise.all(p);
        data.forEach(d => {
            orgPlanMap.set(d.orgId, d.plan);
        })
        setOrgPlanMap(orgPlanMap);
        setOrganizations(orgs);
        context.setBusyIndicator(false);
    }

    const getPlanAsync = async(orgId: string) => {
        const plan = await tenantService.getOrganizationPlanAsyn(orgId);
        return {orgId: orgId, plan: plan};
    }

    const onCloseModal = () => {
        setIsOpen(false);
        setCanSubmit(true);
        setValidationMsg(null);
        setRegOrgName(null);
    }


    const InviteMenu = (props: any) => {
        const {organization, ...rest} = props;
        const org = organization as Tenant;
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        
        
        const menuClicked = (event: any) => {
            setAnchorEl(event.currentTarget);
            event.stopPropagation()
        };
    
        
        const handleClose = (e: any) => {
            setAnchorEl(null);
            e.stopPropagation();
        };

        const onViewMenuItemClick = (org: Tenant) => {
            navigate(`/organization/${org.id}`);
        }


        const onDeleteOrganizationAsync = async(org: Tenant) => {
            dialogUtils.confirmDialog(
                `Are you sure you want to delete this organization: ${org.name}?`,
                async(result) => {
                    if(result){
                        await tenantService.deleteOrganizationAsync(org.id);
                        const orgs = await tenantService.getOrganizationsAsync();
                        setOrganizations(orgs);
                    }
            })
        }


        const onBeginPilotAsync = async(org: Tenant) => {
            dialogUtils.confirmDialog(
                `Start a pilot program for this organization: ${org.name}?`,
                async(result) => {
                    if(result){
                        await tenantService.startPilotProgramAsync(org.id);
                        const orgs = await tenantService.getOrganizationsAsync();
                        setOrganizations(orgs);
                    }
            })
        }

        const onCancelPilotAsync = async(org: Tenant) => {
            dialogUtils.confirmDialog(
                `Cancel the pilot program for this organization: ${org.name}?`,
                async(result) => {
                    if(result){
                        await tenantService.cancelPilotProgramAsync(org.id);
                        const orgs = await tenantService.getOrganizationsAsync();
                        setOrganizations(orgs);
                    }
            })
        }


        // const onExtendPilotAsync = async(org: Tenant) => {
        //     dialogUtils.confirmDialog(
        //         `Extend the pilot program for organization: ${org.name}?`,
        //         async(result) => {
        //             if(result){
        //                 await tenantService.deleteOrganizationAsync(org.id);
        //                 const orgs = await tenantService.getOrganizationsAsync();
        //                 setOrganizations(orgs);
        //             }
        //     })
        // }


        return(
            <>
            <IconButton onClick={(e: any) => menuClicked(e)}>
                <Icon normal>more_vert</Icon>
            </IconButton>
            
                <Menu
                onClick={(e: any) => handleClose(e)}
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                >
                    {<MenuItem onClick={(e: any) => onViewMenuItemClick(org)}>
                        View
                    </MenuItem>}
                    
                    <MenuItem onClick={() => onBeginPilotAsync(org)}>
                        Begin Pilot
                    </MenuItem>
                    <MenuItem onClick={() => onCancelPilotAsync(org)}>
                        Cancel Pilot
                    </MenuItem>
                    <MenuItem onClick={() => onDeleteOrganizationAsync(org)}>
                        Remove
                    </MenuItem>

                </Menu>
                
            </>
        )
    
    }



    const getOrgsList = (orgs: Tenant[]) => {
        
        return(
                <Table className={isMobile ? "responsive-table" : "null"} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Organization</TableCell>
                            <TableCell align="left">Plan</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orgs.map(org => {
                            return (
                                <TableRow 
                                    // component={Link} to={`/organization/${org.id}`}
                                    key={org.id}
                                >
                                    <TableCell data-label="Organization" component="th" scope="row">{org.name}</TableCell>
                                    <TableCell data-label="Plan" align={isMobile ? 'left' : "left"} component="th" scope="row">{Plan.getFullNameLabel(orgPlanMap.get(org.id))}</TableCell>
                                    <TableCell data-label="Action" align={isMobile ? 'left' : "right"}>
                                        <InviteMenu organization={org} />
                                    </TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
        )
    }

    const handleRegisterOrgAsync = async() => {
        if(!canSubmit)
            return;
        setCanSubmit(false);
        setValidationMsg("");

        if(regOrgName != null){
            var result = await tenantService.registerOrganizationAsync(regOrgName).catch((err: Error) => {
                if(err instanceof BadRequestError){
                    setValidationMsg(err.message);
                }
                else{
                    setIsOpen(false);
                    throw err;
                }

            });

            if(result){                
                const orgs = await tenantService.getOrganizationsAsync();
                setOrganizations(orgs);
                onCloseModal();                
            }

        }
        setCanSubmit(true);

    }



    return(
        <Box flexGrow={1}>

        <Section disablePadding id="section-plans" bgColor={bg1}>
                <Animation duration="medium" overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>

                    <Stack spacing={1}>
                            <div>
                                <Button onClick={() => setIsOpen(true)}>Add Organization</Button>
                            </div>

                                <Paper>
                                    {getOrgsList(organizations)}
                                </Paper>
                        </Stack>
                    </div>
                </Animation>

                {isOpen && <Dialog
                    title="Register Organization"
                    action={<LoadingButton loading={!canSubmit} disabled={!canSubmit} onClick={() => handleRegisterOrgAsync()}>Submit</LoadingButton>} 
                    maxWidth="xs" 
                    open={isOpen} 
                    onClose={() => onCloseModal()}>
                            <Form flat validationMsg={validationMsg || ""}>
                                <FieldCollection>
                                    <TextField label="Name" value={regOrgName || ""} onChange={(ele: any) => setRegOrgName(ele.target.value)} {...props} />
                                </FieldCollection>
                            </Form>                    
                            </Dialog>}


            </Section>
        </Box>

    )


}


export default Organizations