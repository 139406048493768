
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Computer, { IComputerProps } from "../../components/objects/computer/Computer"
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import CardPlan from "./CardPlan"
import { Price } from "models/Price"
import { Bootstrapper } from "Bootstrapper"
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';
import { UnauthorizedError } from "errors/ApplicationErrors"
import { useNavigate } from "react-router-dom"
import { DialogUtil } from "utils/DialogUtil"
import { features } from "process"
import { Feature } from "models/Feature"
import PlanOptions from "./PlanOptions"



export enum SubscriptionType{
    License,
    Firm
}

const Plans = (props: any) => {
    const {type, ...rest} = props;
    const planType: SubscriptionType = type ?? SubscriptionType.License;
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);
    const plansClient = new PlansClient();
    const images = prodInfoService.getMedia();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isBusy = UseBusyIndicatorStore();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const showFirmPlans = planType === SubscriptionType.Firm;
    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [plans, setPlans] = useState<Plan[]>([]);
    const [priceMap, setPriceMap] = useState<Map<string,Price[]>>();
    const [loading, setLoading] = useState<boolean>(false);
    const dialogUtils = new DialogUtil();
    const navigate = useNavigate();


    useEffect(() => {
        setPlansAsync();
    }, []);

    const setPlansAsync = async() => {
        setLoading(true);
        BusyUpdate(true);
        var plans = await plansClient.getPlansAsync(showFirmPlans);
        setPlans(plans);
        BusyUpdate(false);
        setLoading(false);    
    }

    const title = showFirmPlans ? "Plans" : "Pricing"
    const subtitle = showFirmPlans ? "Choose a plan that meets your needs" : "Get full access with the license below"   
    return(
        <PlanOptions key={plans}
            title={title} 
            subtitle={subtitle}
            products={plans}/>
    )
}


export default Plans