import {IconButton, Snackbar } from '@mui/material'
import Icon from 'components/icons/Icon'
import React, { useState } from 'react'
import { CommonUtil } from 'utils/CommonUtil'
import { UseToastStore, Update as ToastUpdate } from 'stores/ToastStore';
import { Toast } from 'stores/models/Toast';
import Dialog from 'components/modals/Dialog';
import LoadingButton from './LoadingButton';
import Form from 'components/forms/Form';
import FieldCollection from 'components/fields/fieldCollection/FieldCollection';
import TextField from 'components/fields/textField/TextField';
import Button from './Button';
import { Bootstrapper } from 'Bootstrapper';
import { UserService } from 'services/UserService';
import { InlineWidget, PopupButton } from 'react-calendly';
import { Link } from 'react-router-dom';





const RequestDemoButton = (props: any) => {
    // const {content, ...rest} = props
    const userService = Bootstrapper.getInstance<UserService>(UserService);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [validationMsg, setValidationMsg] = useState<string>();
    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();


    const onDialogClose = () => {
      setValidationMsg('');
      setName('');
      setEmail('');
      setIsOpen(false);
    }

    const handleClick = () => {
      setIsOpen(true);
    }

    // const onSubmitRequestAsync = async() => {
    //   setCanSubmit(false);
    //   var result = await userService.requestDemoAsync(name, email).catch((err: Error) => {
    //     setValidationMsg(err.message);
    //   })
    //   setCanSubmit(true);

    //   if(result){
    //     onDialogClose();
    //   }
    // }

    return (
      <>
        <Button component={Link} to={'/demo'} variant='outlined'>
          Request Demo
        </Button>
      </>
    )
    
    // return (
    //     <>
    //       <Button variant='outlined' onClick={handleClick}>
    //         Request Demo
    //       </Button>
    //       {isOpen && <Dialog 
    //                 action={<LoadingButton loading={!canSubmit} disabled={!canSubmit} onClick={() => onSubmitRequestAsync()}>Submit</LoadingButton>}
    //                 title="Request Demo" 
    //                 maxWidth="xs" 
    //                 open={isOpen} 
    //                 onClose={() => onDialogClose()}>
    //                         <Form 
    //                             flat 
    //                             validationMsg={validationMsg || ''}
    //                             >
    //                             <FieldCollection>
    //                                 <TextField required value={name || ''} onChange={(ele: any) => setName(ele.target.value)} label="Name" {...props} />
    //                                 <TextField required value={email || ''} onChange={(ele: any) => setEmail(ele.target.value)} label="Email" {...props} />
    //                             </FieldCollection>
    //                         </Form>                    
    //                         </Dialog>}
        
    //     </>
    // )
}

export default RequestDemoButton