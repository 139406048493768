import { MutableRefObject, useEffect, useRef, useState } from 'react'




const isInViewport = (ele: HTMLElement, offset: number): boolean => {
  const eleRect = ele.getBoundingClientRect();
  const top = eleRect.top
  const height = eleRect.height;
  const pixelsDisplayedThreshold = offset * height;
  const withinLowerBound = top + pixelsDisplayedThreshold <= window.innerHeight; 
  const withinUpperBound = top + (height - pixelsDisplayedThreshold) >= 0;

  const result = withinLowerBound && withinUpperBound;
  //if(log)
    //console.log("evanTest1", result, withinLowerBound, withinUpperBound);
  return result;
}


/**
 * Check if an element is in viewport
 * @param {number} offset - Number of pixels up to the observable element from the top
 */
export default function useVisibility<T extends HTMLElement | null>(
  onlyNotifyOnce: boolean = true,
  callback: (isVisible: boolean) => void = () => false,
  offset: number = 0,
): [boolean, (ele: T) => void] {
  const [notifyOnFirstAppearanceOnly, setNotifyOnFirstAppearanceOnly] = useState<boolean>(onlyNotifyOnce);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [currentElement, setCurrentElement] = useState<HTMLElement | null>(null);
  //var currentElement = useRef<T>();
  
  const setRef = (ele: T): void => {
    if(ele != null && currentElement !== ele){
      setCurrentElement(ele);
      updateVsibility(ele!)
    }
  }

  const updateVsibility = (ele: HTMLElement): void => {
    const isVisibleVal = isInViewport(ele!, offset)

    if((notifyOnFirstAppearanceOnly && isVisible) || (isVisibleVal === isVisible))
      return;
    if(notifyOnFirstAppearanceOnly)
      setNotifyOnFirstAppearanceOnly(true);
      
    callback(isVisibleVal);
    setIsVisible(isVisibleVal);

  }

  const onScroll = () => {
    if(currentElement == null){
      return;
    }
    updateVsibility(currentElement);
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  })

  return [isVisible, setRef]
}