import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionProps, AccordionSummary as MuiAccordionSummary, AccordionSummaryProps, Icon, Typography} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const Accordion = (props: any) => {
    const {isItem, expanded, hidden, sx, ...rest} = props;
    const isItemStyle = isItem ?? true;
    var itemStyle = {
        // '&:not(:last-child)': {
        //     borderBottom: 0,
        // },
        // '&:before': {
        //     display: 'none',
        // }
    }
    var style = {
        width: '100%',
        //backgroundColor: "black"
        //border: `0.5px solid ${theme.palette.divider}`,
        ...sx,
    }
    var mergedStyle = Object.assign(style, (isItemStyle ? itemStyle : {width: '100%'}));
    var finalStyle = hidden ? {display: 'none'} : mergedStyle; 
    return(
        <MuiAccordion expanded={expanded} sx={finalStyle} elevation={1} square {...rest} />
    )
}



export const AccordionSummary = (props: AccordionSummaryProps) => {
    var style = {
        //backgroundColor:
        // theme.palette.mode === 'dark'
        // ? 'rgba(255, 255, 255, .05)'
        // : 'rgba(0, 0, 0, .03)',
        // '& .MuiAccordionSummary-content': {margin: 0},
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: `rotate(${90}deg)`,
          },
    }

    return (
        <MuiAccordionSummary sx={style} 
        {...props}
      />
    )
}
    

export const AccordionDetails = (props: any) => {
    const {disablePadding, ...rest} = props;

    var style = {
        // padding: disablePadding ? '0px' : theme.spacing(2),
        // borderTop: '1px solid rgba(0, 0, 0, .125)',    
    }
    return (
        <MuiAccordionDetails sx={style} {...rest}/>
    )
  }
