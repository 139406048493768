import { Avatar, Button, Divider, IconButton, Stack } from "@mui/material";
import Icon from "components/icons/Icon";
import Box from "components/layout/box/Box";
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { MyTheme } from "MyTheme";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate  } from 'react-router-dom';
import { TenantService } from "services/TenantService";
import { UserService } from "services/UserService";
import { UseActiveUserIdStore, Subscription as ActiveUserIdSubscription } from "stores/ActiveUserIdStore";
import {UseActiveUserOrganizationIdStore, Subscription as OrganizationIdSubscription, Update as UpdateOrganizationId} from 'stores/ActiveUserOrganizationsStore';
import LoginMenu from "./LoginMenu";
import OrganizationsButton from "./OrganizationsButton";



const AuthenticationAvatar = (props: any) => {
    // const {isAuthenticated, ...rest} = props;
    const userService = new UserService();
    const tenantService = new TenantService();

    const userId = UseActiveUserIdStore();
    const [isAdmin, setIsAdmin] = useState<boolean>();
    const [letter, setLetter] = useState<string>();
    //const activeUserId = UseActiveUserIdStoreStore();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(userId != null);
    const navigate = useNavigate();

    const theme = MyTheme();


    useEffect(() => {
        ActiveUserIdSubscription(val => {
            setActiveUser(val != null);
            setIsAdmin(userService.isAdmin());
            //onActiveUserChangeAsync(val);
        })
    }, []);


    
    const AvatarMenu = (props: any) => {
        const userOrgs = UseActiveUserOrganizationIdStore();
        const userHasOrgs = userOrgs.length > 0;
        const navigate = useNavigate();
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        
        
        const menuClicked = (event: any) => {
            setAnchorEl(event.currentTarget);
            event.stopPropagation()
        };

        const menuItemClicked = (e: any, route: string) => {
            navigate(route); 
            handleClose(e);
        }
        
        const handleClose = (e: any) => {
            setAnchorEl(null);
            e.stopPropagation();
        };
        return(
            <>
            <IconButton onClick={(e: any) => menuClicked(e)}>
                <Avatar sx={{fontWeight: '500', color: theme.palette.primary.main, backgroundColor: theme.palette.common.white}}>{letter}</Avatar>
            </IconButton>
            
                <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={(e: any) => handleClose(e)}
                >

                {isAdmin && <MenuItem onClick={(e: any) => menuItemClicked(e, "/admin")}>
                    Admin
                </MenuItem>}


                <MenuItem onClick={(e: any) => menuItemClicked(e, "/account")}>
                    Account
                </MenuItem>
        
                {userHasOrgs && <OrganizationsButton component={MenuItem} />}
                
                <MenuItem onClick={(e: any) => menuItemClicked(e, "/logout")}>
                    Logout    
                </MenuItem>
        
                </Menu>
            </>
        )
    
    }
    

    
    const setActiveUser = (isAuthenticated: boolean) => {
        //var isAuthenticated  = userService.isAuthenticated();
        // var isAuthenticated = activeUserId != null;
        
        setIsAuthenticated(isAuthenticated);
        if(!isAuthenticated)
            return;

        var username = userService.getActiveUserName().toUpperCase();
        setLetter(username[0])        
        setIsAdmin(userService.isAdmin());

    }

    const handleLoginClick = (route: string) => {
        navigate(route);
    }



    return(
        <Box>
            {isAuthenticated && <AvatarMenu {...props}/>}
            {!isAuthenticated && 
                      <Stack
                      alignItems='center'
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                {/* <IconButton component={Link} to={'/login'} color="inherit">
                    <Icon small color='white'>account_circle</Icon>
                </IconButton> */}

                <Button id='btnDrop'
                    endIcon={<LoginMenu handleClick={(route: string) => handleLoginClick(route)} eleId={'btnDrop'} />} onClick={() => handleLoginClick('login')} color="inherit">Login</Button>
                                
                {/* <Button component={Link} to={'/register'} color="inherit">Free Trial</Button> */}
                </Stack>

            }
        </Box>
    )


}

export default AuthenticationAvatar;