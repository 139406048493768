
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"
import { Tenant } from "models/Tenant"
import { BadRequestError } from "errors/ApplicationErrors"
import { Bootstrapper } from "Bootstrapper"
import LoadingButton from "components/buttons/LoadingButton"
import { DialogUtil } from "utils/DialogUtil"
import Article, { Exhibit, Icon2, Section2, Stack2, Subtitle2, Text2 } from "../../Article"
import InfoLink, { InfoType } from "views/articles/shared/InfoLink"
import SimpleGrid, { SimpleContent } from "views/articles/shared/simpleGrid/SimpleGrid"
// import toolbarImg from "../media/exhibit-pane-toolbar.PNG";
const toolbarImg = require('../../media/exhibit-pane-toolbar.PNG').default;
const ExhibitSortArticle = (props: any) => {
    const navigate = useNavigate();
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);

    const userService = new UserService();
    const tenantService = new TenantService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dialogUtils = new DialogUtil();

    const [orgPermissionMap, setOrgPermissionMap] = useState<Map<Tenant, boolean>>(new Map<Tenant, boolean>());
    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [accessKey, setAccessKey] = useState<string>("");
    const [organizations, setOrganizations] = useState<Tenant[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const userId = userService.getActiveUserId()
    const location = useLocation();
    // const images = prodInfoService.getMedia();

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    useEffect(() => {
    }, []);




    return(
        <Article 
            title="Arrange your exhibits to your liking"
            subtitle={null}>
                <Section2>
                    <Stack2>
                        <Text2>The order of your exhibits determines the label that appears when referencing an exhibit. For example, when referring to your exhibits, the first of the collection will appear as <Exhibit num={1} /> while the second will appear as <Exhibit num={2} />.</Text2>
                        <Text2>The order of your exhibits is also used when inserting a table-of-contents. In this article we will discuss how you can arrage your exhibits in any desired order.</Text2>
                    </Stack2>
                </Section2>
                <Section2>
                    <SimpleGrid>
                        <SimpleContent>
                        <Stack2>
                                <Text2>Click the <Icon2>sort</Icon2> icon from the toolbar of the <InfoLink type={InfoType.ExhibitsPane}/> to manage the order of your exhibits.</Text2>

                                    <Stack2>
                                        <Subtitle2>Auto-Sort</Subtitle2>
                                        <Text2>This option is useful when you're referring to exhibits throughout your document. This option will automatically update the order of your exhibits based on where they appear in the document.</Text2>
                                        <Text2>This allows the sequence of your exhibits to always appear in the correct order as you update your document.</Text2>
                                        <Text2>For example, if you are preparing an affidavit and you insert a reference to an exhibit in the first paragraph, it will show as <Exhibit num={1} /> because your exhibits will have beeen re-ordered automatically so that the referenced exhibit appears first in the collection.</Text2>
                                    </Stack2>
                                    <Stack2>
                                        <Subtitle2>Fixed</Subtitle2>
                                        <Text2>The remaining options will keep the order of your exhibits fixed regardless of how you update your document.</Text2>
                                        <Text2>You can sort based on the filenames (<strong>Alphabetical</strong> or <strong>Reverse Alphabetical</strong>) or you can select <strong>Custom</strong> to arrage the exhibits in any order.</Text2>
                                    </Stack2>
                                </Stack2>
                        </SimpleContent>
                        <SimpleContent>
                            <Stack2>
                                <img src={toolbarImg} />
                            </Stack2>

                        </SimpleContent>
                    </SimpleGrid>
                </Section2>

        </Article>
    )


}


export default ExhibitSortArticle