import { createTheme, PaletteColor, PaletteMode, Theme } from '@mui/material';
import { blue, purple, deepPurple, green, lightGreen, teal, lightBlue, cyan } from '@mui/material/colors';

const primaryColor2: PaletteColor = {
    main: "#00936f",
    light: "#4fc49d",
    dark: '#006444',
    contrastText: '#ffffffcc'
  }

const primaryColor: PaletteColor = {
  main: "#035762d9",
  light: "#41848f",
  dark: '#002e38',
  contrastText: '#ffffffcc'
}

// const secondaryColor: PaletteColor = {
//     main: "#774ea2e0",
//     light: "774ea2e0",
//     dark: '#774ea2e0',
//     contrastText: '#774ea2e0'
// }

export const MyTheme = (mode: PaletteMode = 'light') => {
    const theme = createTheme({
        palette: {
          mode: mode,
          primary: primaryColor,
          secondary: green
        }

      });
    return theme;
}

export type ColourType = "ThemePrimary" | "White" | "Gray" | 'warning';

export const ColorsUtil = (theme: Theme, type: ColourType): string => {
    switch(type){
        case 'warning':
          return theme.palette.warning.main;
        case 'ThemePrimary':
            return theme.palette.primary.main;
        case 'Gray':
            return theme.palette.grey[200];
    }
       
    return "white";
}