import * as React from 'react';
import {AppBar as MuiAppBar, Avatar, Collapse, Color, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Stack, ThemeProvider, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { MyTheme } from 'MyTheme';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Bootstrapper } from 'Bootstrapper';
import Button from 'components/buttons/Button';
import ExpansionPanel from 'components/transitions/expansionPanel/ExpansionPanel';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


const ExpandableListItem = (props: any) => {
  const {children, nestedListProps, title, ...rest} = props;
    const [open, setOpen] = React.useState<boolean>(false);
  
    const handleClick = (e: any) => {
        setOpen(!open);
    };
  
    return (
      <Box>
        <ListItemButton onClick={handleClick} {...rest}>
            <Box width="100%" display='flex' justifyContent='space-between'>

            {title}
              {open ? <ExpandLess /> : <ExpandMore />}
              </Box>
          </ListItemButton>

          <Collapse in={open}>
              {children}

            </Collapse>

          </Box>
    );
    
}

export default ExpandableListItem;