import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionProps, AccordionSummary as MuiAccordionSummary, AccordionSummaryProps, Icon, Typography} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Accordion, AccordionDetails, AccordionSummary } from '../accordion/AccordionBase';



const ExpansionPanel = (props: any) => {
    const {title, children, disablePadding, expanded, ...rest} = props;
    const [isExpanded, setExpanded] = React.useState<boolean>(expanded || false);
    const titleHtml = typeof title === 'string' || title instanceof String ? <Typography>{title}</Typography> : title;

    return(
        <Accordion {...rest} expanded={isExpanded} onChange={() => setExpanded(!isExpanded)}>
          <AccordionSummary expandIcon={<ChevronRightIcon />}>
            {titleHtml}
          </AccordionSummary>
          <AccordionDetails disablePadding={disablePadding}>
              {children}
          </AccordionDetails>
      </Accordion>
    )
} 

export default ExpansionPanel;