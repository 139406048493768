import * as React from 'react';
import {Card as MuiCard, CardActions, CardContent, CardHeader, Chip, Divider, List, ListItem, ListItemAvatar, ListItemText, Paper} from '@mui/material';
import SubHeading from 'components/labels/SubHeading';
import Text from 'components/labels/Text';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Icon from 'components/icons/Icon';
import { Plan, PlanType } from 'models/Plan';
import Button from 'components/buttons/Button';
import Box from 'components/layout/box/Box';
import { Price } from 'models/Price';
import DownloadsButton from 'views/downloads/DownloadsButton';

const CardPlan = (props: any) => {
    const {plan, checkoutHandler, ...rest} = props;
    const p: Plan = plan;
    const isFreePlan = p?.type === PlanType.Free;
    const price: Price = p?.prices?.length > 0 ? p.prices[0] : null; 
    const priceLabel = isFreePlan ? "$0" : price?.description ?? "";
    return (
        <Box height='100%' textAlign='center'>
            <MuiCard {...rest}>
                <Box margin={0}>
                    <Paper elevation={0}>

                        <Box p={2}>
                            <Chip variant="outlined" color="primary" label={p.name}/>
                            <SubHeading m={2}>{priceLabel}</SubHeading>
                            {!isFreePlan && <Button onClick={()=>checkoutHandler(price.id)} fullWidth={false}>Buy now</Button>}
                            {isFreePlan && <DownloadsButton hideDetailsLink />}
                        </Box>
                    </Paper>
                </Box>

                <Divider variant="middle" />

                <CardContent>
                    <Text mb={2} align='left'>{p?.description}</Text>
                    <List  disablePadding>
                        
                        {p.features.map((f, i) => {
                            return(
                                f.include && <ListItem disablePadding key={i} alignItems='flex-start'>
                                    <ListItemAvatar sx={{marginTop: 0}}>
                                        <Icon>check</Icon>

                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={f.label}
                                        >
                                    </ListItemText>
                                </ListItem>
                            )
    
                        })}
                    </List>
                </CardContent>
                <CardActions>
                </CardActions>
            </MuiCard>
        

        </Box>
    ) 
}

export default CardPlan;