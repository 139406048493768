import { FreeTrialStatus } from './../models/FreeTrialStatus';
import { PermissionRequest } from './../models/PermissionRequest';
import { PermissionTicket } from './../models/PermissionTicket';
import { User } from './../models/User';
import { Tenant } from './../models/Tenant';
import { AuthTokenInfo } from './../models/AuthTokenInfo'; 


import { Installer } from 'models/Installer';
import { Invitation } from 'models/Invitation';
import isDev from '../MySettings';
import axios from 'axios';
import { singleton } from 'tsyringe';
import { MembershipInfo } from 'models/MembershipInfo';

@singleton()
export class IdentityClient {
    private baseUrlDev: string = "https://localhost:5001";
    private baseUrlProd: string = "https://zibbit-identity.azurewebsites.net";
    private baseUrl: string;
  
    constructor() {

        this.baseUrl = isDev() ? this.baseUrlDev : this.baseUrlProd;
    }

    public get refreshUrl(): string{
        return this.baseUrl + "/api/token/refresh";

    } 

    public refreshAsync(info: AuthTokenInfo): Promise<any> {
        var url = this.baseUrl + "/api/token/refresh";
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", info.accessToken)
        bodyFormData.append("refreshToken", info.refreshToken)

        return axios.post(url, bodyFormData)
        .then(function (response) {
            var info: AuthTokenInfo = response.data;
            return info;

        })
    }

    public async getPermissionTicketAsync(request: PermissionRequest): Promise<PermissionTicket> {
        var url = this.baseUrl + `/api/resource/permission-ticket`;
        return axios.post(url, request)
        .then(function (response) {
            var ticket: PermissionTicket = response.data;
            return ticket;
        })
    }

    public async userConfirmationAsync(link: string): Promise<string> {
        var url = this.baseUrl + `/api/user/user-confirmation/`;
        var bodyFormData = new FormData();
        bodyFormData.append("link", link);
        return axios.post(url, bodyFormData)
        .then(function (response) {
            var redirect = response.data;
            return redirect;
        });

    }

    public async getUsersByOrgAsync(orgId: string): Promise<User[]> {
        var url = this.baseUrl + `/api/user/tenant/${orgId}`;
        return axios.get(url)
        .then(function (response) {
            var users: User[] = response.data;
            return users;
        })
    }

    public async getOrganizationOwnerIdAsync(tenantId: string): Promise<string> {
        var url = this.baseUrl + `/api/tenant/${tenantId}/ownerId`;
        return axios.get(url)
        .then(function (response) {
            var ownerId: string = response.data;
            return ownerId;
        })

    }

    public async getOwnerOrgAsync(): Promise<Tenant> {
        var url = this.baseUrl + `/api/user/tenant`;
        return axios.get(url)
        .then(function (response) {
            var tenant: Tenant = response.data;
            return tenant;
        })
    }

    public async isSubsciptionValidAsync(tenantId: string): Promise<boolean> {
        var url = this.baseUrl + `/api/tenant/${tenantId}/is-subscription-valid`;
        return axios.get(url)
        .then(function (response) {
            var result: boolean = response.data;
            return result;
        })

    }

    public async getInvitesByOrgAsync(orgId: string): Promise<Invitation[]> {
        var url = this.baseUrl + `/api/invite/${orgId}`;
        return axios.get(url)
        .then(function (response) {
            var users: Invitation[] = response.data;
            return users;
        })
    }

    public async cancelInviteAsync(inviteId: string): Promise<boolean> {
        var url = this.baseUrl + `/api/invite/${inviteId}/cancel`;
        return axios.post(url)
        .then(function (response) {
            return response.status === 200;
        })
    }


    public async extendInviteAsync(inviteId: string, daysExtended: number, sendEmail: boolean): Promise<boolean> {
        var url = this.baseUrl + `/api/invite/${inviteId}/extend`;
        var bodyFormData = new FormData();
        bodyFormData.append("daysExtended", daysExtended.toString());
        bodyFormData.append("sendReminder", String(sendEmail));
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status === 200;
        })
    }



    public updateOrganizationDetailsAsync(org: Tenant): Promise<any> {
        var url = this.baseUrl + `/api/tenant/edit/${org.id}`;
        return axios.post(url, org)
        .then(function (response) {
            return response.status === 200;
        })
    }

    public newOrganizationRegKeyAsync(orgId: string): Promise<string> {
        var url = this.baseUrl + `/api/tenant/${orgId}/regKey/new`;
        return axios.get(url)
        .then(function (response) {
            var regKey: string = response.data;
            return regKey;
        })
    }

    public getOrganizationRegKeyAsync(orgId: string): Promise<string> {
        var url = this.baseUrl + `/api/tenant/${orgId}/regKey`;
        return axios.get(url)
        .then(function (response) {
            var regKey: string = response.data;
            return regKey;
        })
    }


    public getOrganizationMembershipInfoAsync(orgId: string): Promise<MembershipInfo[]> {
        var url = this.baseUrl + `/api/tenant/membership-info/${orgId}`;
        return axios.get(url)
        .then(function (response) {
            var tenant: MembershipInfo[] = response.data;
            return tenant;
        })
    }


    public getOrganizationAsync(orgId: string): Promise<Tenant> {
        var url = this.baseUrl + `/api/tenant/${orgId}`;
        return axios.get(url)
        .then(function (response) {
            var tenant: Tenant = response.data;
            return tenant;
        })
    }
    

    public getOrganizationsAsync(): Promise<Tenant[]> {
        var url = this.baseUrl + `/api/tenant/all`;
        return axios.get(url)
        .then(function (response) {
            var tenants: Tenant[] = response.data;
            return tenants;
        })
    }

    public getOrganizationsByUserAsync(userId: string): Promise<Tenant[]> {
        var url = this.baseUrl + `/api/tenant/access-granted/${userId}`;
        return axios.get(url)
        .then(function (response) {
            var tenants: Tenant[] = response.data;
            return tenants;
            //return checkoutUrl;
        })
    }


    public updateOrganizationMembersDeactivatedStatusAsync(orgId: string, activatedUserIds: string[]): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("tenantId", orgId);
        activatedUserIds.forEach(id => {
            bodyFormData.append("activatedUserIds", id);
        })
        var url = this.baseUrl + "/api/tenant/update-deactivated-status";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })
    }


    public reactivateOrganizationMemberAsync(orgId: string, userId: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("tenantId", orgId);
        bodyFormData.append("userId", userId);

        var url = this.baseUrl + "/api/tenant/reactivate-member";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })
    }


    public revokeAccessToOrganizationAsync(orgId: string, userId: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("tenantId", orgId);
        bodyFormData.append("userId", userId);
        var url = this.baseUrl + "/api/tenant/revoke-access";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
            //return checkoutUrl;
        })
    }

    public requestAccessToOrganizationAsync(regKey: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("regKey", regKey);
        var url = this.baseUrl + "/api/tenant/request-access";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
            //return checkoutUrl;
        })
    }

    public cancelTenantMembershipAsync(tenantId: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("tenantId", tenantId);
        var url = this.baseUrl + "/api/tenant/cancel-access";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
            //return checkoutUrl;
        })
        
    }
    
    public registerOrganizationAsync(orgName: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("tenantName", orgName)
        var url = this.baseUrl + "/api/tenant/register";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })
    }

    public replaceOrganizationOwnerAsync(orgId: string, userId: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("tenantId", orgId);
        bodyFormData.append("userId", userId);
        var url = this.baseUrl + "/api/tenant/replace-owner";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })

    }


    public startOrganizationPilotAsync(orgName: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("tenantId", orgName)
        var url = this.baseUrl + "/api/tenant/start-pilot";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })
    }

    public cancelOrganizationPilotAsync(orgName: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("tenantId", orgName)
        var url = this.baseUrl + "/api/tenant/cancel-pilot";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })
    }

    public getUserAsync(userId: string): Promise<User> {
        var url = this.baseUrl + `/api/user/${userId}`;
        return axios.get(url)
        .then(function (response) {
            var user: User = response.data;
            return user;
        })
    }

    public getUsersAsync(): Promise<User[]> {
        var url = this.baseUrl + `/api/user/all`;
        return axios.get(url)
        .then(function (response) {
            var users: User[] = response.data;
            return users;
        })
    }

    public registerAdminAsync(username: string, password: string, adminKey: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("username", username)
        bodyFormData.append("password", password)
        bodyFormData.append("adminKey", adminKey)
        var url = this.baseUrl + "/api/user/register-admin";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
            //return checkoutUrl;
        })
    }

    public registerAsync(username: string, password: string, regKey: string = ""): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("username", username)
        bodyFormData.append("password", password)
        bodyFormData.append("registrationKey", regKey)
        var url = this.baseUrl + "/api/user/register";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })
    }

    public deleteOrganizationAsync(orgId: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("tenantId", orgId);
        var url = this.baseUrl + "/api/tenant/delete";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
            //return checkoutUrl;
        })
    }

    public deleteAccountAsync(userId: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("userId", userId);
        var url = this.baseUrl + "/api/user/delete";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
            //return checkoutUrl;
        })
    }

    public sendNewUserInvitationAsync(email: string, role: string, trialDays: number): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("inviteeEmail", email);
        bodyFormData.append("role", role);
        if(trialDays)
            bodyFormData.append("trialPeriodDays", trialDays.toString());
        
            var url = this.baseUrl + "/api/invite/send-new-user-invitation";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })
        
    }

    public sendTenantMembershipInvitationAsync(emails: string[], tenantId: string, role: string): Promise<string[]> {
        var bodyFormData = new FormData();
        emails.forEach(e => {
            bodyFormData.append("inviteeEmails", e);
        })
        bodyFormData.append("tenantId", tenantId);
        bodyFormData.append("role", role);
        var url = this.baseUrl + "/api/invite/send-tenant-membership-invitation";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            var failedEmails: string[] = response.data;
            return failedEmails;
        })
        
    }



    public changePasswordAsync(password: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("password", password)
        var url = this.baseUrl + "/api/user/change-password";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
            //return checkoutUrl;
        })
    }

    public updatePasswordAsync(userId: string, password: string, token: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("userId", userId)
        bodyFormData.append("newPassword", password)
        bodyFormData.append("token", token)

        var url = this.baseUrl + "/api/user/update-password";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
            //return checkoutUrl;
        })
    }

    public sendPasswordResetLinkAsync(email: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("email", email)
        var url = this.baseUrl + "/api/user/password-reset-link";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
            //return checkoutUrl;
        })
    }


    public forceResetEmailLinkAsync(userId: string, newEmail: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("userId", userId);
        bodyFormData.append("newEmail", newEmail);
        var url = this.baseUrl + "/api/user/force-reset-email";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })
    }


    public sendEmailUpdateRequestLinkAsync(newEmail: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("newEmail", newEmail);
        var url = this.baseUrl + "/api/user/email-update-request";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })
    }

    public confirmEmailUpdateRequestAsync(token: string, newEmail: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("token", token);
        bodyFormData.append("newEmail", newEmail);
        var url = this.baseUrl + "/api/user/email-update-request-confirmation";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })
    }

    public updateEmailAsync(token: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("token", token);
        var url = this.baseUrl + "/api/user/update-email";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })
    }

    public updateProfileAsync(firstname: string, lastname: string): Promise<boolean> {
        var bodyFormData = new FormData();
        bodyFormData.append("firstname", firstname || "")
        bodyFormData.append("lastname", lastname || "")

        var url = this.baseUrl + "/api/user/update-profile";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })
    }

    public hasFeaturesEnabledAsync(): Promise<boolean>{
        var url = this.baseUrl + "/api/user/has-features-enabled";
        return axios.get(url)
        .then(function (response) {
            var isActive: boolean = response.data;
            return isActive;
        })

    }


    public activateFreeTrailAsync(): Promise<boolean>{
        var url = this.baseUrl + "/api/user/activate-free-trial";
        return axios.post(url)
        .then(function (response) {
            return response.status == 200;
            //return checkoutUrl;
        })

    }



    public requestDemoAsync(name: string, email: string): Promise<boolean>{
        var bodyFormData = new FormData();
        bodyFormData.append("name", name);
        bodyFormData.append("email", email);
        var url = this.baseUrl + "/api/user/request-demo";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })

    }

    public contactUsAsync(name: string, email: string, description: string): Promise<boolean>{
        var bodyFormData = new FormData();
        bodyFormData.append("name", name);
        bodyFormData.append("email", email);
        bodyFormData.append('msg', description);
        var url = this.baseUrl + "/api/user/contact-us";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })

    }

    public extendFreeTrailAsync(userId: string, daysToExtend: number = null): Promise<boolean>{
        var bodyFormData = new FormData();
        bodyFormData.append("userId", userId);
        if(daysToExtend){
            bodyFormData.append("daysToExtend", daysToExtend.toString());
        }
        var url = this.baseUrl + "/api/user/extend-free-trial";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            return response.status == 200;
        })

    }

    public getFreeTrailStatusAsync(): Promise<FreeTrialStatus>{
        var url = this.baseUrl + "/api/user/get-free-trial-status";
        return axios.get(url)
        .then(function (response) {
            var isActive: FreeTrialStatus = response.data;
            return isActive;
        })

    }

    public sendEmailConfirmationLinkAsync(): Promise<boolean> {
        // var bodyFormData = new FormData();
        // bodyFormData.append("email", email)
        var url = this.baseUrl + "/api/user/resend-confirmation-link";
        return axios.post(url)
        .then(function (response) {
            return response.status == 200;
            //return checkoutUrl;
        })
    }

    public getAuthTokenInfoAsync(username: string, password: string): Promise<AuthTokenInfo> {
        var bodyFormData = new FormData();
        bodyFormData.append("username", username)
        bodyFormData.append("password", password)
        var url = this.baseUrl + "/api/authentication/login";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            var info: AuthTokenInfo = response.data;
            return info;
        });
    }


    public refreshAuthAsync(authInfo: AuthTokenInfo): Promise<AuthTokenInfo> {
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", authInfo.accessToken)
        bodyFormData.append("refreshToken", authInfo.refreshToken)
        var url = this.baseUrl + "/api/token/refresh";
        return axios.post(url, bodyFormData)
        .then(function (response) {
            var info = response.data;
            return info;
        })
    }

    public logoutAsync(): Promise<any> {
        var url = this.baseUrl + "/api/authentication/logout";
        return axios.get(url)
        .then(function (response) {
        })
    }

    public getLatestInstallerAsync(): Promise<Installer>{
        var url = this.baseUrl + "/download-latest";
        return axios.get(url)
        .then((response) => {
            var info: Installer = response.data;
            return info;
        })
    }

    public getBetaInstallerAsync(): Promise<Installer>{
        var url = this.baseUrl + "/download-beta";
        return axios.get(url)
        .then((response) => {
            var info: Installer = response.data;
            return info;
        })
    }
}