import { BusyIndicator } from 'stores/models/BusyIndicator';
import { StoreBase, UseStore } from './StoreBase';

const store = new StoreBase<BusyIndicator>(new BusyIndicator(false));

export const GetState = () => store.state;
export const Update = (val: boolean, label: string = null, progressValue: number = null) => {
    if(val === null)
        val = store.state.isBusy;
    
    store.update(new BusyIndicator(val, label, progressValue))
}
export const Subscription = (fn: (state: BusyIndicator) => any) => {
    store.subscription(fn)
}
export function UseBusyIndicatorStore(){
    let result = UseStore(store);
    return result;
}
