import * as React from 'react';
import {Select as MuiSelect} from '@mui/material';

const Select = (props: any) => {
  const {isReadOnly, ...rest} = props 
  var variant = props.isReadOnly ? "standard" : "outlined";
  return (
      <MuiSelect notched={false} variant={variant} size="small" {...rest}></MuiSelect>
  );
}
export default Select;