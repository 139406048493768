
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { UserService } from "services/UserService"

import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import Link from "components/buttons/Link"
import LoadingButton from "components/buttons/LoadingButton"
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"

class Prerequisite {
    constructor(id: string, label: string, link: string){
        this.id = id;
        this.label = label;
        this.link = link;
    }
    id: string;
    label: string;
    link: string;
}

const prereqs = {
    vsto: new Prerequisite('vsto', 'Visual Studio 2010 Tools for Office Runtime', 'https://www.microsoft.com/en-us/download/details.aspx?id=105522'),
    webview2: new Prerequisite('webview2','Microsoft Edge WebView2 Runtime', 'https://developer.microsoft.com/en-us/microsoft-edge/webview2/#download'),
}


const Prerequisites = (props: any) => {
    const {success, ...rest} = props;

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const userService = new UserService();
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);

    const images = prodInfoService.getMedia();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [validationMsg, setValidationMsg] = useState<string>();
    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [msg, setMsg] = useState<string>();
    // const [hasRequirementsKeyParam, setHasRequirementsKeyParam] = useState<boolean>();
    const [prereqMap, setPrereqMap] = useState<Map<string, boolean>>();

    useEffect(() => {
        const keyParam = searchParams.get("missing");
        const map = new Map<string, boolean>();
        map.set(prereqs.vsto.id, keyParam === prereqs.vsto.id);
        map.set(prereqs.webview2.id, keyParam === prereqs.webview2.id);
        setPrereqMap(map);
        // setHasRequirementsKeyParam(keyParam)
    }, []);


    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }


    const getStyle = (isMissing: boolean) => {
        return isMissing ? {border: `solid 2px ${theme.palette.warning.main}`} : {};
    }

    return(
        <Section sx={{flexGrow: 1}} bgColor={bg2}>
            <Container maxWidth="xs">
                <TitleBlock sx={{justifyContent: 'center'}}>
                    <Title  textAlign="center">Prerequisites</Title>

                    <Text textAlign="center">These prerequisites must be installed to run Zibbit</Text>

                </TitleBlock>

                <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                    <ExpansionPanel sx={getStyle(prereqMap?.get(prereqs.vsto.id))} key={prereqMap?.get(prereqs.vsto.id)} expanded={prereqMap?.get(prereqs.vsto.id)} title={prereqs.vsto.label}>
                        Download from their <Link target="_blank" href={prereqs.vsto.link}>website</Link>
                    </ExpansionPanel>
                    <ExpansionPanel sx={getStyle(prereqMap?.get(prereqs.webview2.id))} key={prereqMap?.get(prereqs.webview2.id)} expanded={prereqMap?.get(prereqs.webview2.id)} title={prereqs.webview2.label}>
                        Download the Evergreen Bootstrapper from their <Link target="_blank" href={prereqs.webview2.link}>website</Link> 
                        {/* Download from the <Button target="_blank" href={prereqs.webview2.link}>Website</Button> */}

                    </ExpansionPanel>

                    </div>
                </Animation>
            </Container>
        </Section>
    )

}


export default Prerequisites