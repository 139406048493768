import * as React from 'react';
import {AppBar as MuiAppBar, Avatar, Divider, Stack} from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { LogoType, ProductInfoService } from 'services/ProductInfoService';
import { ColorsUtil, MyTheme } from 'MyTheme';
import HomeIcon from '@mui/icons-material/Home';
import Grid from 'components/layout/grid/Grid';
import Item from 'components/layout/grid/Item';
import Section from 'components/layout/Section';
import Container from 'components/layout/Container';
import Text from 'components/labels/Text';
import Heading from 'components/labels/Heading';
import SubHeading from 'components/labels/SubHeading';
import Link from 'components/buttons/Link';
import './footer.scss';
import { Bootstrapper } from 'Bootstrapper';
import { Link as NavLink } from 'react-router-dom';


const Footer = (props: any) => {
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);
    const theme = MyTheme();

    const logoUrl = prodInfoService.getLogoUrl(LogoType.WithText);
    const homeIcon = <Box component={NavLink} to={'/'} display='flex' alignItems='center' padding={0} width={'140px'}><img src={logoUrl}/></Box>
  

    const email = prodInfoService.getEmail();
    const currentYear = new Date().getFullYear().toString();

    return (
          <Section id="footer" disablePadding sx={{backgroundColor: theme.palette.primary.dark}}>
            <Container maxWidth="xl">
              <Box pt={6}>
                <Grid 
                  container
                  alignItems="stretch"
                  spacing={2}>
                      <Grid item xs={12} sm={6}>
                          <Item>
                            {homeIcon}
                            <Text mt={1}>Contact us at {email}</Text>
                          </Item>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                          <Item>
                            <SubHeading className="subheading" variant="OVERLINE">PRODUCT</SubHeading>
                            <Stack alignItems="start" direction="column">
                              <Link component={NavLink} to={'/'} color="inherit">Home</Link>
                              <Link component={NavLink} to={'/plans'} color="inherit">Price</Link>
                              <Link component={NavLink} to={'/downloads'} color="inherit">Download</Link>
                              <Link component={NavLink} to={'/contact'} color="inherit">Contact</Link>
                              <Link component={NavLink} to={'/demo'} color="inherit">Request Demo</Link>

                            </Stack>
                          </Item>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                          <Item>
                            <SubHeading className="subheading" variant="OVERLINE">COMPANY</SubHeading>
                            <Stack alignItems="start" direction="column">
                              <Link component={NavLink} to={'/tos-policy'} color="inherit">Terms of Service</Link>
                              <Link component={NavLink} to={'/privacy'} color="inherit">Privacy Policy</Link>
                              <Link component={NavLink} to={'/eula-policy'} color="inherit">License Agreement</Link>
                            </Stack>
                          </Item>
                      </Grid>
                </Grid>
              </Box>
            </Container>
            <Container>
              <Box mt={6}>
                <Text textAlign="center" variant="caption">©{currentYear} 1000224182 ONTARIO INC. All Rights Reserved.</Text>
              </Box>

            </Container>
          </Section>
  );
}

export default Footer;