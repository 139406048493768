import * as React from 'react';
import {Card as MuiCard, CardContent} from '@mui/material';
import { Card as ModelCard } from './models/Card';
import SubHeading from 'components/labels/SubHeading';
import Text from 'components/labels/Text';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Icon from 'components/icons/Icon';

const CardDashboard = (props: any) => {
    const {card, ...rest} = props;
    const c: ModelCard = card;
    return (
        <MuiCard {...rest}>
            <CardContent>
                {c.icon && <Icon bgColor={c.iconBgColor} bgCircle>{c.icon}</Icon>}

                <SubHeading mb={2} variant="h5" component="h4" mt={2}>{c.title}</SubHeading>
                {c.subtitle && <Text>{c.subtitle}</Text>}
                {c.description === typeof String && <Text>{c.description}</Text>}
                {c.description !== typeof String && c.description}
            </CardContent>
        </MuiCard>
    ) 
}

export default CardDashboard;