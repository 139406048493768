import { Subject } from "observable-fns";
import { CommonUtil } from "utils/CommonUtil";


export abstract class ContextBase {
    protected busyCallers: number = 0;
    protected commonUtil = new CommonUtil();
    protected minBusyDurationMS: number = 500;
    protected busyBegin: number;

    protected isBusy = new Subject<boolean>();

    public isBusyEmitter = this.isBusy;
    public reset(){
        this.busyCallers = 0;
    }
    public setBusyIndicator(isVisible: boolean): void {
        if (isVisible) {
            this.busyCallers ++;
            this.isBusyEmitChange(isVisible);
            if(this.busyCallers === 1){
                this.busyBegin = Date.now();
            }
        }
        else {
            this.busyCallers --;
            if(this.busyCallers === 0){
                var elapseTime = Date.now() - this.busyBegin;
                var delay = Math.max(0, this.minBusyDurationMS - elapseTime);
                this.commonUtil.timeoutAsync(delay)
                    .then(() => {
                        if(this.busyCallers === 0)
                            this.isBusyEmitChange(isVisible)
                    });
    
            }
        }
    }

    protected isBusyEmitChange(isBusy: boolean) {
        this.isBusy.next(isBusy);
    }
    
}