import * as React from 'react';
import { CSSProperties } from '@mui/material/styles/createTypography';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import { ColorsUtil } from 'MyTheme';
import { useEffect, useState } from 'react';
import Heading from 'components/labels/Heading';
import SubHeading from 'components/labels/SubHeading';
import useVisibility from 'hooks/UseVisibility';



const getCurretDeviceSize = (isMobile: boolean, isTablet: boolean): string => {
    if(isMobile)
        return "mobile";
    if(isTablet)
        return "tablet";
    return "desktop";
}

const buildHeader = (title: string, subtitle: string): JSX.Element => {
    return(
        <>
        {title && <Heading>{title}</Heading>}
        {subtitle && <SubHeading>{subtitle}</SubHeading>}
        </>
    )
}

const Section = (props: any) => {
    const {bgColor, onFirstAppearance, sx, title, subtitle, headerComponent, mobile, children, disablePadding, bgType, ...rest} = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const bg = bgColor ?? ColorsUtil(theme, bgType);
    const [isCompEleVisible, setRef] = useVisibility<HTMLElement | null>(true, onFirstAppearance);


    const responsiveClassName = getCurretDeviceSize(isMobile, isTablet);
    const paddingVal = isMobile ? '50px' : '100px';
    const padding = disablePadding ? 0 : paddingVal;
    const style: CSSProperties = {
        overflow: 'hidden',
        background: bg,
        paddingTop: padding,
        paddingBottom: padding,
        paddingLeft: 0,
        paddingRight: 0,
        ...sx
    }

    return (
    <section ref={r => setRef(r)} style={style} {...rest} >
        <div className={responsiveClassName}>
            {children}
        </div>
    </section>
    )
}

// Section.Header = (props: any) => <div className="page-header" {...props} />
// Section.Content = (props: any) => {
//   const {padding, ...rest} = props;
//   var paddingClass = props.padding ? "padding" : "";
//   return(
//     <div className={`page-content ${paddingClass}`} {...rest}/>
//   )
// }

export default Section;