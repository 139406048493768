import * as React from 'react';
import {Avatar, Box, Icon as MuiIcon, useTheme}  from '@mui/material';
import { CSSProperties } from 'react';
import { ColorsUtil, ColourType, MyTheme } from 'MyTheme';
import './icon.scss';




const Icon = (props: any) => {
    const {sx, normal, color, bgColor, displayText, lightTheme, bgCircle, small, verySmall, size, ...rest} = props;
    // const isListBullet = children == 'circle';
    // const clsName = isListBullet ? 'bullet' : '';
    // console.log(isListBullet, children);

    if(normal){
        var s = {
            
            margin: 0,
            ...sx,
        }
        return <MuiIcon style={s} {...rest} />
    }

    const theme = MyTheme();
    var iColor: ColourType = lightTheme ? 'White' : 'ThemePrimary';
    const backgroundColor: ColourType = bgColor ?? (lightTheme ?  'White' : 'ThemePrimary');
    if(bgCircle){
        iColor = lightTheme ? 'ThemePrimary' : 'White';
    }

    const iconFontSize: number = size ? size : verySmall ? 10 : small ? 24 : 40;
    var style: CSSProperties = {
        color: color ?? ColorsUtil(theme, iColor),
        fontSize: iconFontSize,
        margin: 0,
        ...sx
    }
    if(displayText){
        style.fontFamily = 'inherit';
    }

    if(bgCircle){
        const avatarSize = iconFontSize + 15;
        const avatarStyle: CSSProperties = {
            backgroundColor: ColorsUtil(theme, backgroundColor),
            width: avatarSize,
            height: avatarSize,
            padding: '5px',
            ...sx
        }
        return(
            <Box display='inline-block'>
                <Avatar sx={avatarStyle}>
                    <MuiIcon sx={style} {...rest} />
                </Avatar>
            </Box>

        ) 
    }
    else
        return <MuiIcon sx={style} {...rest} />
}

export default Icon;