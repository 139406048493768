import * as React from 'react';
import {AppBar as MuiAppBar, Avatar, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, ListSubheader, Stack} from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { LogoType, ProductInfoService } from 'services/ProductInfoService';
import { MyTheme } from 'MyTheme';
import HomeIcon from '@mui/icons-material/Home';
import { Link, useNavigate } from 'react-router-dom';
import AuthenticationAvatar from './AuthenticationAvatar';
import { Bootstrapper } from 'Bootstrapper';
import Icon from 'components/icons/Icon';
import { UserService } from 'services/UserService';
import {UseActiveUserOrganizationIdStore, Subscription as OrganizationIdSubscription, Update as UpdateOrganizationId} from 'stores/ActiveUserOrganizationsStore';
import { ThemeProvider } from '@emotion/react';
import OrganizationsButton from './OrganizationsButton';
import DropdownButton from 'components/buttons/DropdownButton';
import MenuItem from 'components/menu/MenuItem';


const Header = (props: any) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);
  const userService = new UserService();
  const ownerOrgId = UseActiveUserOrganizationIdStore();
  const hasOrgs = ownerOrgId.length > 0;
  const theme = MyTheme();
  const logoUrl = prodInfoService.getLogoUrl(LogoType.WithText);
  const homeIcon = <Box component={Link} to={'/'} display='flex' alignItems='center' padding={0} width={'140px'}><img src={logoUrl}/></Box>
  const navigate = useNavigate();
  const logoBtnStyle = {
    color: 'black', 
    bgcolor: 'white', 
    lineHeight: 0, 
    textTransform: 'none', 
    fontSize: '22px',
    "&.MuiButtonBase-root:hover": {
      bgcolor: "white"
    }
  }

  const handleMobileDropdownItemClick = (path: string) => {
    setOpen(false);
    navigate(path);
  }

  return (
    <Box sx={{ flexGrow: 0 }}>
      <MuiAppBar position="relative">
        <Toolbar  sx={{justifyContent: 'space-between', display: { xs: 'flex', md: 'none' }}}>
          <Stack direction="row" spacing={1}>
            {homeIcon}
          </Stack>
          <Stack direction="row" spacing={0}>
            {userService.isAuthenticated() && <AuthenticationAvatar />}
            <IconButton onClick={() => setOpen(!open)} edge="end"><Icon lightTheme>menu</Icon></IconButton>
          </Stack>
        </Toolbar>

        <Toolbar  sx={{justifyContent: 'space-between', display: { xs: 'none', md: 'flex' }}}>
          <Stack direction="row" spacing={1} alignItems='center'>
            {homeIcon}
            <Button component={Link} to={'/plans'} color="inherit">Price</Button>
            {/* <Button component={Link} to={'/about'} color="inherit">About</Button> */}
            <Button component={Link} to={'/downloads'} color="inherit">Download</Button>
            <DropdownButton variant='text' color="inherit" title="Help">
                <MenuItem onClick={() => navigate('/faq')}>
                    FAQ
                </MenuItem>

                <MenuItem onClick={() => navigate('/articles')}>
                    Articles
                </MenuItem>
                <MenuItem onClick={() => navigate('/contact')}>
                    Contact
                </MenuItem>
            </DropdownButton>


          </Stack>
          <Stack direction="row" spacing={1} alignItems='center'>
            <AuthenticationAvatar />
            <Button variant='outlined' component={Link} to={'/demo'} color="inherit">Request Demo</Button>
          </Stack>
        </Toolbar>
      </MuiAppBar>
      <Drawer
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            sx: {
              // backgroundColor: theme.palette.primary.dark,
              // color: "white",
              width: "80%"
            }
          }}
        >
          <>
          <Toolbar onClick={() => setOpen(false)} sx={{ backgroundColor: theme.palette.primary.main, justifyContent: 'space-between', display: {sm: 'flex' }}}>
            {homeIcon}
          </Toolbar>
          <Stack spacing={0}>
          <List onClick={() => setOpen(false)} dense disablePadding>
            <ListItem>
              <Button variant="outlined" fullWidth component={Link} to={'/plans'}>Price</Button>
            </ListItem>
            <ListItem>
              <Button variant="outlined" fullWidth component={Link} to={'/downloads'}>Download</Button>
            </ListItem>
            <ListItem onClick={(e: any) => e.stopPropagation()}>
              <DropdownButton title="Help" variant="outlined" fullWidth>
              <MenuItem onClick={() => handleMobileDropdownItemClick('/faq')}>
                    FAQ
                </MenuItem>

                <MenuItem onClick={() => handleMobileDropdownItemClick('/articles')}>
                    Articles
                </MenuItem>
                <MenuItem onClick={() => handleMobileDropdownItemClick('/contact')}>
                    Contact
                </MenuItem>
              </DropdownButton>
            </ListItem>
            {userService.isAuthenticated() && <React.Fragment>
                            {/* <Box m={1}>
                            <Divider variant='fullWidth' />

                            </Box> */}


              <ListItem>
                <Button variant="outlined" fullWidth component={Link} to={'/account'}>Account</Button>
              </ListItem>
              {hasOrgs && <ListItem onClick={(e) => e.stopPropagation()}>
                <OrganizationsButton onNav={() => setOpen(false)} component={Button} componentProps={{variant: "outlined", fullWidth: true}} />
              </ListItem>}
              <ListItem>
                <Button variant="outlined" fullWidth component={Link} to={'/logout'}>Logout</Button>
              </ListItem>

            </React.Fragment>}

            {!userService.isAuthenticated() && <React.Fragment>
              <ListItem>
                <Button variant="outlined" onClick={() => setOpen(false)} fullWidth component={Link} to={'/login'}>Login</Button>

                {/* <ListItemButton component={Link} to={'/login'}>Login</ListItemButton> */}
              </ListItem>
              <ListItem>
                <Button variant="outlined" onClick={() => setOpen(false)} fullWidth component={Link} to={'/register'}>Free Trial</Button>
              </ListItem>
            </React.Fragment>}
          
            <ListItem>
              <Button variant="contained" onClick={() => setOpen(false)} fullWidth component={Link} to={'/demo'}>Request Demo</Button>
            </ListItem>


          </List>
          </Stack>
          </>
      </Drawer>
    </Box>
  );
}

export default Header;