import { Tenant } from 'models/Tenant';
import { StoreBase, UseStore } from './StoreBase';

const store = new StoreBase<Tenant>(null);

export const GetState = () => store.state;
export const Update = (tenant: Tenant) => {    
    store.update(tenant);
}
export const Subscription = (fn: (tenant: Tenant) => any) => {
    store.subscription(fn)
}
export function UseOrganizationStore(){
    let result = UseStore(store);
    return result;
}
