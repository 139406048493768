
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { Tenant } from "models/Tenant"
import {UseOrganizationStore, Subscription as OrganizationSubscription, Update as UpdateOrganization} from 'stores/OrganizationStore';
import { TenantService } from "services/TenantService"
import Tooltip from "components/tooltip/Tooltip"
import CopyToClipboardButton from "components/buttons/CopyToClipboardButton"
import { Bootstrapper } from "Bootstrapper"
import { OrganizationContext } from "./OrganizationContext"
import { CommonUtil } from "utils/CommonUtil"
import { Toast } from "stores/models/Toast"
import { UseToastStore, Update as ToastUpdate } from 'stores/ToastStore';
import OrganizationInfoForm from "./OrganizationInfoForm"




const Profile = (props: any) => {
    const commonUtil = new CommonUtil();
    const navigate = useNavigate();
    const organization = UseOrganizationStore();
    const context = Bootstrapper.getInstance<OrganizationContext>(OrganizationContext);

    const tenantService = new TenantService();
    const userService = new UserService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmitProfile, setCanSubmitProfile] = useState<boolean>(true);
    const [regKey, setRegKey] = useState<string>("");


    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }


    useEffect(() => {
        loadDataAsync();
    }, []);

    const loadDataAsync = async() => {
        context.setBusyIndicator(true);
        const regKey = await tenantService.getOrganizationRegKey(organization?.id);
        setRegKey(regKey);
        context.setBusyIndicator(false);
    }

    const AccessKeyMenu = (props: any) => {
        const {invite, ...rest} = props;
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        
        
        const menuClicked = (event: any) => {
            setAnchorEl(event.currentTarget);
            event.stopPropagation()
        };
    
        
        const handleClose = (e: any) => {
            setAnchorEl(null);
            e.stopPropagation();
        };

        const onCopyMenuItemAsync = async(e: any) => {
            handleClose(e);
            commonUtil.CopyToClipboard(regKey);
            ToastUpdate(new Toast(true, "Copied to clipboard"));
        }

        const onChangeKeyMenuItemAsync = async(e: any) => {
            handleClose(e);
            const regKey = await tenantService.getNewOrganizationRegKey(organization?.id);
            setRegKey(regKey);
    
        }

        return(
            <>
            <IconButton onClick={(e: any) => menuClicked(e)}>
                <Icon normal>more_vert</Icon>
            </IconButton>
            
                <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={(e: any) => handleClose(e)}
                >
    
                    <MenuItem onClick={(e: any) => onCopyMenuItemAsync(e)}>
                        Copy
                    </MenuItem>
                    <MenuItem onClick={(e: any) => onChangeKeyMenuItemAsync(e)}>
                        New Key
                    </MenuItem>
                </Menu>
            </>
        )
    
    }

    return(
        <Container sx={{margin: 0}} disableGutters maxWidth="sm">
        <Section disablePadding id="section-plans" bgColor={bg1}>
                <Animation duration="medium" overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                        <Grid container justifyContent='center' alignItems="stretch"spacing={2}>
                            <Grid flexGrow={1} item sm={12} md={12}>
                                <Item sx={{height: '100%'}}>
                                        <div style={{height: '100%'}}>
                                        <ExpansionPanel expanded={true} title="Organization">
                                            <OrganizationInfoForm label="Name" org={organization} />
                                        </ExpansionPanel>
                                        </div>                                    
                                </Item>
                            </Grid>
                            <Grid flexGrow={1} item sm={12} md={12}>
                                <Item sx={{height: '100%'}}>
                                        <div style={{height: '100%'}}>
                                            <ExpansionPanel expanded={true} title="Access Key">
                                                <Form flat
                                                    validationMsg={validationMsg}
                                                    >
                                                        <FieldCollection>
                                                        <TextField
                                                            type="password"
                                                            disabled 
                                                            isReadOnly value={regKey} 
                                                            endAdornment={<AccessKeyMenu />}
                                                            startAdornment={<CopyToClipboardButton content={regKey} />}
                                                            />
                                                        </FieldCollection>
                                                </Form>
                                            </ExpansionPanel>                                       
                                        </div>                                    
                                </Item>
                            </Grid>
                        </Grid>




                    </div>
                </Animation>
        </Section>
        </Container>

    )


}


export default Profile