import { Box } from '@mui/material';
import * as React from 'react';

const TitleBlock = (props: any) => {
    const {children, fullWidth, ...rest} = props;
    const maxWidth = fullWidth ? '100%' : '500px';
    return(
        
        <Box display='flex' justifyContent='left' mb={8} {...rest}>
            <div style={{maxWidth: maxWidth}}>
                {children}
            </div>
        </Box>
    ) 
}

export default TitleBlock;