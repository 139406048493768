import * as React from 'react';
import {Link as MuiLink}  from '@mui/material';
import { Link as NavLink } from 'react-router-dom';


const Link = (props: any) => {
    const {href, ...rest} = props;
    const nav = href ? undefined : NavLink;
    

    return <MuiLink href={href} component={nav} sx={{cursor: 'pointer'}} underline="always" cursor="pointer" {...rest} />
}

export default Link;