
import { Avatar, Card, CardActions, CardContent, CardHeader, Checkbox, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import Dialog from "components/modals/Dialog"
import { Plan, SubscriptionStatus } from "models/Plan"
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import { TenantService } from "services/TenantService"

import { User } from "models/User"
import { DialogUtil } from "utils/DialogUtil"
import LoadingButton from "components/buttons/LoadingButton"
import Button from "components/buttons/Button";
import Text from "components/labels/Text";


const AccessManagerButton = (props: any) => {
    const {fullWidth, onSuccess, orgId, orgUsers, orgPlan, ...rest} = props;

    const userService = new UserService();
    const tenantService = new TenantService();
    const dialogUtils = new DialogUtil();

    const users = orgUsers ?? [];
    const plan = orgPlan ?? Plan.empty();

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    const getUsersList = (users: User[], selectedIds: string[], selectionChange: (id: string, selected: boolean)=>void) => {
        const idSet = new Set(selectedIds);
        return(
            <List dense disablePadding>
                {users.map((u: User, i: number) => {
                    const checked = idSet.has(u.id);
                    return(
                        <ListItem disablePadding
                        key={i}
                      >
                        <ListItemButton onClick={() => selectionChange(u.id, !checked)} dense>
                            <ListItemIcon>
                                <Checkbox
                                
                                edge="start"
                                checked={checked}
                                tabIndex={-1}
                                />
                            </ListItemIcon>
                          <ListItemText primary={u.userName} />

                        </ListItemButton>
                      </ListItem>
            
                    )
                })}
            </List>
        )
    }

    const getAvailableLicenses = () => {
        return plan.maxUsers - selectedUserIds.length;
    }

    const selectionChange = (id: string, checked: boolean) => {
        if(checked && getAvailableLicenses() == 0){
            dialogUtils.confirmDialog("You cannot select anymore users because you have no more licenses", () => {return true}, "Action Denied");
            return;
        }
        var ids = [...selectedUserIds];
        if(checked)
            ids.push(id);
        else
            ids = ids.filter(i => i !== id);

        setSelectedUserIds(ids);
            
    }

    const submitUsersWithAccessAsync = async() => {
            if(!canSubmit)
                return;
            setCanSubmit(false);
            setValidationMsg(null);

            const selectedCount = selectedUserIds.length;
            const availableLicenses = getAvailableLicenses();

            try{
                if(selectedCount == 0){
                    setValidationMsg('You have not selected any users');
                    return;
                }
                if(availableLicenses && users.length > selectedCount){
                    setValidationMsg(`You still have more licenses: ${availableLicenses}. Please select more users and try again.`);
                    return;
                }
    
                const result = await tenantService.updateTenantMembersDeactivationStatusAsync(orgId, selectedUserIds);
                setOpen(false);
                if(result)
                    onSuccess && onSuccess();

            }
            finally{
                setCanSubmit(true);
            }
        

    }

    const availableLicenses = getAvailableLicenses();
    const action = <LoadingButton loading={!canSubmit} disabled={!canSubmit || selectedUserIds.length == 0} onClick={()=> submitUsersWithAccessAsync()}>Submit</LoadingButton>
    return(
        <>
        <Button fullWidth={fullWidth} onClick={() => setOpen(true)} {...rest}>Manage User Access</Button>

        <Dialog onClose={() => setOpen(false)} action={action} title={`Licenses Available: ${availableLicenses}`} open={open}>
            <Form validationMsg={validationMsg} flat>
                <Stack spacing={1}>
                    <div>
                        <Text>Choose which member(s) get access</Text>
                    </div>

                    <Paper elevation={0}>
                            {getUsersList(users, selectedUserIds, selectionChange)}
                    </Paper>                    
                </Stack>

            </Form>

        </Dialog>
        </>


    )


}


export default AccessManagerButton