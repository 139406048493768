import {useStore as reactUseStore} from 'effector-react'
import {createEvent, createStore, Event, Store} from 'effector'


export class StoreBase<T>{
    private _store: Store<T>;
    private initVal: T;
    private writer: Event<T>;

    constructor(initVal: T){

        this.initVal = initVal;
        this.writer = createEvent<T>();
        this._store = createStore<T>(this.initVal);
        this._store.on(this.writer, (current, newVal)=> newVal);
    }

    public get store(): Store<T> {
        return this._store;
    }

    public get state(): T {
        return this.store.getState();
    }
    public update = (newVal: T) => {
        this.writer(newVal);
    }
    public subscription = (fn: (state: T) => any) => {
        this.store.watch(val => fn(val));
    }

}

export function UseStore<T>(store: StoreBase<T>): T {
    let val = reactUseStore(store.store)
    return val;
}

// export const UseStore = <T>(store: StoreBase<T>) => {
//     return reactUseStore(store.store)
// };