import * as React from 'react';
import Typography  from '@mui/material/Typography';

const Subtitle = (props: any) => {
    const {gutterBottom, bold, ...rest} = props;
    const mb = gutterBottom ? 1 : 0;
    const fw = bold ? {fontWeight: 'bold'} : null;
    return <Typography style={fw} mb={mb} variant="h5" component="h3" {...rest} />
}

export default Subtitle;