
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Computer, { IComputerProps } from "../../components/objects/computer/Computer"
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import Link from "components/buttons/Link"
import { Bootstrapper } from "Bootstrapper"
import LoadingButton from "components/buttons/LoadingButton"
import { DialogUtil } from "utils/DialogUtil"
import { NavLink, useNavigate } from "react-router-dom"
import { UnauthorizedError } from "errors/ApplicationErrors"



const DownloadsButton = (props: any) => {
    const {beta, requiresAccount, onCompleted, linkOnly, hideDetailsLink, ...rest} = props;
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const dialogUtils = new DialogUtil();
    const navigate = useNavigate();

    const [canSubmit, setCanSubmit] = useState<boolean>(true);

    const downloadLatestAsync = async() => {
        var os = prodInfoService.getOS();
        if(os !== null && os !== 'windows'){
            dialogUtils.confirmDialog("This add-in only works on a Windows operating system. Are you sure you want to continue with your download?", async(confirmed) => {
                if(confirmed){
                    setCanSubmit(false);
                    var result = await prodInfoService.downLoadInstallerAsync(beta);
                    setCanSubmit(true);    
                }
                onCompleted && onCompleted(result);
            });
        }
        else{
            setCanSubmit(false);
            var result = await prodInfoService.downLoadInstallerAsync(beta).catch((e: Error) => {
                if (e instanceof UnauthorizedError) {
                    dialogUtils.confirmDialog("This download requires a free account. Register now?", async(confirmed) => {
                        if(confirmed){
                            navigate('/register');
                        }
                    });
                }
                else 
                    throw e;

            });
            setCanSubmit(true);    
            onCompleted && onCompleted(result);
        }
    }
    
    return(
        <>
            {linkOnly && <Text>Need the software? Download it <Link component={NavLink} to="/downloads">here</Link></Text>}

            {!linkOnly && <Box>
                <LoadingButton disabled={!canSubmit} loading={!canSubmit} onClick={() => downloadLatestAsync()} {...rest}>Download{beta ? "-Beta" : ""}</LoadingButton>
                {!hideDetailsLink && <Text mt={1} variant="caption">Find more info <Link component={NavLink} to="/downloads">here</Link></Text>}
            </Box>}
        </>
    )
}


export default DownloadsButton