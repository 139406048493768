import { Tenant } from 'models/Tenant';
import { StoreBase, UseStore } from './StoreBase';

const store = new StoreBase<Tenant[]>([]);

export const GetState = () => store.state;
export const Update = (tenants: Tenant[]) => {    
    store.update(tenants);
}
export const Subscription = (fn: (tenants: Tenant[]) => any) => {
    store.subscription(fn)
}
export function UseActiveUserOrganizationIdStore(){
    let result = UseStore(store);
    return result;
}
