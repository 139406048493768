
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"
import { Tenant } from "models/Tenant"
import { BadRequestError } from "errors/ApplicationErrors"
import { Bootstrapper } from "Bootstrapper"
import LoadingButton from "components/buttons/LoadingButton"
import { DialogUtil } from "utils/DialogUtil"
import Article, { Icon2, Section2, Stack2, Subtitle2, Text2 } from "../../Article"
import InfoLink, { InfoType } from "views/articles/shared/InfoLink"
import SimpleGrid, { SimpleContent } from "views/articles/shared/simpleGrid/SimpleGrid"
// import toolbarImg from "../media/exhibit-pane-toolbar.PNG";
const toolbarImg = require('../../media/exhibit-pane-toolbar.PNG');
const fileSizeImg = require('../../media/filesize-input.PNG');
const bundleMethodImg = require('../../media/bundle-method-input.PNG');

const BundleMaterialsArticle = (props: any) => {
    const navigate = useNavigate();
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);

    const userService = new UserService();
    const tenantService = new TenantService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dialogUtils = new DialogUtil();

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const media = prodInfoService.getMedia();

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    useEffect(() => {
    }, []);




    return(
        <Article 
            title="Decide how to bundle your digital binder"
            >
                <Section2>
                    <Stack2>
                        <Text2>Zibbit creates digital binders by bundling your Word document with your collection of exhibits. Typically you'll want to combine all your materials together, but for some Courts (or jurisdictions) you may need to bundle your materials separately.</Text2>
                        <Text2>For example, you may want the digital binder to be a folder containing multiple PDFs (a PDF for your Word document and each exhibit). In this article we'll discuss different approaches to bundling your materials.</Text2>
                    </Stack2>
                </Section2>
                <Section2>
                    <SimpleGrid>
                        <SimpleContent>
                            <Stack2>
                                <Subtitle2>Bundle Options</Subtitle2>
                                <Text2>Open the <InfoLink type={InfoType.PublishPane}/> and expand the <strong>Output</strong> group to see options for bundling your digital binder.</Text2>
                            </Stack2>

                        </SimpleContent>
                        <SimpleContent><Box></Box></SimpleContent>
                    </SimpleGrid>

                </Section2>
                <Section2>
                    <Stack2>
                        <Box>
                        <SimpleGrid>
                            <SimpleContent>
                                <Stack2>
                                    <Stack2>
                                    <Subtitle2>Bundle Materials Together</Subtitle2>
                                    <Text2>By default, Zibbit creates digital binders by combining all your materials together into a <strong>Single PDF</strong>.</Text2>
                                    <Text2>However, you may want to split your materials into <strong>Volumes</strong> if you are working with large exhibits. You can specify a maximum file size under the "File Size" input. This will generate a folder containing PDF volumes if your materials exceed the limit.</Text2>
                                    </Stack2>
                                </Stack2>

                            </SimpleContent>
                            <SimpleContent>
                                <Paper>
                                    <img src={fileSizeImg} />
                                </Paper>
                            </SimpleContent>
                        </SimpleGrid>

                        </Box>
                    </Stack2>

                </Section2>
                <Section2>
                <Box>
                        <SimpleGrid>
                            <SimpleContent>
                                <Stack2>
                                    <Stack2>
                                    <Subtitle2>Bundle Materials Separately</Subtitle2>
                                    <Text2>Instead of combining all your materials together, you may want to generate a digital binder as a folder containing a separate PDF for your Word document and each exhibit.</Text2>
                                    <Text2>Note that hyperlinks will still function correctly even when the destination is within a separate PDF.</Text2>
                                    <Text2>Select this approach by unchecking the "Append materials" option under "Bundle Method".</Text2>
                                    </Stack2>
                                </Stack2>

                            </SimpleContent>
                            <SimpleContent>
                                <Paper>
                                    <img src={bundleMethodImg} />
                                </Paper>
                            </SimpleContent>
                        </SimpleGrid>

                        </Box>

                </Section2>

        </Article>
    )


}


export default BundleMaterialsArticle