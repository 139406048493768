
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import Link from "components/buttons/Link"
import LoadingButton from "components/buttons/LoadingButton"
import { Tenant } from "models/Tenant"
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';
import { TenantService } from "services/TenantService"
import OrganizationInfoForm from "views/organization/OrganizationInfoForm"
import useInterval from "hooks/UseInterval"
import { BadRequestError, NotFoundError } from "errors/ApplicationErrors"




const PaymentResult = (props: any) => {
    const navigate = useNavigate();
    const userService = new UserService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [org, setOrg] = useState<Tenant>();
    const tenantService = new TenantService();
    const [delay, setDelay] = useState<number>(2000);
    const countRef = useRef(delay);
    countRef.current = delay;
    
    useInterval(async() => {
        console.log("Poll attempt", org)
        if(org == null){
            var tenant = await tenantService.getOwnerOrganization();
            var tenantId = tenant?.id ?? null;

            if(tenantId != null){
                tenant.name = null;
                setOrg(tenant);
                BusyUpdate(false);
                setDelay(null);
                await Bootstrapper.setUserInfoAsync();

            }
        }
      }, delay);
    

    useEffect(() => {

        BusyUpdate(delay != null);
        setTimeout(() => {
            if(countRef.current != null){
                setDelay(null);
                BusyUpdate(false);
                throw new NotFoundError("Your subscription was not found. Try refreshing the browser.");
            }

        }, 1000 * 60);
            

    }, []);


    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }
    
    return(
        <Section sx={{flexGrow: 1}} bgColor={bg2}>
            <Container maxWidth="xs">
                <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                        {!org && <Form title="Checkout Successful!" action={<Text textAlign='center'>Setting up your organization. This may take a couple minutes.</Text>} />}  
                        {org && <OrganizationInfoForm onSubmit={() => navigate(`/organization/${org?.id}`)} title="Checkout Successful!" label="Organization" org={org}/>}                         
                    </div>
                </Animation>
            </Container>
        </Section>
    )

}


export default PaymentResult