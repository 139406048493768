import * as React from 'react';
import {FormControl, FormHelperText, FormLabel, InputAdornment, InputLabel, Stack, TextField as MuiTextField} from '@mui/material';
import Input from 'components/inputs/input/Input';
import Icon from 'components/icons/Icon';
import Tooltip from 'components/tooltip/Tooltip';



const getDefaultInputProps = (props: any): any => {
  var startAdorn = props.startAdornment;
  var endAdorn = props.endAdornment;

  var startAdornment = !startAdorn ? null : <InputAdornment position={"start"}>{startAdorn}</InputAdornment>
  var endAdornment = !endAdorn ? null : <InputAdornment position={"end"}>{endAdorn}</InputAdornment>

  return {
    startAdornment, 
    endAdornment, 
    size: "small",
    inputProps: { spellCheck: 'false' }
  }
}

const TextField = (props: any) => {
  const {label, tooltip, isReadOnly, children, helperText, childrenProps, fullWidth, hidden, helperTextProps, InputProps, InputLabelProps, ...rest } = props;
  const fullWidthVal = fullWidth ?? true;
  const tip = tooltip ? <Tooltip title={tooltip} placement="bottom-start">{<Icon small>info</Icon>}</Tooltip>  : null;

  //const childrenStyle = fullWidthVal ? "" : "";

  // const inputProps = getDefaultInputProps(props);

  // const InputLabelProps = {};
  const style = hidden ? {display: "none"} : {};
  const lbl = isReadOnly ? <InputLabel shrink>{label}</InputLabel> : <FormLabel>{label}</FormLabel>;
  const disabled = props.disabled; // || isReadOnly;
  const variant = isReadOnly ? "standard" : "outlined";
  const inp = <Input {...InputProps} disabled={disabled} fullWidth={fullWidthVal} isReadOnly={isReadOnly} {...rest} />
  
  return (
    <FormControl sx={style} disabled={disabled} hiddenLabel={!isReadOnly} variant={variant} fullWidth={fullWidthVal}>
      {lbl}
      {getInput(inp, tip)}
      <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
    </FormControl>
  );
}

// const getHelperTextStyle = (props: any) => {
//   if(!props || !props.flush)
//     return null;
  
//   return {margin: 0};
  
// }


const getInput = (input: React.ReactElement, tooltip: React.ReactElement): React.ReactElement => {
  if(tooltip == null)
    return input
  return <Stack>
        {input}
        {tooltip}
      </Stack>
}
export default TextField;